/** meta helper to inline css through dart css */
/** This import ensures the @unity/tokens design system variables are available through the sass files */
/**
 * Do not edit directly
 * Generated on Tue, 23 May 2023 09:38:54 GMT
 */
:root {
  --unity-global-opacity-80: 0.8; /* TBD */
  --unity-global-opacity-60: 0.6; /* TBD */
  --unity-global-opacity-40: 0.4; /* TBD */
  --unity-global-opacity-20: 0.2; /* TBD */
  --unity-global-opacity-10: 0.1; /* TBD */
  --unity-global-base: 16;
  --unity-global-text-decoration-underline: underline;
  --unity-global-text-decoration-line-through: line-through;
  --unity-global-text-decoration-none: none;
  --unity-global-text-case-capitalize: capitalize;
  --unity-global-text-case-uppercase: uppercase;
  --unity-global-text-case-lowercase: lowercase;
  --unity-global-text-case-none: none;
  --unity-global-letter-spacing-expanded: 0.009375rem;
  --unity-global-letter-spacing-semi-expanded: 0.028125rem;
  --unity-global-letter-spacing-extra-expanded: 0.0375rem;
  --unity-global-letter-spacing-ultra-expanded: 0.01875rem;
  --unity-global-letter-spacing-slightly-thight: -0.009375rem;
  --unity-global-letter-spacing-ultra-condensed: -0.01875rem;
  --unity-global-letter-spacing-extra-condensed: -0.028125rem;
  --unity-global-letter-spacing-condensed: -0.0375rem;
  --unity-global-letter-spacing-semi-condensed: -0.05625rem;
  --unity-global-letter-spacing-extra-semi-tight: -0.1125rem;
  --unity-global-letter-spacing-semi-tighter: -0.09375rem;
  --unity-global-letter-spacing-semi-tight: -0.075rem;
  --unity-global-letter-spacing-extreme-thight: -0.253125rem;
  --unity-global-letter-spacing-tightest: -0.1875rem;
  --unity-global-letter-spacing-extra-tight: -0.13125rem;
  --unity-global-letter-spacing-extra-tighter: -0.140625rem;
  --unity-global-letter-spacing-tight: -0.084375rem;
  --unity-global-letter-spacing-normal: 0;
  --unity-global-paragraph-spacing-0: 0;
  --unity-global-font-size-200: 12.5rem;
  --unity-global-font-size-144: 9rem;
  --unity-global-font-size-104: 6.5rem;
  --unity-global-font-size-96: 6rem;
  --unity-global-font-size-72: 4.5rem;
  --unity-global-font-size-56: 3.5rem;
  --unity-global-font-size-48: 3rem;
  --unity-global-font-size-40: 2.5rem;
  --unity-global-font-size-36: 2.25rem;
  --unity-global-font-size-32: 2rem;
  --unity-global-font-size-24: 1.5rem;
  --unity-global-font-size-20: 1.25rem;
  --unity-global-font-size-18: 1.125rem;
  --unity-global-font-size-16: 1rem;
  --unity-global-font-size-14: 0.875rem;
  --unity-global-font-size-13: 0.8125rem;
  --unity-global-font-size-12: 0.75rem;
  --unity-global-font-size-11: 0.6875rem;
  --unity-global-font-weights-black: 900;
  --unity-global-font-weights-bold: 700;
  --unity-global-font-weights-light: 300;
  --unity-global-font-weights-regular-italic: Regular Italic;
  --unity-global-font-weights-medium-italic: Medium Italic;
  --unity-global-font-weights-regular: 400;
  --unity-global-font-weights-medium: 500;
  --unity-global-line-heights-178: 11.125rem;
  --unity-global-line-heights-132: 8.25rem;
  --unity-global-line-heights-106: 6.625rem;
  --unity-global-line-heights-98: 6.125rem;
  --unity-global-line-heights-74: 4.625rem;
  --unity-global-line-heights-64: 4rem;
  --unity-global-line-heights-52: 3.25rem;
  --unity-global-line-heights-44: 2.75rem;
  --unity-global-line-heights-40: 2.5rem;
  --unity-global-line-heights-36: 2.25rem;
  --unity-global-line-heights-32: 2rem;
  --unity-global-line-heights-28: 1.75rem;
  --unity-global-line-heights-26: 1.625rem;
  --unity-global-line-heights-24: 1.5rem;
  --unity-global-line-heights-22: 1.375rem;
  --unity-global-line-heights-19: 1.1875rem;
  --unity-global-line-heights-18: 1.125rem;
  --unity-global-line-heights-17: 1.0625rem;
  --unity-global-font-families-otypical-text: Otypical Text;
  --unity-global-font-families-otypical-headline: Otypical Headline;
  --unity-global-border-width-8: 0.5rem; /* TBD */
  --unity-global-border-width-4: 0.25rem; /* TBD */
  --unity-global-border-width-2: 0.125rem; /* TBD */
  --unity-global-border-width-1: 0.0625rem; /* TBD */
  --unity-global-border-radius-64: 4rem; /* TBD */
  --unity-global-border-radius-48: 3rem; /* TBD */
  --unity-global-border-radius-36: 2.25rem; /* TBD */
  --unity-global-border-radius-32: 2rem; /* TBD */
  --unity-global-border-radius-24: 1.5rem; /* TBD */
  --unity-global-border-radius-16: 1rem; /* TBD */
  --unity-global-border-radius-12: 0.75rem; /* TBD */
  --unity-global-border-radius-8: 0.5rem; /* TBD */
  --unity-global-border-radius-4: 0.25rem; /* TBD */
  --unity-global-border-radius-0: 0; /* TBD */
  --unity-global-rem: 16; /* TBD */
  --unity-global-size-128: 8rem; /* TBD */
  --unity-global-size-80: 5rem; /* TBD */
  --unity-global-size-64: 4rem; /* TBD */
  --unity-global-size-48: 3rem; /* TBD */
  --unity-global-size-40: 2.5rem; /* TBD */
  --unity-global-size-36: 2.25rem; /* TBD */
  --unity-global-size-32: 2rem; /* TBD */
  --unity-global-size-24: 1.5rem; /* TBD */
  --unity-global-size-16: 1rem; /* TBD */
  --unity-global-size-8: 0.5rem; /* TBD */
  --unity-global-size-4: 0.25rem; /* TBD */
  --unity-global-size-0: 0; /* TBD */
  --unity-global-space-128: 8rem; /* TBD */
  --unity-global-space-96: 6rem; /* TBD */
  --unity-global-space-64: 4rem; /* TBD */
  --unity-global-space-48: 3rem; /* TBD */
  --unity-global-space-32: 2rem; /* TBD */
  --unity-global-space-24: 1.5rem; /* TBD */
  --unity-global-space-20: 1.25rem; /* TBD */
  --unity-global-space-16: 1rem; /* TBD */
  --unity-global-space-12: 0.75rem;
  --unity-global-space-8: 0.5rem; /* TBD */
  --unity-global-space-6: 0.375rem; /* TBD */
  --unity-global-space-4: 0.25rem; /* TBD */
  --unity-global-space-2: 0.125rem; /* TBD */
  --unity-global-space-0: 0; /* TBD */
  --unity-global-color-transparency-black-80: #000000cc; /* Modals, overlays, lightbox */
  --unity-global-color-transparency-black-40: #00000066; /* Multiple design components such as popover component */
  --unity-global-color-transparency-black-20: #00000033; /* Subnav darkmode background */
  --unity-global-color-transparency-black-10: #0000001a; /* TBD */
  --unity-global-color-transparency-white-10: #ffffff1a; /* TBD */
  --unity-global-color-solid-white: #fff; /* All fontsizes. */
  --unity-global-color-solid-black: #000000; /* All fontsizes. */
  --unity-global-color-solid-purple: #7066ff; /* Min. size of 18,66 px bold or 24 px for text (on) white. */
  --unity-global-color-solid-pink: #ff808c; /* All fontsizes. */
  --unity-global-color-solid-orange: #ff7621; /* All fontsizes. */
  --unity-global-color-solid-yellow: #ffac24; /* All fontsizes. */
  --unity-global-color-solid-green: #2f9a92; /* Min. size of 18,66 px bold or 24 px for text (on) white. */
  --unity-global-color-solid-blue: #2c72ff; /* Min. size of 18,66 px bold or 24 px for text (on) white and black. */
  --unity-global-color-scale-red-900: #EB0E00; /* Min. size of 18,66 px bold or 24 px for text (on) black. */
  --unity-global-color-scale-red-700: #FE5F55; /* Min. size of 18,66 px bold or 24 px for text (on) white. */
  --unity-global-color-scale-red-500: #FE7F77; /* All fontsizes. */
  --unity-global-color-scale-red-300: #FE9F99; /* All fontsizes. */
  --unity-global-color-scale-red-100: #FFBFBB; /* All fontsizes. */
  --unity-global-color-scale-red-50: #FFE7E5; /* All fontsizes. */
  --unity-global-color-scale-purple-900: #5347FF; /* Min. size of 18,66 px bold or 24 px for text (on) black. */
  --unity-global-color-scale-purple-700: #7066ff; /* Min. size of 18,66 px bold or 24 px for text (on) white. */
  --unity-global-color-scale-purple-500: #8D85FF; /* All fontsizes. */
  --unity-global-color-scale-purple-300: #A9A3FF; /* All fontsizes. */
  --unity-global-color-scale-purple-100: #C6C2FF; /* All fontsizes. */
  --unity-global-color-scale-purple-50: #E8E7FF; /* All fontsizes. */
  --unity-global-color-scale-pink-900: #F95262; /* Min. size of 18,66 px bold or 24 px for text (on) white. */
  --unity-global-color-scale-pink-700: #ff808c; /* All fontsizes. */
  --unity-global-color-scale-pink-500: #FF99A3; /* All fontsizes. */
  --unity-global-color-scale-pink-300: #FFB3BA; /* All fontsizes. */
  --unity-global-color-scale-pink-100: #FFCCD1; /* All fontsizes. */
  --unity-global-color-scale-pink-50: #FFF5F6; /* All fontsizes. */
  --unity-global-color-scale-orange-900: #F05C00; /* Min. size of 18,66 px bold or 24 px for text (on) white. */
  --unity-global-color-scale-orange-700: #ff7621; /* All fontsizes. */
  --unity-global-color-scale-orange-500: #FF914D; /* All fontsizes. */
  --unity-global-color-scale-orange-300: #FFAD7A; /* All fontsizes. */
  --unity-global-color-scale-orange-100: #FFC8A6; /* All fontsizes. */
  --unity-global-color-scale-orange-50: #FFF1E9; /* All fontsizes. */
  --unity-global-color-scale-yellow-900: #FFA005; /* All fontsizes. */
  --unity-global-color-scale-yellow-700: #ffac24; /* All fontsizes. */
  --unity-global-color-scale-yellow-500: #FFBD50; /* All fontsizes. */
  --unity-global-color-scale-yellow-300: #FFCD7C; /* All fontsizes. */
  --unity-global-color-scale-yellow-100: #FFDEA7; /* All fontsizes. */
  --unity-global-color-scale-yellow-50: #FFF7E9; /* All fontsizes. */
  --unity-global-color-scale-green-900: #207D76; /* Min. size of 18,66 px bold or 24 px for text (on) black. */
  --unity-global-color-scale-green-700: #2f9a92; /* Min. size of 18,66 px bold or 24 px for text (on) white. */
  --unity-global-color-scale-green-500: #59AEA8; /* All fontsizes. */
  --unity-global-color-scale-green-300: #82C2BE; /* All fontsizes. */
  --unity-global-color-scale-green-100: #ACD7D3; /* All fontsizes. */
  --unity-global-color-scale-green-50: #EEF7F6; /* All fontsizes. */
  --unity-global-color-scale-blue-900: #1B5EE4; /* All fontsizes. */
  --unity-global-color-scale-blue-700: #2c72ff; /* Min. size of 18,66 px bold or 24 px for text (on) white and black. */
  --unity-global-color-scale-blue-500: #568EFF; /* Min. size of 18,66 px bold or 24 px for text (on) white. */
  --unity-global-color-scale-blue-300: #80AAFF; /* All fontsizes. */
  --unity-global-color-scale-blue-100: #ABC7FF; /* All fontsizes. */
  --unity-global-color-scale-blue-50: #ECF2FF; /* All fontsizes. */
  --unity-global-color-scale-gray-900: #222222; /* All fontsizes. */
  --unity-global-color-scale-gray-700: #444444; /* All fontsizes. */
  --unity-global-color-scale-gray-500: #666666; /* Min. size of 18,66 px bold or 24 px for text (on) white. */
  --unity-global-color-scale-gray-300: #999999; /* All fontsizes. */
  --unity-global-color-scale-gray-100: #DDDDDD; /* All fontsizes. */
  --unity-global-color-scale-gray-50: #EBEBEB; /* All fontsizes. */
  --unity-alias-app-badge-typography: 500 0.6875rem / 1.0625rem Otypical Headline;
  --unity-alias-form-active-outline-focus: inset 0 0 0 2px #1B5EE4, 0 0 0 4px #1b5ee41a;
  --unity-alias-components-outline-focus: inset 0 0 0 2px #1B5EE4, 0 0 0 2px #ffffff, 0 0 0 6px #1b5ee41a;
  --unity-alias-opacity-disabled: 0.2; /* Used for disabled UI elements. */
  --unity-alias-text-link-typography-lg: 500 1.25rem / 2rem Otypical Headline;
  --unity-alias-text-link-typography-md: 500 1rem / 1.5rem Otypical Headline;
  --unity-alias-text-link-typography-sm: 500 0.875rem / 1.375rem Otypical Headline;
  --unity-alias-quote-strong: Medium Italic 1.25rem / 2rem Otypical Headline;
  --unity-alias-quote-normal: Regular Italic 1.25rem / 2rem Otypical Headline;
  --unity-alias-charts-value-mobile: 500 1.25rem / 1.75rem Otypical Headline;
  --unity-alias-charts-value-desktop: 500 2.25rem / 2.5rem Otypical Headline;
  --unity-alias-list-strong: 400 1rem / 1.5rem Otypical Headline;
  --unity-alias-label-strong: 400 0.875rem / 1.375rem Otypical Headline;
  --unity-alias-price-euro-md: 500 1rem / 1.0625rem Otypical Headline;
  --unity-alias-price-euro-sm: 500 1rem / 1.0625rem Otypical Headline;
  --unity-alias-price-digits-md: 500 1rem / 1.0625rem Otypical Headline;
  --unity-alias-price-digits-sm: 500 0.875rem / 1.0625rem Otypical Headline;
  --unity-alias-price-main-md: 500 2rem / 1.5rem Otypical Headline;
  --unity-alias-price-main-sm: 500 1.5rem / 1.0625rem Otypical Headline;
  --unity-alias-lead-strong: 500 1.25rem / 2rem Otypical Headline;
  --unity-alias-lead-normal: 400 1.25rem / 2rem Otypical Headline;
  --unity-alias-footnote-medium-line-trough-xs: 500 0.6875rem / 1.0625rem Otypical Headline;
  --unity-alias-footnote-medium-line-trough-sm: 500 0.75rem / 1.125rem Otypical Headline;
  --unity-alias-footnote-medium-line-trough-md: 500 0.8125rem / 1.1875rem Otypical Headline;
  --unity-alias-footnote-medium-xs: 500 0.6875rem / 1.0625rem Otypical Text;
  --unity-alias-footnote-medium-sm: 500 0.75rem / 1.125rem Otypical Text;
  --unity-alias-footnote-medium-md: 500 0.8125rem / 1.1875rem Otypical Text;
  --unity-alias-footnote-regular-line-trough-xs: 400 0.6875rem / 1.0625rem Otypical Text;
  --unity-alias-footnote-regular-line-trough-sm: 400 0.75rem / 1.125rem Otypical Text;
  --unity-alias-footnote-regular-line-trough-md: 400 0.8125rem / 1.1875rem Otypical Text;
  --unity-alias-footnote-regular-xs: 400 0.6875rem / 1.0625rem Otypical Text;
  --unity-alias-footnote-regular-sm: 400 0.75rem / 1.125rem Otypical Text;
  --unity-alias-footnote-regular-md: 400 0.8125rem / 1.1875rem Otypical Text;
  --unity-alias-body-medium-underline-md: 500 1rem / 1.5rem Otypical Text;
  --unity-alias-body-medium-line-through-sm: 500 0.875rem / 1.375rem Otypical Text;
  --unity-alias-body-medium-line-through-md: 500 1rem / 1.5rem Otypical Text;
  --unity-alias-body-medium-line-through-lg: 500 1.125rem / 1.625rem Otypical Text;
  --unity-alias-body-medium-line-through-xl: 500 1.25rem / 1.75rem Otypical Text;
  --unity-alias-body-medium-sm: 500 0.875rem / 1.375rem Otypical Text;
  --unity-alias-body-medium-md: 500 1rem / 1.5rem Otypical Text;
  --unity-alias-body-medium-lg: 500 1.125rem / 1.625rem Otypical Text;
  --unity-alias-body-medium-xl: 500 1.25rem / 1.75rem Otypical Text;
  --unity-alias-body-regular-line-through-sm: 400 0.875rem / 1.375rem Otypical Text;
  --unity-alias-body-regular-line-through-md: 400 1rem / 1.5rem Otypical Text;
  --unity-alias-body-regular-line-through-lg: 400 1.125rem / 1.625rem Otypical Text;
  --unity-alias-body-regular-line-through-xl: 400 1.25rem / 1.75rem Otypical Text;
  --unity-alias-body-regular-sm: 400 0.875rem / 1.375rem Otypical Text;
  --unity-alias-body-regular-md: 400 1rem / 1.5rem Otypical Text;
  --unity-alias-body-regular-lg: 400 1.125rem / 1.625rem Otypical Text;
  --unity-alias-body-regular-xl: 400 1.25rem / 1.75rem Otypical Text;
  --unity-alias-mobile-heading-sx: 500 1.125rem / 1.625rem Otypical Headline;
  --unity-alias-mobile-heading-sm: 500 1.25rem / 1.75rem Otypical Headline;
  --unity-alias-mobile-heading-md: 500 1.5rem / 2rem Otypical Headline;
  --unity-alias-mobile-heading-lg: 500 2rem / 2.25rem Otypical Headline;
  --unity-alias-mobile-heading-xl: 500 2.25rem / 2.5rem Otypical Headline;
  --unity-alias-mobile-display-xs: 500 2.5rem / 2.75rem Otypical Headline;
  --unity-alias-mobile-display-sm: 500 3rem / 3.25rem Otypical Headline;
  --unity-alias-mobile-display-md: 500 3.5rem / 4rem Otypical Headline;
  --unity-alias-mobile-display-lg: 500 4.5rem / 4.625rem Otypical Headline;
  --unity-alias-mobile-display-xl: 500 6.5rem / 6.625rem Otypical Headline;
  --unity-alias-desktop-heading-xs: 500 1.125rem / 1.625rem Otypical Headline;
  --unity-alias-desktop-heading-sm: 500 1.25rem / 1.75rem Otypical Headline;
  --unity-alias-desktop-heading-md: 500 1.5rem / 2rem Otypical Headline;
  --unity-alias-desktop-heading-lg: 500 2.25rem / 2.5rem Otypical Headline;
  --unity-alias-desktop-heading-xl: 500 3rem / 3.25rem Otypical Headline;
  --unity-alias-desktop-display-xs: 500 3.5rem / 4rem Otypical Headline;
  --unity-alias-desktop-display-sm: 500 4.5rem / 4.625rem Otypical Headline;
  --unity-alias-desktop-display-md: 500 6rem / 6.125rem Otypical Headline;
  --unity-alias-desktop-display-lg: 500 9rem / 8.25rem Otypical Headline;
  --unity-alias-desktop-display-xl: 500 12.5rem / 11.125rem Otypical Headline;
  --unity-alias-typography-status: 500 1.125rem / 1.5rem Otypical Headline;
  --unity-alias-typography-numbers-3-xl: 500 3rem / 3.25rem Otypical Headline; /* TBD */
  --unity-alias-typography-numbers-2-xl: 500 2.25rem / 2.5rem Otypical Headline; /* TBD */
  --unity-alias-typography-numbers-xl: 500 2rem / 2.25rem Otypical Headline; /* TBD */
  --unity-alias-typography-numbers-lg: 500 1.25rem / 2rem Otypical Headline; /* TBD */
  --unity-alias-typography-numbers-md: 500 1.125rem / 1.5rem Otypical Headline; /* TBD */
  --unity-alias-typography-numbers-sm: 500 1rem / 1.5rem Otypical Headline; /* TBD */
  --unity-alias-typography-navigation: 500 1.25rem / 1.5rem Otypical Headline;
  --unity-alias-typography-title-message: 500 1.25rem / 1.5rem Otypical Headline;
  --unity-alias-button-typography-lg: 500 1.25rem / 2rem Otypical Text;
  --unity-alias-button-typography-md: 500 1rem / 1.5rem Otypical Text;
  --unity-alias-button-typography-sm: 500 0.875rem / 1.375rem Otypical Text;
  --unity-alias-size-item-xl: 5.5rem; /* TBD */
  --unity-alias-size-item-lg: 3.75rem; /* TBD */
  --unity-alias-size-item-md: 2.5rem; /* TBD */
  --unity-alias-size-item-sm: 2rem; /* TBD */
  --unity-alias-size-item-xs: 1.5rem; /* TBD */
  --unity-alias-size-cta-height-lg: 4rem; /* TBD */
  --unity-alias-size-cta-height-md: 3rem; /* TBD */
  --unity-alias-size-cta-height-sm: 2rem; /* TBD */
  --unity-alias-navigation-meta-mobile-selected: 500 1rem / 1.5rem Otypical Headline;
  --unity-alias-navigation-meta-mobile-default: 400 1rem / 1.5rem Otypical Headline;
  --unity-alias-navigation-meta-desktop-selected: 500 0.875rem / 1.0625rem Otypical Headline;
  --unity-alias-navigation-meta-desktop-default: 400 0.875rem / 1.0625rem Otypical Headline;
  --unity-alias-navigation-main-secondary: 400 1rem / 1.5rem Otypical Headline;
  --unity-alias-navigation-main-primary: 500 1rem / 1.5rem Otypical Headline;
  --unity-alias-border-radius-lg: 1rem; /* TBD */
  --unity-alias-border-radius-md: 0.75rem; /* TBD */
  --unity-alias-border-radius-sm: 0.5rem; /* TBD */
  --unity-alias-border-radius-xs: 0.25rem; /* TBD */
  --unity-alias-border-radius-none: 0; /* TBD */
  --unity-alias-input-text-typography-success: 400 1rem / 1.5rem Otypical Headline;
  --unity-alias-input-text-typography-error: 400 1rem / 1.5rem Otypical Headline;
  --unity-alias-input-text-typography-helper: 400 1rem / 1.5rem Otypical Headline;
  --unity-alias-input-typography-value: 400 1rem / 1.5rem Otypical Headline;
  --unity-alias-input-typography-placeholder: 400 1rem / 1.5rem Otypical Headline;
  --unity-alias-input-label-typography-floating: 400 1rem / 1.5rem Otypical Headline;
  --unity-alias-input-label-typography-default: 500 1rem / 1.5rem Otypical Headline;
  --unity-alias-cta-border-width: 0.125rem; /* TBD */
  --unity-alias-cta-border-radius-lg: 4rem; /* TBD */
  --unity-alias-cta-border-radius-md: 3rem; /* TBD */
  --unity-alias-cta-border-radius-sm: 2rem; /* TBD */
  --unity-alias-space-button-lg-items: 1rem; /* Padding vertical */
  --unity-alias-space-button-lg-vertical: 1.25rem; /* Padding vertical */
  --unity-alias-space-button-lg-horizontal: 2rem; /* TBD */
  --unity-alias-space-button-md-items: 0.75rem; /* Padding vertical */
  --unity-alias-space-button-md-vertical: 0.75rem; /* Padding vertical */
  --unity-alias-space-button-md-horizontal: 1.5rem; /* TBD */
  --unity-alias-space-button-sm-items: 0.5rem; /* Padding vertical */
  --unity-alias-space-button-sm-vertical: 0.5rem; /* Padding vertical */
  --unity-alias-space-button-sm-horizontal: 1rem; /* TBD */
  --unity-alias-space-items-lg: 0.375rem; /* TBD */
  --unity-alias-space-items-md: 0.125rem; /* TBD */
  --unity-alias-space-items-sm: 0.25rem; /* TBD */
  --unity-alias-space-surface-lg-items: 0.375rem; /* TBD */
  --unity-alias-space-surface-lg-vertical: 0.5rem; /* TBD */
  --unity-alias-space-surface-lg-horizontal: 0.5rem; /* TBD */
  --unity-alias-space-surface-md-items: 0.25rem; /* TBD */
  --unity-alias-space-surface-md-vertical: 0.25rem; /* TBD */
  --unity-alias-space-surface-md-horizontal: 0.25rem; /* TBD */
  --unity-alias-space-surface-sm-items: 0.25rem; /* TBD */
  --unity-alias-space-surface-sm-vertical: 0.375rem; /* TBD */
  --unity-alias-space-surface-sm-horizontal: 0.25rem; /* TBD */
  --unity-alias-space-surface-xs-items: 0.125rem; /* TBD */
  --unity-alias-space-surface-xs-vertical: 0.125rem; /* TBD */
  --unity-alias-space-surface-xs-horizontal: 0.125rem; /* TBD */
  --unity-alias-space-surface-2-xs-items: 0.125rem; /* TBD */
  --unity-alias-space-surface-2-xs-vertical: 0.25rem; /* TBD */
  --unity-alias-space-surface-2-xs-horizontal: 0.125rem; /* TBD */
  --unity-alias-space-numbers-md-horizontal: 0.25rem; /* TBD */
  --unity-alias-space-numbers-sm-vertical: 0; /* TBD */
  --unity-alias-space-numbers-sm-horizontal: 0; /* TBD */
  --unity-alias-space-navigation-items: 0.25rem; /* TBD */
  --unity-alias-space-navigation-vertical: 0.25rem; /* TBD */
  --unity-alias-space-cta-lg-items: 0.25rem; /* TBD */
  --unity-alias-space-cta-lg-vertical: 0.125rem; /* TBD */
  --unity-alias-space-cta-lg-horizontal: 0.375rem; /* TBD */
  --unity-alias-space-cta-md-items: 0.25rem; /* TBD */
  --unity-alias-space-cta-md-vertical: 0.125rem; /* TBD */
  --unity-alias-space-cta-md-horizontal: 0.375rem; /* TBD */
  --unity-alias-space-cta-sm-items: 0.125rem; /* TBD */
  --unity-alias-space-cta-sm-vertical: 0.125rem; /* TBD */
  --unity-alias-space-cta-sm-horizontal: 0.25rem; /* TBD */
  --unity-alias-space-cta-xs-horizontal: 0.125rem; /* TBD */
  --unity-alias-divider-color-muted-dark: #444444; /* TBD */
  --unity-alias-divider-color-muted: #DDDDDD; /* TBD */
  --unity-alias-divider-color-base: #222222; /* TBD */
  --unity-alias-icon-size-2-xl: 5rem; /* TBD */
  --unity-alias-icon-size-xl: 4rem; /* TBD */
  --unity-alias-icon-size-lg: 3rem; /* TBD */
  --unity-alias-icon-size-md: 2rem; /* TBD */
  --unity-alias-icon-size-sm: 1.5rem; /* TBD */
  --unity-alias-icon-size-xs: 1rem; /* TBD */
  --unity-alias-icon-color-transparents-inverted: #ffffff66; /* TBD
*/
  --unity-alias-icon-color-transparents-base: #22222266; /* TBD */
  --unity-alias-icon-color-inverted: #fff; /* TBD */
  --unity-alias-icon-color-muted: #999999; /* TBD */
  --unity-alias-icon-color-base: #222222; /* TBD */
  --unity-alias-text-color-inverted: #fff; /* TBD */
  --unity-alias-text-color-muted-dark: #999999; /* TBD */
  --unity-alias-text-color-muted: #444444; /* TBD */
  --unity-alias-text-color-base: #222222; /* TBD */
  --unity-alias-glows-color-purple-700: #7066ff; /* TBD */
  --unity-alias-glows-color-purple-500: #8D85FF; /* TBD */
  --unity-alias-glows-color-purple-300: #A9A3FF; /* TBD */
  --unity-alias-glows-color-purple-100: #C6C2FF; /* TBD */
  --unity-alias-glows-color-pink-700: #ff808c; /* TBD */
  --unity-alias-glows-color-pink-500: #FF99A3; /* TBD */
  --unity-alias-glows-color-pink-300: #FFB3BA; /* TBD */
  --unity-alias-glows-color-pink-100: #FFCCD1; /* TBD */
  --unity-alias-glows-color-orange-700: #ff7621; /* TBD */
  --unity-alias-glows-color-orange-500: #FF914D; /* TBD */
  --unity-alias-glows-color-orange-300: #FFAD7A; /* TBD */
  --unity-alias-glows-color-orange-100: #FFC8A6; /* TBD */
  --unity-alias-glows-color-yellow-700: #ffac24; /* TBD */
  --unity-alias-glows-color-yellow-500: #FFBD50; /* TBD */
  --unity-alias-glows-color-yellow-300: #FFCD7C; /* TBD */
  --unity-alias-glows-color-yellow-100: #FFDEA7; /* TBD */
  --unity-alias-glows-color-green-700: #2f9a92; /* TBD */
  --unity-alias-glows-color-green-500: #59AEA8; /* TBD */
  --unity-alias-glows-color-green-300: #82C2BE; /* TBD */
  --unity-alias-glows-color-green-100: #ACD7D3; /* TBD */
  --unity-alias-glows-color-blue-700: #2c72ff; /* TBD */
  --unity-alias-glows-color-blue-500: #568EFF; /* TBD */
  --unity-alias-glows-color-blue-300: #80AAFF; /* TBD */
  --unity-alias-glows-color-blue-100: #ABC7FF; /* TBD */
  --unity-alias-color-numbers-muted-dark: #444444; /* TBD */
  --unity-alias-color-numbers-base: #000000; /* TBD */
  --unity-alias-color-states-persuasive-loading: #1B5EE4; /* TBD */
  --unity-alias-color-states-persuasive-disabled: #1B5EE4; /* Add opacity to the parent component, not in the token itself. */
  --unity-alias-color-states-persuasive-focus: #2c72ff; /* TBD */
  --unity-alias-color-states-persuasive-active: #1B5EE4; /* TBD */
  --unity-alias-color-states-persuasive-hover: #2c72ff; /* TBD */
  --unity-alias-color-states-persuasive-default: #1B5EE4; /* TBD */
  --unity-alias-color-states-inverted-loading: #fff; /* TBD */
  --unity-alias-color-states-inverted-disabled: #fff; /* Add opacity to the parent component, not in the token itself. */
  --unity-alias-color-states-inverted-focus: #DDDDDD; /* TBD */
  --unity-alias-color-states-inverted-active: #fff; /* TBD */
  --unity-alias-color-states-inverted-hover: #DDDDDD; /* TBD */
  --unity-alias-color-states-inverted-default: #fff; /* TBD */
  --unity-alias-color-states-base-loading: #222222; /* TBD */
  --unity-alias-color-states-base-disabled: #222222; /* Add opacity to the parent component, not in the token itself. */
  --unity-alias-color-states-base-focus: #444444; /* TBD */
  --unity-alias-color-states-base-active: #222222; /* TBD */
  --unity-alias-color-states-base-hover: #444444; /* TBD */
  --unity-alias-color-states-base-default: #000000; /* TBD */
  --unity-alias-color-semantic-warning: #ff7621; /* Warning callout message (icon). */
  --unity-alias-color-semantic-danger: #FE5F55; /* Error callout message (icon). */
  --unity-alias-color-semantic-success: #2f9a92; /* Success callout message (icon).
*/
  --unity-alias-color-semantic-information: #2c72ff; /* Information callout message (icon). */
  --unity-alias-color-sales: #1B5EE4; /* TBD */
  --unity-alias-color-outline-focus-outer: #1b5ee41a; /* TBD */
  --unity-alias-color-outline-focus-inner: #1B5EE4; /* TBD */
  --unity-alias-color-brand-secondary: #fff; /* TBD */
  --unity-alias-color-brand-primary: #000000; /* TBD */
  --unity-alias-color-border-selected: #222222; /* TBD */
  --unity-alias-color-border-focus: #000000; /* TBD */
  --unity-alias-color-border-hover: #999999; /* TBD */
  --unity-alias-color-border-default: #DDDDDD; /* TBD */
  --unity-alias-color-background-purple: #E8E7FF; /* TBD */
  --unity-alias-color-background-pink: #FFF5F6; /* TBD */
  --unity-alias-color-background-green: #EEF7F6; /* TBD */
  --unity-alias-color-background-blue: #ECF2FF; /* TBD */
  --unity-alias-color-background-inverted: #222222; /* TBD */
  --unity-alias-color-background-disabled: #DDDDDD; /* TBD */
  --unity-alias-color-background-base: #fff; /* TBD */
  --unity-component-help-tip-color-icon-base-base: #222222;
  --unity-component-help-tip-color-icon-inverted-inverted: #fff;
  --unity-component-help-tip-icon-size: 1.5rem;
  --unity-component-stock-status-text-color-out-of-stock-default: #FE5F55; /* T.B.D */
  --unity-component-stock-status-text-color-limited-in-stock-default: #ff7621; /* T.B.D */
  --unity-component-stock-status-text-color-in-stock-default: #2f9a92; /* T.B.D */
  --unity-component-stock-status-typography: 500 1.125rem / 1.5rem Otypical Headline;
  --unity-component-tabs-opacity-disabled: 0.2;
  --unity-component-tabs-space-items: 0.25rem;
  --unity-component-tabs-space-vertical: 0.25rem;
  --unity-component-tabs-typography: 500 1.25rem / 1.5rem Otypical Headline;
  --unity-component-tabs-color-border-selected-disabled: #222222;
  --unity-component-tabs-color-border-selected-focus: #444444;
  --unity-component-tabs-color-border-selected-active: #222222;
  --unity-component-tabs-color-border-selected-hover: #444444;
  --unity-component-tabs-color-border-selected-default: #000000;
  --unity-component-tabs-color-border-deselected-disabled: #222222;
  --unity-component-tabs-color-border-deselected-focus: #444444;
  --unity-component-tabs-color-border-deselected-active: #222222;
  --unity-component-tabs-color-border-deselected-hover: #444444;
  --unity-component-tabs-color-border-deselected-default: #000000;
  --unity-component-tabs-text-color-selected-disabled: #222222;
  --unity-component-tabs-text-color-selected-focus: #444444;
  --unity-component-tabs-text-color-selected-active: #222222;
  --unity-component-tabs-text-color-selected-hover: #444444;
  --unity-component-tabs-text-color-selected-default: #000000;
  --unity-component-tabs-text-color-deselected-disabled: #222222;
  --unity-component-tabs-text-color-deselected-focus: #444444;
  --unity-component-tabs-text-color-deselected-active: #222222;
  --unity-component-tabs-text-color-deselected-hover: #444444;
  --unity-component-tabs-text-color-deselected-default: #000000;
  --unity-component-tabs-border-width-deselected-disabled: 0;
  --unity-component-tabs-border-width-deselected-focus: 0.125rem;
  --unity-component-tabs-border-width-deselected-active: 0.125rem;
  --unity-component-tabs-border-width-deselected-hover: 0.125rem;
  --unity-component-tabs-border-width-deselected-default: 0;
  --unity-component-tabs-border-width-selected-disabled: 0.0625rem;
  --unity-component-tabs-border-width-selected-focus: 0.125rem;
  --unity-component-tabs-border-width-selected-active: 0.25rem;
  --unity-component-tabs-border-width-selected-hover: 0.125rem;
  --unity-component-tabs-border-width-selected-default: 0.125rem;
  --unity-component-metrics-color-digit-base-default: #000000;
  --unity-component-metrics-color-suffix-base-default: #000000;
  --unity-component-metrics-typography-digit-lg: 500 2.25rem / 2.5rem Otypical Headline;
  --unity-component-metrics-typography-digit-md: 500 2rem / 2.25rem Otypical Headline;
  --unity-component-metrics-typography-suffix-lg: 500 1rem / 1.5rem Otypical Headline;
  --unity-component-metrics-typography-suffix-md: 500 1rem / 1.5rem Otypical Headline;
  --unity-component-price-space-lg-horizontal: 0.25rem;
  --unity-component-price-space-md-horizontal: 0.25rem;
  --unity-component-price-opacity-disabled: 0.2;
  --unity-component-price-color-digit-base-disabled: #000000;
  --unity-component-price-color-digit-base-default: #000000;
  --unity-component-price-color-currency-base-disabled: #000000;
  --unity-component-price-color-currency-base-default: #000000;
  --unity-component-price-color-comma-base-disabled: #000000;
  --unity-component-price-color-comma-base-default: #000000;
  --unity-component-price-color-suffix-base-disabled: #000000;
  --unity-component-price-color-suffix-base-default: #000000;
  --unity-component-price-color-discount-base-disabled: #444444;
  --unity-component-price-color-discount-base-default: #444444;
  --unity-component-price-typography-digit-lg: 500 2rem / 2.25rem Otypical Headline;
  --unity-component-price-typography-digit-md: 500 1rem / 1.5rem Otypical Headline;
  --unity-component-price-typography-currency-lg: 500 2rem / 2.25rem Otypical Headline;
  --unity-component-price-typography-currency-md: 500 1rem / 1.5rem Otypical Headline;
  --unity-component-price-typography-comma-lg: 500 1.25rem / 2rem Otypical Headline;
  --unity-component-price-typography-suffix-lg: 500 1rem / 1.5rem Otypical Headline;
  --unity-component-price-typography-suffix-md: 500 1rem / 1.5rem Otypical Headline;
  --unity-component-price-typography-discount-md: 500 1rem / 1.5rem Otypical Headline;
  --unity-component-divider-color-background-muted: #DDDDDD;
  --unity-component-divider-color-background-base: #222222;
  --unity-component-tooltip-color-background: #222222;
  --unity-component-tooltip-text-color-default: #fff;
  --unity-component-tooltip-border-radius: 0.25rem;
  --unity-component-tooltip-icon-color-background: #2c72ff;
  --unity-component-tooltip-icon-color-base: #fff;
  --unity-component-tooltip-icon-size-md: 1.5rem;
  --unity-component-tooltip-icon-size-sm: 1.5rem;
  --unity-component-tooltip-typography: 400 0.875rem / 1.375rem Otypical Text;
  --unity-component-input-color-value-base: #222222;
  --unity-component-input-color-value-disabled: #444444;
  --unity-component-input-color-placeholder: #444444;
  --unity-component-input-color-border-error: #FE5F55;
  --unity-component-input-color-border-success: #2f9a92;
  --unity-component-input-color-border-selected: #222222;
  --unity-component-input-color-border-focus: #000000;
  --unity-component-input-color-border-selected-disabled: #999999;
  --unity-component-input-color-border-hover: #999999;
  --unity-component-input-color-border-default: #DDDDDD;
  --unity-component-input-color-background-message-helper: #444444;
  --unity-component-input-color-background-message-error: #FE5F55;
  --unity-component-input-color-background-disabled: #DDDDDD;
  --unity-component-input-color-background-base: #fff;
  --unity-component-input-typography-message: 400 0.875rem / 1.375rem Otypical Text;
  --unity-component-input-typography-value: 400 1rem / 1.5rem Otypical Text;
  --unity-component-input-label-color-error: #FE5F55;
  --unity-component-input-label-color-success: #2f9a92;
  --unity-component-input-label-color-base: #222222;
  --unity-component-input-label-typography-floating: 400 0.875rem / 1.375rem Otypical Text;
  --unity-component-input-label-typography-base: 400 1rem / 1.5rem Otypical Text;
  --unity-component-input-border-radius: 0.25rem;
  --unity-component-input-icon-color-surface-error: #FE5F55;
  --unity-component-input-icon-color-surface-dropdown: #22222266;
  --unity-component-input-icon-color-surface-base: #fff;
  --unity-component-input-icon-color-surface-success: #2f9a92;
  --unity-component-input-icon-size-md: 1.5rem;
  --unity-component-input-icon-size-sm: 2rem;
  --unity-component-badge-color-text-dark-base: #222222;
  --unity-component-badge-color-text-light-base: #fff;
  --unity-component-badge-color-background-dark-base: #fff;
  --unity-component-badge-color-background-light-base: #000000;
  --unity-component-badge-typography-default: 500 0.6875rem / 1.0625rem Otypical Headline;
  --unity-component-bottom-nav-typography-active: 500 0.875rem / 1.375rem Otypical Text;
  --unity-component-bottom-nav-typography-default: 400 0.875rem / 1.375rem Otypical Text;
  --unity-component-bottom-nav-icon-size: 1.5rem;
  --unity-component-bottom-nav-color-icon-dark-active: #fff;
  --unity-component-bottom-nav-color-icon-dark-default: #999999;
  --unity-component-bottom-nav-color-icon-light-active: #222222;
  --unity-component-bottom-nav-color-icon-light-default: #999999;
  --unity-component-bottom-nav-color-background-dark-default: #222222;
  --unity-component-bottom-nav-color-background-light-default: #fff;
  --unity-component-bottom-nav-color-text-dark-active: #fff;
  --unity-component-bottom-nav-color-text-dark-default: #999999;
  --unity-component-bottom-nav-color-text-light-active: #222222;
  --unity-component-bottom-nav-color-text-light-default: #444444;
  --unity-component-bottom-nav-color-border-dark-default: #444444;
  --unity-component-bottom-nav-color-border-light-default: #DDDDDD;
  --unity-component-textlink-color-persuasive-default: #1B5EE4;
  --unity-component-textlink-typography-md: 500 0.875rem / 1.375rem Otypical Headline;
  --unity-component-textlink-space-horizontal: 0.125rem;
  --unity-component-callout-description-icon-border-radius: 4rem;
  --unity-component-callout-message-icon-border-radius: 2rem;
  --unity-component-callout-border-radius-bottom-right: 3rem;
  --unity-component-callout-border-radius-top-right: 3rem;
  --unity-component-callout-border-radius-top-left: 3rem;
  --unity-component-callout-space-items-horizontal: 0.125rem;
  --unity-component-callout-space-items-vertical: 0.125rem;
  --unity-component-callout-space-surface-surface: 0.5rem;
  --unity-component-callout-color-border-warning: #ff7621; /* Warning callout message (icon). */
  --unity-component-callout-color-border-danger: #FE5F55; /* Error callout message (icon). */
  --unity-component-callout-color-border-success: #2f9a92; /* Success callout message (icon).
*/
  --unity-component-callout-color-border-information: #2c72ff; /* Information callout message (icon). */
  --unity-component-callout-background-inverted: #000000;
  --unity-component-callout-background-base: #fff;
  --unity-component-callout-background-warning: #ff7621; /* Warning callout message (icon). */
  --unity-component-callout-background-danger: #FE5F55; /* Error callout message (icon). */
  --unity-component-callout-background-success: #2f9a92; /* Success callout message (icon).
*/
  --unity-component-callout-background-information: #2c72ff; /* Information callout message (icon). */
  --unity-component-callout-description-typography: 400 1rem / 1.5rem Otypical Text;
  --unity-component-callout-title-typography: 400 1rem / 1.5rem Otypical Text;
  --unity-component-callout-text-color-base-inverted: #fff;
  --unity-component-callout-text-color-base-base: #222222;
  --unity-component-callout-icon-color-base-base: #222222;
  --unity-component-callout-icon-color-base-inverted: #fff;
  --unity-component-callout-size-description-icon: 1.5rem;
  --unity-component-callout-size-message-icon: 2rem;
  --unity-component-button-opacity-disabled: 0.2; /* TBD */
  --unity-component-button-size-height-lg: 4rem;
  --unity-component-button-size-height-md: 3rem;
  --unity-component-button-size-height-sm: 2rem;
  --unity-component-button-icon-size-lg: 1.5rem;
  --unity-component-button-icon-size-md: 1.5rem;
  --unity-component-button-icon-size-sm: 1rem;
  --unity-component-button-icon-color-persuasive-loading-transparency-solid: #ffffff66;
  --unity-component-button-icon-color-persuasive-loading-transparency-outline: #22222266;
  --unity-component-button-icon-color-persuasive-loading-solid: #fff;
  --unity-component-button-icon-color-persuasive-loading-outline: #222222;
  --unity-component-button-icon-color-persuasive-disabled-solid: #fff;
  --unity-component-button-icon-color-persuasive-disabled-outline: #222222;
  --unity-component-button-icon-color-persuasive-focus-solid: #fff;
  --unity-component-button-icon-color-persuasive-focus-outline: #fff;
  --unity-component-button-icon-color-persuasive-active-solid: #fff;
  --unity-component-button-icon-color-persuasive-active-outline: #fff;
  --unity-component-button-icon-color-persuasive-hover-solid: #fff;
  --unity-component-button-icon-color-persuasive-hover-outline: #fff;
  --unity-component-button-icon-color-persuasive-default-solid: #fff;
  --unity-component-button-icon-color-persuasive-default-outline: #222222;
  --unity-component-button-icon-color-inverted-loading-transparency-solid: #22222266;
  --unity-component-button-icon-color-inverted-loading-transparency-outline: #ffffff66;
  --unity-component-button-icon-color-inverted-loading-solid: #222222;
  --unity-component-button-icon-color-inverted-loading-outline: #fff;
  --unity-component-button-icon-color-inverted-disabled-solid: #222222;
  --unity-component-button-icon-color-inverted-disabled-outline: #fff;
  --unity-component-button-icon-color-inverted-focus-solid: #222222;
  --unity-component-button-icon-color-inverted-focus-outline: #222222;
  --unity-component-button-icon-color-inverted-active-solid: #222222;
  --unity-component-button-icon-color-inverted-active-outline: #222222;
  --unity-component-button-icon-color-inverted-hover-solid: #222222;
  --unity-component-button-icon-color-inverted-hover-outline: #222222;
  --unity-component-button-icon-color-inverted-default-solid: #222222;
  --unity-component-button-icon-color-inverted-default-outline: #fff;
  --unity-component-button-icon-color-base-loading-transparency-solid: #ffffff66;
  --unity-component-button-icon-color-base-loading-transparency-outline: #22222266;
  --unity-component-button-icon-color-base-loading-solid: #fff;
  --unity-component-button-icon-color-base-loading-outline: #222222;
  --unity-component-button-icon-color-base-disabled-solid: #fff;
  --unity-component-button-icon-color-base-disabled-outline: #222222;
  --unity-component-button-icon-color-base-focus-solid: #fff;
  --unity-component-button-icon-color-base-focus-outline: #fff;
  --unity-component-button-icon-color-base-active-solid: #fff;
  --unity-component-button-icon-color-base-active-outline: #fff;
  --unity-component-button-icon-color-base-hover-solid: #fff;
  --unity-component-button-icon-color-base-hover-outline: #fff;
  --unity-component-button-icon-color-base-default-solid: #fff;
  --unity-component-button-icon-color-base-default-outline: #222222;
  --unity-component-button-color-border-persuasive-disabled-outline: #1B5EE4;
  --unity-component-button-color-border-persuasive-focus-outline: transparent;
  --unity-component-button-color-border-persuasive-active-outline: transparent;
  --unity-component-button-color-border-persuasive-hover-outline: transparent;
  --unity-component-button-color-border-persuasive-loading-outline: #1B5EE4;
  --unity-component-button-color-border-persuasive-default-outline: #1B5EE4;
  --unity-component-button-color-border-inverted-disabled-outline: #fff;
  --unity-component-button-color-border-inverted-focus-outline: transparent;
  --unity-component-button-color-border-inverted-active-outline: transparent;
  --unity-component-button-color-border-inverted-hover-outline: transparent;
  --unity-component-button-color-border-inverted-loading-outline: #fff;
  --unity-component-button-color-border-inverted-default-outline: #fff;
  --unity-component-button-color-border-base-disabled-outline: #222222;
  --unity-component-button-color-border-base-focus-outline: transparent;
  --unity-component-button-color-border-base-active-outline: transparent;
  --unity-component-button-color-border-base-hover-outline: transparent;
  --unity-component-button-color-border-base-loading-outline: #222222;
  --unity-component-button-color-border-base-default-outline: #000000;
  --unity-component-button-color-background-persuasive-disabled-solid: #1B5EE4;
  --unity-component-button-color-background-persuasive-disabled-outline: transparent;
  --unity-component-button-color-background-persuasive-focus-solid: #2c72ff;
  --unity-component-button-color-background-persuasive-focus-outline: #2c72ff;
  --unity-component-button-color-background-persuasive-active-solid: #1B5EE4;
  --unity-component-button-color-background-persuasive-active-outline: #1B5EE4;
  --unity-component-button-color-background-persuasive-hover-solid: #2c72ff;
  --unity-component-button-color-background-persuasive-hover-outline: #2c72ff;
  --unity-component-button-color-background-persuasive-default-solid: #1B5EE4;
  --unity-component-button-color-background-persuasive-default-outline: transparent;
  --unity-component-button-color-background-persuasive-loading-solid: #1B5EE4;
  --unity-component-button-color-background-persuasive-loading-outline: transparent;
  --unity-component-button-color-background-inverted-disabled-solid: #fff;
  --unity-component-button-color-background-inverted-disabled-outline: transparent;
  --unity-component-button-color-background-inverted-focus-solid: #DDDDDD;
  --unity-component-button-color-background-inverted-focus-outline: #DDDDDD;
  --unity-component-button-color-background-inverted-active-solid: #fff;
  --unity-component-button-color-background-inverted-active-outline: #fff;
  --unity-component-button-color-background-inverted-hover-solid: #DDDDDD;
  --unity-component-button-color-background-inverted-hover-outline: #DDDDDD;
  --unity-component-button-color-background-inverted-default-solid: #fff;
  --unity-component-button-color-background-inverted-default-outline: transparent;
  --unity-component-button-color-background-inverted-loading-solid: #fff;
  --unity-component-button-color-background-inverted-loading-outline: transparent;
  --unity-component-button-color-background-base-disabled-solid: #222222;
  --unity-component-button-color-background-base-disabled-outline: transparent;
  --unity-component-button-color-background-base-focus-solid: #444444;
  --unity-component-button-color-background-base-focus-outline: #444444;
  --unity-component-button-color-background-base-active-solid: #222222;
  --unity-component-button-color-background-base-active-outline: #222222;
  --unity-component-button-color-background-base-hover-solid: #444444;
  --unity-component-button-color-background-base-hover-outline: #444444;
  --unity-component-button-color-background-base-loading-solid: #000000;
  --unity-component-button-color-background-base-loading-outline: transparent;
  --unity-component-button-color-background-base-default-solid: #000000;
  --unity-component-button-color-background-base-default-outline: transparent;
  --unity-component-button-border-width: 0.125rem;
  --unity-component-button-border-radius-lg: 4rem;
  --unity-component-button-border-radius-md: 3rem;
  --unity-component-button-border-radius-sm: 2rem;
  --unity-component-button-text-color-persuasive-disabled-solid: #fff;
  --unity-component-button-text-color-persuasive-disabled-outline: #222222;
  --unity-component-button-text-color-persuasive-focus-solid: #fff;
  --unity-component-button-text-color-persuasive-focus-outline: #fff;
  --unity-component-button-text-color-persuasive-active-solid: #fff;
  --unity-component-button-text-color-persuasive-active-outline: #fff;
  --unity-component-button-text-color-persuasive-hover-solid: #fff;
  --unity-component-button-text-color-persuasive-hover-outline: #fff;
  --unity-component-button-text-color-persuasive-default-solid: #fff;
  --unity-component-button-text-color-persuasive-default-outline: #222222;
  --unity-component-button-text-color-inverted-disabled-solid: #222222;
  --unity-component-button-text-color-inverted-disabled-outline: #fff;
  --unity-component-button-text-color-inverted-focus-solid: #222222;
  --unity-component-button-text-color-inverted-focus-outline: #222222;
  --unity-component-button-text-color-inverted-active-solid: #222222;
  --unity-component-button-text-color-inverted-active-outline: #222222;
  --unity-component-button-text-color-inverted-hover-solid: #222222;
  --unity-component-button-text-color-inverted-hover-outline: #222222;
  --unity-component-button-text-color-inverted-default-solid: #222222;
  --unity-component-button-text-color-inverted-default-outline: #fff;
  --unity-component-button-text-color-base-disabled-solid: #fff;
  --unity-component-button-text-color-base-disabled-outline: #222222;
  --unity-component-button-text-color-base-focus-solid: #fff;
  --unity-component-button-text-color-base-focus-outline: #fff;
  --unity-component-button-text-color-base-active-solid: #fff;
  --unity-component-button-text-color-base-active-outline: #fff;
  --unity-component-button-text-color-base-hover-solid: #fff;
  --unity-component-button-text-color-base-hover-outline: #fff;
  --unity-component-button-text-color-base-default-solid: #fff;
  --unity-component-button-text-color-base-default-outline: #000000;
  --unity-component-button-typography-lg: 500 1.25rem / 2rem Otypical Text;
  --unity-component-button-typography-md: 500 1rem / 1.5rem Otypical Text;
  --unity-component-button-typography-sm: 500 0.875rem / 1.375rem Otypical Text;
  --unity-component-button-space-lg-items: 1rem;
  --unity-component-button-space-lg-vertical: 1.25rem;
  --unity-component-button-space-lg-horizontal: 2rem;
  --unity-component-button-space-md-items: 0.75rem;
  --unity-component-button-space-md-vertical: 0.75rem;
  --unity-component-button-space-md-horizontal: 1.5rem;
  --unity-component-button-space-sm-items: 0.5rem;
  --unity-component-button-space-sm-vertical: 0.5rem;
  --unity-component-button-space-sm-horizontal: 1rem;
}

/** css variables import for dark mode, uncomment the following lines to include the dark themes when you want start the implementation for those */
.datepicker--cell-year.-other-decade-, .datepicker--cell-day.-other-month- {
  color: #d9d9d9;
}
.datepicker--cell-year.-other-decade-:hover, .datepicker--cell-day.-other-month-:hover {
  color: silver;
}
.-disabled-.-focus-.datepicker--cell-year.-other-decade-, .-disabled-.-focus-.datepicker--cell-day.-other-month- {
  color: #d9d9d9;
}
.-selected-.datepicker--cell-year.-other-decade-, .-selected-.datepicker--cell-day.-other-month- {
  color: #fff;
  background: #262626;
}
.-selected-.-focus-.datepicker--cell-year.-other-decade-, .-selected-.-focus-.datepicker--cell-day.-other-month- {
  background: #1a1a1a;
}
.-in-range-.datepicker--cell-year.-other-decade-, .-in-range-.datepicker--cell-day.-other-month- {
  background-color: rgba(255, 255, 255, 0.2);
  color: #c7c7c7;
}
.-in-range-.-focus-.datepicker--cell-year.-other-decade-, .-in-range-.-focus-.datepicker--cell-day.-other-month- {
  background-color: rgba(255, 255, 255, 0.2);
}
.datepicker--cell-year.-other-decade-:empty, .datepicker--cell-day.-other-month-:empty {
  background: none;
  border: none;
}

.-only-timepicker- {
  height: 5.25rem !important;
  min-width: 17.375rem !important;
}
.-only-timepicker- .datepicker--time {
  padding: var(--unity-global-space-16);
  position: relative;
}
.-only-timepicker- .datepicker--time-current {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background: #f6f6f6;
  border-radius: var(--unity-global-border-radius-4);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: var(--unity-global-font-size-20);
  font-weight: var(--unity-global-font-weights-medium);
  height: 3.25rem;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin: 0;
  left: var(--unity-global-space-16);
  position: absolute;
  top: var(--unity-global-space-16);
  width: var(--unity-global-size-80);
}
.-only-timepicker- .datepicker--time-sliders {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  height: 3.25rem;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-left: calc(var(--unity-global-size-80) + var(--unity-global-space-16));
}
.-only-timepicker- .datepicker--time-row {
  background: transparent;
  color: #757575;
  font-size: var(--unity-global-font-size-20);
  height: var(--unity-global-space-16);
}
.-only-timepicker- .datepicker--time-row::after {
  margin-left: var(--unity-global-space-16);
}
.-only-timepicker- .datepicker--time-row:first-child {
  margin-bottom: calc(var(--unity-global-space-16) / 1.5);
}
.-only-timepicker- .datepicker--time-row:first-child::after {
  content: "Uur";
  font-size: var(--unity-global-font-size-16);
  font-weight: var(--unity-global-font-weights-regular);
}
.-only-timepicker- .datepicker--time-row:last-child::after {
  content: "Min.";
  font-size: var(--unity-global-font-size-16);
  font-weight: var(--unity-global-font-weights-regular);
}
.-only-timepicker- .datepicker--time input[type=range] {
  max-width: 6rem;
  width: 6rem;
}
.-only-timepicker- .datepicker--time input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
  background: transparent url("data:image/svg+xml,%0A%3Csvg width=%2724%27 height=%2724%27 viewBox=%270 0 24 24%27 fill=%27none%27 xmlns=%27http://www.w3.org/2000/svg%27%3E%3Crect width=%2724%27 height=%2724%27 rx=%2712%27 fill=%27black%27/%3E%3Cg clip-path=%27url%28%23clip0_556_20222%29%27%3E%3Cpath fill-rule=%27evenodd%27 clip-rule=%27evenodd%27 d=%27M5.14645 12.3536C4.95118 12.1583 4.95118 11.8417 5.14645 11.6464L9.14645 7.64645C9.34171 7.45118 9.65829 7.45118 9.85355 7.64645C10.0488 7.84171 10.0488 8.15829 9.85355 8.35355L6.20711 12L9.85355 15.6464C10.0488 15.8417 10.0488 16.1583 9.85355 16.3536C9.65829 16.5488 9.34171 16.5488 9.14645 16.3536L5.14645 12.3536Z%27 fill=%27white%27/%3E%3C/g%3E%3Cg clip-path=%27url%28%23clip1_556_20222%29%27%3E%3Cpath fill-rule=%27evenodd%27 clip-rule=%27evenodd%27 d=%27M18.8536 11.6464C19.0488 11.8417 19.0488 12.1583 18.8536 12.3536L14.8536 16.3536C14.6583 16.5488 14.3417 16.5488 14.1464 16.3536C13.9512 16.1583 13.9512 15.8417 14.1464 15.6464L17.7929 12L14.1464 8.35355C13.9512 8.15829 13.9512 7.84171 14.1464 7.64645C14.3417 7.45118 14.6583 7.45118 14.8536 7.64645L18.8536 11.6464Z%27 fill=%27white%27/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id=%27clip0_556_20222%27%3E%3Crect width=%2716%27 height=%2716%27 fill=%27white%27 transform=%27translate%280 4%29%27/%3E%3C/clipPath%3E%3CclipPath id=%27clip1_556_20222%27%3E%3Crect width=%2716%27 height=%2716%27 fill=%27white%27 transform=%27translate%288 4%29%27/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E") no-repeat;
  border: none;
  height: var(--unity-global-size-24);
  margin-top: calc((
                  var(--unity-global-size-24) - var(--unity-global-border-width-4)
                ) / 2 * -1);
  width: var(--unity-global-size-24);
}
.-only-timepicker- .datepicker--time input[type=range]:focus::-webkit-slider-thumb {
  background: transparent url("data:image/svg+xml,%0A%3Csvg width=%2724%27 height=%2724%27 viewBox=%270 0 24 24%27 fill=%27none%27 xmlns=%27http://www.w3.org/2000/svg%27%3E%3Crect width=%2724%27 height=%2724%27 rx=%2712%27 fill=%27black%27/%3E%3Cg clip-path=%27url%28%23clip0_556_20222%29%27%3E%3Cpath fill-rule=%27evenodd%27 clip-rule=%27evenodd%27 d=%27M5.14645 12.3536C4.95118 12.1583 4.95118 11.8417 5.14645 11.6464L9.14645 7.64645C9.34171 7.45118 9.65829 7.45118 9.85355 7.64645C10.0488 7.84171 10.0488 8.15829 9.85355 8.35355L6.20711 12L9.85355 15.6464C10.0488 15.8417 10.0488 16.1583 9.85355 16.3536C9.65829 16.5488 9.34171 16.5488 9.14645 16.3536L5.14645 12.3536Z%27 fill=%27white%27/%3E%3C/g%3E%3Cg clip-path=%27url%28%23clip1_556_20222%29%27%3E%3Cpath fill-rule=%27evenodd%27 clip-rule=%27evenodd%27 d=%27M18.8536 11.6464C19.0488 11.8417 19.0488 12.1583 18.8536 12.3536L14.8536 16.3536C14.6583 16.5488 14.3417 16.5488 14.1464 16.3536C13.9512 16.1583 13.9512 15.8417 14.1464 15.6464L17.7929 12L14.1464 8.35355C13.9512 8.15829 13.9512 7.84171 14.1464 7.64645C14.3417 7.45118 14.6583 7.45118 14.8536 7.64645L18.8536 11.6464Z%27 fill=%27white%27/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id=%27clip0_556_20222%27%3E%3Crect width=%2716%27 height=%2716%27 fill=%27white%27 transform=%27translate%280 4%29%27/%3E%3C/clipPath%3E%3CclipPath id=%27clip1_556_20222%27%3E%3Crect width=%2716%27 height=%2716%27 fill=%27white%27 transform=%27translate%288 4%29%27/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E") no-repeat;
  -webkit-box-shadow: inset 0 0 0 1px var(--unity-global-color-solid-black);
          box-shadow: inset 0 0 0 1px var(--unity-global-color-solid-black);
  width: var(--unity-global-size-24);
}
.-only-timepicker- .datepicker--time input[type=range]::-webkit-slider-runnable-track {
  background: #d0d0d0;
  border-radius: var(--unity-global-border-radius-4);
  height: var(--unity-global-border-width-4);
}

.datepicker--cell-year.-other-decade-, .datepicker--cell-day.-other-month- {
  color: #d9d9d9;
}
.datepicker--cell-year.-other-decade-:hover, .datepicker--cell-day.-other-month-:hover {
  color: silver;
}
.-disabled-.-focus-.datepicker--cell-year.-other-decade-, .-disabled-.-focus-.datepicker--cell-day.-other-month- {
  color: #d9d9d9;
}
.-selected-.datepicker--cell-year.-other-decade-, .-selected-.datepicker--cell-day.-other-month- {
  color: #fff;
  background: #262626;
}
.-selected-.-focus-.datepicker--cell-year.-other-decade-, .-selected-.-focus-.datepicker--cell-day.-other-month- {
  background: #1a1a1a;
}
.-in-range-.datepicker--cell-year.-other-decade-, .-in-range-.datepicker--cell-day.-other-month- {
  background-color: rgba(255, 255, 255, 0.2);
  color: #c7c7c7;
}
.-in-range-.-focus-.datepicker--cell-year.-other-decade-, .-in-range-.-focus-.datepicker--cell-day.-other-month- {
  background-color: rgba(255, 255, 255, 0.2);
}
.datepicker--cell-year.-other-decade-:empty, .datepicker--cell-day.-other-month-:empty {
  background: none;
  border: none;
}

/* -------------------------------------------------
    Datepicker
   ------------------------------------------------- */
.datepickers-container {
  position: absolute;
  left: 0;
  top: 0;
}
@media print {
  .datepickers-container {
    display: none;
  }
}

.datepicker {
  background: var(--unity-global-color-solid-white);
  border: var(--unity-global-border-width-1) solid #dbdbdb;
  -webkit-box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
          box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
  -webkit-box-sizing: content-box;
          box-sizing: content-box;
  font-size: 1rem;
  color: #000000;
  width: 15.625rem;
  position: absolute;
  left: -100000px;
  opacity: 0;
  -webkit-transition: opacity 0.3s ease, left 0s 0.3s, -webkit-transform 0.3s ease;
  transition: opacity 0.3s ease, left 0s 0.3s, -webkit-transform 0.3s ease;
  -o-transition: opacity 0.3s ease, transform 0.3s ease, left 0s 0.3s;
  transition: opacity 0.3s ease, transform 0.3s ease, left 0s 0.3s;
  transition: opacity 0.3s ease, transform 0.3s ease, left 0s 0.3s, -webkit-transform 0.3s ease;
  z-index: 100;
}
.datepicker.-from-top- {
  -webkit-transform: translateY(-0.5rem);
      -ms-transform: translateY(-0.5rem);
          transform: translateY(-0.5rem);
}
.datepicker.-from-right- {
  -webkit-transform: translateX(0.5rem);
      -ms-transform: translateX(0.5rem);
          transform: translateX(0.5rem);
}
.datepicker.-from-bottom- {
  -webkit-transform: translateY(0.5rem);
      -ms-transform: translateY(0.5rem);
          transform: translateY(0.5rem);
}
.datepicker.-from-left- {
  -webkit-transform: translateX(-0.5rem);
      -ms-transform: translateX(-0.5rem);
          transform: translateX(-0.5rem);
}
.datepicker.active {
  opacity: 1;
  -webkit-transform: translate(0);
      -ms-transform: translate(0);
          transform: translate(0);
  -webkit-transition: opacity 0.3s ease, left 0s 0s, -webkit-transform 0.3s ease;
  transition: opacity 0.3s ease, left 0s 0s, -webkit-transform 0.3s ease;
  -o-transition: opacity 0.3s ease, transform 0.3s ease, left 0s 0s;
  transition: opacity 0.3s ease, transform 0.3s ease, left 0s 0s;
  transition: opacity 0.3s ease, transform 0.3s ease, left 0s 0s, -webkit-transform 0.3s ease;
}
.datepicker .datepicker--pointer {
  display: none;
}

.datepicker-inline .datepicker {
  border-color: #d7d7d7;
  -webkit-box-shadow: none;
          box-shadow: none;
  position: static;
  left: auto;
  right: auto;
  opacity: 1;
  -webkit-transform: none;
      -ms-transform: none;
          transform: none;
}
.datepicker-inline .datepicker--pointer {
  display: none;
}

.datepicker--content {
  -webkit-box-sizing: content-box;
          box-sizing: content-box;
  padding: 0.5rem;
}
.-only-timepicker- .datepicker--content {
  display: none;
}

.datepicker--pointer {
  position: absolute;
  background: var(--unity-global-color-solid-white);
  border-top: var(--unity-global-border-width-1) solid #dbdbdb;
  border-right: var(--unity-global-border-width-1) solid #dbdbdb;
  width: 0.625rem;
  height: 0.625rem;
  z-index: -1;
}
.-top-left- .datepicker--pointer, .-top-center- .datepicker--pointer, .-top-right- .datepicker--pointer {
  top: calc(100% - -0.6875rem);
  -webkit-transform: rotate(135deg);
      -ms-transform: rotate(135deg);
          transform: rotate(135deg);
}
.-right-top- .datepicker--pointer, .-right-center- .datepicker--pointer, .-right-bottom- .datepicker--pointer {
  right: calc(100% - -0.6875rem);
  -webkit-transform: rotate(225deg);
      -ms-transform: rotate(225deg);
          transform: rotate(225deg);
}
.-bottom-left- .datepicker--pointer, .-bottom-center- .datepicker--pointer, .-bottom-right- .datepicker--pointer {
  bottom: calc(100% - -0.6875rem);
  -webkit-transform: rotate(315deg);
      -ms-transform: rotate(315deg);
          transform: rotate(315deg);
}
.-left-top- .datepicker--pointer, .-left-center- .datepicker--pointer, .-left-bottom- .datepicker--pointer {
  left: calc(100% - -0.6875rem);
  -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
          transform: rotate(45deg);
}
.-top-left- .datepicker--pointer, .-bottom-left- .datepicker--pointer {
  left: 0.625rem;
}
.-top-right- .datepicker--pointer, .-bottom-right- .datepicker--pointer {
  right: 0.625rem;
}
.-top-center- .datepicker--pointer, .-bottom-center- .datepicker--pointer {
  left: calc(50% - 0.625rem / 2);
}
.-left-top- .datepicker--pointer, .-right-top- .datepicker--pointer {
  top: 0.625rem;
}
.-left-bottom- .datepicker--pointer, .-right-bottom- .datepicker--pointer {
  bottom: 0.625rem;
}
.-left-center- .datepicker--pointer, .-right-center- .datepicker--pointer {
  top: calc(50% - 0.625rem / 2);
}

.datepicker--body {
  display: none;
}
.datepicker--body.active {
  display: block;
}

.datepicker--cell-year.-other-decade-, .datepicker--cell-day.-other-month- {
  color: #d9d9d9;
}
.datepicker--cell-year.-other-decade-:hover, .datepicker--cell-day.-other-month-:hover {
  color: silver;
}
.-disabled-.-focus-.datepicker--cell-year.-other-decade-, .-disabled-.-focus-.datepicker--cell-day.-other-month- {
  color: #d9d9d9;
}
.-selected-.datepicker--cell-year.-other-decade-, .-selected-.datepicker--cell-day.-other-month- {
  color: #fff;
  background: #262626;
}
.-selected-.-focus-.datepicker--cell-year.-other-decade-, .-selected-.-focus-.datepicker--cell-day.-other-month- {
  background: #1a1a1a;
}
.-in-range-.datepicker--cell-year.-other-decade-, .-in-range-.datepicker--cell-day.-other-month- {
  background-color: rgba(255, 255, 255, 0.2);
  color: #c7c7c7;
}
.-in-range-.-focus-.datepicker--cell-year.-other-decade-, .-in-range-.-focus-.datepicker--cell-day.-other-month- {
  background-color: rgba(255, 255, 255, 0.2);
}
.datepicker--cell-year.-other-decade-:empty, .datepicker--cell-day.-other-month-:empty {
  background: none;
  border: none;
}

/* -------------------------------------------------
    Datepicker cells
   ------------------------------------------------- */
.datepicker--cells {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

.datepicker--cell {
  border-radius: 0.25rem;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  height: 2.188rem;
  z-index: 1;
}
.datepicker--cell.-focus- {
  background: #000000;
}
.datepicker--cell.-current- {
  color: #6b6b6b;
}
.datepicker--cell.-current-.-focus- {
  color: #000000;
}
.datepicker--cell.-current-.-in-range- {
  color: #6b6b6b;
}
.datepicker--cell.-in-range- {
  background: rgba(255, 255, 255, 0.2);
  color: #000000;
  border-radius: 0;
}
.datepicker--cell.-in-range-.-focus- {
  background-color: rgba(255, 255, 255, 0.2);
}
.datepicker--cell.-disabled- {
  cursor: default;
  color: #a4a4a4;
}
.datepicker--cell.-disabled-.-focus- {
  color: #a4a4a4;
}
.datepicker--cell.-disabled-.-in-range- {
  color: #979797;
}
.datepicker--cell.-disabled-.-current-.-focus- {
  color: #a4a4a4;
}
.datepicker--cell.-range-from- {
  border: 1px solid rgba(0, 0, 0, 0.5);
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 0.25rem 0 0 0.25rem;
}
.datepicker--cell.-range-to- {
  border: 1px solid rgba(0, 0, 0, 0.5);
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 0 0.25rem 0.25rem 0;
}
.datepicker--cell.-range-from-.-range-to- {
  border-radius: 0.25rem;
}
.datepicker--cell.-selected- {
  color: #fff;
  border: none;
  background: #000000;
}
.datepicker--cell.-selected-.-current- {
  color: #fff;
  background: #000000;
}
.datepicker--cell.-selected-.-focus- {
  background: #000000;
}
.datepicker--cell:empty {
  cursor: default;
}

.datepicker--days-names {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin: 8px 0 3px;
}

.datepicker--day-name {
  color: #000000;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  text-align: center;
  text-transform: uppercase;
  font-size: 0.8em;
}

.datepicker--cell-day {
  width: 14.2857142857%;
}
.datepicker--cells-months {
  height: 10.625rem;
}

.datepicker--cell-month {
  width: 33.33%;
  height: 25%;
}

.datepicker--years {
  height: 10.625rem;
}

.datepicker--cells-years {
  height: 10.625rem;
}

.datepicker--cell-year {
  width: 25%;
  height: 33.33%;
}
.datepicker--cell-day.-other-month-, .datepicker--cell-year.-other-decade- {
  color: #d9d9d9;
}
.datepicker--cell-day.-other-month-:hover, .datepicker--cell-year.-other-decade-:hover {
  color: silver;
}
.-disabled-.-focus-.datepicker--cell-day.-other-month-, .-disabled-.-focus-.datepicker--cell-year.-other-decade- {
  color: #d9d9d9;
}
.-selected-.datepicker--cell-day.-other-month-, .-selected-.datepicker--cell-year.-other-decade- {
  color: #fff;
  background: #262626;
}
.-selected-.-focus-.datepicker--cell-day.-other-month-, .-selected-.-focus-.datepicker--cell-year.-other-decade- {
  background: #1a1a1a;
}
.-in-range-.datepicker--cell-day.-other-month-, .-in-range-.datepicker--cell-year.-other-decade- {
  background-color: rgba(255, 255, 255, 0.2);
  color: #c7c7c7;
}
.-in-range-.-focus-.datepicker--cell-day.-other-month-, .-in-range-.-focus-.datepicker--cell-year.-other-decade- {
  background-color: rgba(255, 255, 255, 0.2);
}
.datepicker--cell-day.-other-month-:empty, .datepicker--cell-year.-other-decade-:empty {
  background: none;
  border: none;
}

/* -------------------------------------------------
    Navigation
   ------------------------------------------------- */
.datepicker--nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  border-bottom: 1px solid transparent;
  min-height: 3rem;
  padding: 0.5rem;
}
.-only-timepicker- .datepicker--nav {
  display: none;
}

.datepicker--nav-title,
.datepicker--nav-action {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  cursor: pointer;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.datepicker--nav-action {
  width: 2.188rem;
  border-radius: 0.25rem;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.datepicker--nav-action:hover {
  background: #000000;
}
.datepicker--nav-action.-disabled- {
  visibility: hidden;
}
.datepicker--nav-action svg {
  width: 32px;
  height: 32px;
}
.datepicker--nav-action path {
  fill: none;
  stroke: #000000;
  stroke-width: 2px;
}

.datepicker--nav-title {
  border-radius: 0.25rem;
  padding: 0 8px;
}
.datepicker--nav-title i {
  font-style: normal;
  color: #000000;
  margin-left: 5px;
}
.datepicker--nav-title:hover {
  background: #000000;
}
.datepicker--nav-title.-disabled- {
  cursor: default;
  background: none;
}

.datepicker--buttons {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 0.5rem;
  border-top: 1px solid transparent;
}

.datepicker--button {
  color: #6b6b6b;
  cursor: pointer;
  border-radius: 0.25rem;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: 32px;
}
.datepicker--button:hover {
  color: #000000;
  background: #000000;
}

.datepicker--cell-day.-other-month-, .datepicker--cell-year.-other-decade- {
  color: #d9d9d9;
}
.datepicker--cell-day.-other-month-:hover, .datepicker--cell-year.-other-decade-:hover {
  color: silver;
}
.-disabled-.-focus-.datepicker--cell-day.-other-month-, .-disabled-.-focus-.datepicker--cell-year.-other-decade- {
  color: #d9d9d9;
}
.-selected-.datepicker--cell-day.-other-month-, .-selected-.datepicker--cell-year.-other-decade- {
  color: #fff;
  background: #262626;
}
.-selected-.-focus-.datepicker--cell-day.-other-month-, .-selected-.-focus-.datepicker--cell-year.-other-decade- {
  background: #1a1a1a;
}
.-in-range-.datepicker--cell-day.-other-month-, .-in-range-.datepicker--cell-year.-other-decade- {
  background-color: rgba(255, 255, 255, 0.2);
  color: #c7c7c7;
}
.-in-range-.-focus-.datepicker--cell-day.-other-month-, .-in-range-.-focus-.datepicker--cell-year.-other-decade- {
  background-color: rgba(255, 255, 255, 0.2);
}
.datepicker--cell-day.-other-month-:empty, .datepicker--cell-year.-other-decade-:empty {
  background: none;
  border: none;
}

/* -------------------------------------------------
    Timepicker
   ------------------------------------------------- */
.datepicker--time {
  border-top: 1px solid transparent;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 0.5rem;
  position: relative;
}
.datepicker--time.-am-pm- .datepicker--time-sliders {
  -webkit-box-flex: 0;
      -ms-flex: 0 1 138px;
          flex: 0 1 138px;
  max-width: 138px;
}
.-only-timepicker- .datepicker--time {
  border-top: none;
}

.datepicker--time-sliders {
  -webkit-box-flex: 0;
      -ms-flex: 0 1 153px;
          flex: 0 1 153px;
  margin-right: 10px;
  max-width: 153px;
}

.datepicker--time-label {
  display: none;
  font-size: 12px;
}

.datepicker--time-current {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  font-size: 14px;
  text-align: center;
  margin: 0 0 0 10px;
}

.datepicker--time-current-colon {
  margin: 0 2px 3px;
  line-height: 1;
}

.datepicker--time-current-hours,
.datepicker--time-current-minutes {
  line-height: 1;
  position: relative;
  z-index: 1;
}
.datepicker--time-current-hours:after,
.datepicker--time-current-minutes:after {
  content: "";
  border-radius: 0.25rem;
  position: absolute;
  left: -2px;
  top: -3px;
  right: -2px;
  bottom: -2px;
  z-index: -1;
  opacity: 0;
}
.datepicker--time-current-hours.-focus-:after,
.datepicker--time-current-minutes.-focus-:after {
  opacity: 1;
}

.datepicker--time-current-ampm {
  text-transform: uppercase;
  -ms-flex-item-align: end;
      align-self: flex-end;
  color: #000000;
  margin-left: 6px;
  font-size: 11px;
  margin-bottom: 1px;
}

.datepicker--time-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 11px;
  height: 17px;
  background: -webkit-gradient(linear, left top, right top, from(#dedede), to(#dedede)) left 50%/100% 0.0625rem no-repeat;
  background: -o-linear-gradient(left, #dedede, #dedede) left 50%/100% 0.0625rem no-repeat;
  background: linear-gradient(to right, #dedede, #dedede) left 50%/100% 0.0625rem no-repeat;
}
.datepicker--time-row:first-child {
  margin-bottom: 4px;
}
.datepicker--time-row input[type=range] {
  background: none;
  cursor: pointer;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  height: 100%;
  padding: 0;
  margin: 0;
  -webkit-appearance: none;
}
.datepicker--time-row input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
}
.datepicker--time-row input[type=range]::-ms-tooltip {
  display: none;
}
.datepicker--time-row input[type=range]:hover::-webkit-slider-thumb {
  border-color: #b8b8b8;
}
.datepicker--time-row input[type=range]:hover::-moz-range-thumb {
  border-color: #b8b8b8;
}
.datepicker--time-row input[type=range]:hover::-ms-thumb {
  border-color: #b8b8b8;
}
.datepicker--time-row input[type=range]:focus {
  outline: none;
}
.datepicker--time-row input[type=range]:focus::-webkit-slider-thumb {
  background: #000000;
  border-color: #000000;
}
.datepicker--time-row input[type=range]:focus::-moz-range-thumb {
  background: #000000;
  border-color: #000000;
}
.datepicker--time-row input[type=range]:focus::-ms-thumb {
  background: #000000;
  border-color: #000000;
}
.datepicker--time-row input[type=range]::-webkit-slider-thumb {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  height: var(--unity-global-size-24);
  width: var(--unity-global-size-24);
  border-radius: 50%;
  border: var(--unity-global-border-width-1) solid #dedede;
  background: #fff;
  cursor: pointer;
  -webkit-transition: background 0.2s;
  transition: background 0.2s;
}
.datepicker--time-row input[type=range]::-moz-range-thumb {
  box-sizing: border-box;
  height: var(--unity-global-size-24);
  width: var(--unity-global-size-24);
  border-radius: 50%;
  border: var(--unity-global-border-width-1) solid #dedede;
  background: #fff;
  cursor: pointer;
  -moz-transition: background 0.2s;
  transition: background 0.2s;
}
.datepicker--time-row input[type=range]::-ms-thumb {
  box-sizing: border-box;
  height: var(--unity-global-size-24);
  width: var(--unity-global-size-24);
  border-radius: 50%;
  border: var(--unity-global-border-width-1) solid #dedede;
  background: #fff;
  cursor: pointer;
  -ms-transition: background 0.2s;
  transition: background 0.2s;
}
.datepicker--time-row input[type=range]::-webkit-slider-thumb {
  margin-top: var(--unity-global-size-24)/-2;
}
.datepicker--time-row input[type=range]::-webkit-slider-runnable-track {
  border: none;
  height: 0.0625rem;
  cursor: pointer;
  color: transparent;
  background: transparent;
}
.datepicker--time-row input[type=range]::-moz-range-track {
  border: none;
  height: 0.0625rem;
  cursor: pointer;
  color: transparent;
  background: transparent;
}
.datepicker--time-row input[type=range]::-ms-track {
  border: none;
  height: 0.0625rem;
  cursor: pointer;
  color: transparent;
  background: transparent;
}
.datepicker--time-row input[type=range]::-ms-fill-lower {
  background: transparent;
}
.datepicker--time-row input[type=range]::-ms-fill-upper {
  background: transparent;
}
.datepicker--time-row span {
  padding: 0 12px;
}

.datepicker--time-icon {
  color: #000000;
  border: 1px solid;
  border-radius: 50%;
  font-size: 16px;
  position: relative;
  margin: 0 5px -1px 0;
  width: 1em;
  height: 1em;
}
.datepicker--time-icon:after, .datepicker--time-icon:before {
  content: "";
  background: currentColor;
  position: absolute;
}
.datepicker--time-icon:after {
  height: 0.4em;
  width: 1px;
  left: calc(50% - 1px);
  top: calc(50% + 1px);
  -webkit-transform: translateY(-100%);
      -ms-transform: translateY(-100%);
          transform: translateY(-100%);
}
.datepicker--time-icon:before {
  width: 0.4em;
  height: 1px;
  top: calc(50% + 1px);
  left: calc(50% - 1px);
}

.datepicker--nav-title {
  color: var(--unity-global-color-solid-black);
  font-size: var(--unity-global-size-16);
  font-weight: var(--unity-global-font-weights-medium);
}
.datepicker--nav-title:hover {
  background-color: transparent;
}

.datepicker--nav-action {
  background-color: var(--unity-global-color-solid-black);
  color: var(--unity-global-color-solid-white);
  border-radius: 50%;
  width: var(--unity-global-size-32);
  height: var(--unity-global-size-32);
}
.datepicker--nav-action:hover {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 160, 222, 0.1);
          box-shadow: inset 0 0 6px rgba(0, 160, 222, 0.1);
}
.datepicker--nav-action path {
  stroke: var(--unity-global-color-solid-white);
  stroke-width: 1px;
}

.datapicker--day-name {
  margin: 8px 0;
}

.datepicker--cell.-focus-, .datepicker--cell.-selected- {
  border-radius: 1.094rem;
}
.datepicker--cell.-focus-:not(.-selected-), .datepicker--cell:hover {
  color: var(--unity-global-color-solid-white);
}
.datepicker--cell.-month.-focus- {
  background-color: transparent;
}
.datepicker--cell.-focus- {
  border: transparent;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 160, 222, 0.1);
          box-shadow: inset 0 0 6px rgba(0, 160, 222, 0.1);
  color: var(--unity-global-color-solid-white);
}
.datepicker--cell.-month.-focus-, .datepicker--cell.-selected-.-focus- {
  border: var(--unity-global-border-width-1) solid var(--unity-global-color-solid-white);
}
.datepicker--cell.-current-:hover {
  color: var(--unity-global-color-solid-white);
}
