/* design systems complimentary colors / backgrounds */
$magenta: #e20074;
$disabled: #a4a4a4;
$muted: #757575;
$white: #fff;

$green: #21b277;
$green-lighter: #e9f8f1;
$red-light: #fae6e6;

.bg-red-light {
  background-color: $red-light !important;
}

.bg-green-light {
  background-color: $green-lighter !important;
}
