

.svg-font {
  display: inline-block;
  svg {
    width: 1em;
    height: 1em;
  }
}

svg-icon {
  svg {
    width: 1em;
    height: 1em;
  }
}

.icon-warning-circle-solid,
.icon-error-solid,
.icon-clock {
  padding-top: 1px;
}

.default-icon-font-size {
  font-size: 1.35em;
}

@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes scale {
  0%,
  100% {
    transform: none;
  }
  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}

@keyframes fill {
  100% {
    box-shadow: inset 0px 0px 0px 30px #333;
  }
}
