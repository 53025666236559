@import 'src/assets/stylesheets/partials/_colors.scss';

body {
  background-color: #fff;
}

.container {
  width: 100%;
}

.bold {
  font-weight: bold;
}

.ellipse {
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}

a {
  cursor: pointer;

  .icon-arrow-right::before {
    display: inline-block;
    text-decoration: none;
  }
}

.divider {
  margin: 15px 0;
  height: 1px;
  background-color: #dddddd;
}

.app-loader {
  object {
    width: 150px;
    position: absolute;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

/* This applies a style to all root components and enforces it to take 100% of the screen size */
app-root > main > router-outlet + *:not(app-breadcrumb),
.section.section-square-pattern {
  display: flex;
  flex-direction: column;
  flex: 1 1 100%;
}

.download-icon {
  cursor: pointer;

  &.disabled {
    cursor: initial;
    color: #a4a4a4;
  }
}

/* Loaders */
.donut-loading-animation-overlay {
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.6);
    z-index: 1;
  }
}
.donut-loading-animation-background-gray {
  background-color: #ededed;
}

.donut-loading-animation-background-gray > li {
  opacity: 0.6;

  a::before,
  a {
    pointer-events: none;
  }
}

.donut-loading-animation-background-gray,
.donut-loading-animation {
  &::before {
    visibility: hidden;
  }
}

.donut-loading-animation-background-gray,
.donut-loading-animation-overlay,
.donut-loading-animation {
  pointer-events: none;

  @keyframes donut-loader-animation {
    0% {
      -webkit-transform: rotate(0);
      transform: rotate(0);
    }

    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

  $loaderSize: 24px;

  &::after {
    content: '';
    height: $loaderSize;
    width: $loaderSize;
    display: inline-block;
    border: 2px solid rgba(0, 0, 64, 0.4);
    border-radius: 50%;
    border-right-color: #000000;
    -webkit-animation: donut-loader-animation 1s linear infinite;
    animation: donut-loader-animation 1s linear infinite;
    top: calc(50% - (#{$loaderSize} / 2));
    left: calc(50% - (#{$loaderSize} / 2));
    position: absolute;
    z-index: 2;
  }
}

.donut-loading-animation-large {
  $loaderSizeLarge: 48px;

  &::after {
    height: $loaderSizeLarge;
    width: $loaderSizeLarge;
    top: calc(50% - (#{$loaderSizeLarge} / 2));
    left: calc(50% - (#{$loaderSizeLarge} / 2));
  }
}

.padded {
  padding: 1.25rem;

  @media (max-width: 575.98px) {
    padding: 0.75rem;
  }

  &.selected {
    border: 2px solid #000; //changed from magenta
  }
}

/* Positioning */
.relative {
  position: relative;
}

.centered-in-parent {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.align-self-flex-start {
  align-self: flex-start;
}

/* Text decoration */
.underline {
  text-decoration: underline;
}

.strikethrough {
  text-decoration: line-through;
}

/* links */
.link-call-to-action.stretched::after {
  position: absolute;
  right: 0;
  line-height: 28px;
  bottom: auto;
}

:checked + .form-switch {
  background: $green !important;
}

.form-switch-input:focus:not(:checked) ~ .form-switch {
  box-shadow: 0 0 0 4px rgba(0, 160, 222, 0.1), inset 0 0 0 1px $green !important;
}

/* Borders */
.border-left-blue {
  border-left: 1px solid #1063ad !important;
}

.border-left-muted {
  border-left: 1px solid $muted !important;
}

.border-color-muted {
  border-color: #a4a4a4 !important;
}

/* Pricing */
.min-width-price-tablet-up {
  @media (min-width: 768px) {
    min-width: 75px;
    text-align: right;
  }
}

/* Link list item additions */
.link-list-item > div,
.link-list-item > div .text-muted {
  -webkit-transition: color 0.3s;
  -o-transition: color 0.3s;
  transition: color 0.3s;
  color: #262626;
}

.link-list-item > div::after {
  font-family: 'live-forward-icons';
  content: var(--icon-arrow-right);
  position: absolute;
  right: 0;
  font-size: 24px;
  right: 16px;
}

/* Move icon*/
.move-icon {
  width: 22.5px;
}
