@import 'src/assets/stylesheets/partials/_colors.scss';

// A section for disabled cards
app-card.disabled {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: $disabled !important;
  }
}

.card[disabled],
app-card.disabled {
  input,
  input::placeholder {
    color: $disabled;
  }

  .btn,
  .btn:hover {
    background-color: $disabled;
  }

  .form-switch {
    opacity: 0.5;
  }

  .callout-info {
    color: $disabled;
  }
}

.card-item,
.card-body {
  padding: 1.25rem;

  @media (max-width: 575.98px) {
    padding: 0.75rem;
  }

  &:nth-child(2) {
    cursor: default;
  }
}

.highlight-down-centered {
  transition: all 0.2s ease-in-out;
  left: 50%;
  margin-top: 8px;
  position: absolute;

  &::before {
    content: ' ';
    border: solid transparent;
    pointer-events: none;

    border-top-color: white;
    border-width: 35px;
    margin-left: -35px;
  }
}

.card-flex > div {
  display: flex;
  flex-direction: column;
  flex: 1;

  > div:first-child {
    flex: 1;
  }
}
