/* You can add global styles to this file, and also import other style files */

@import 'partials/colors';
@import 'partials/scaffolding';
@import 'partials/forms';
@import 'partials/cards';
@import 'partials/tables';

@import 'partials/icons';
@import 'partials/responsive';
@import 'partials/maintenance';
@import 'partials/display';
@import 'partials/tiles';
