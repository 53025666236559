/*!******************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************!*\
  !*** css ./node_modules/.pnpm/css-loader@6.7.3_webpack@5.75.0/node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[2].use[1]!./node_modules/.pnpm/postcss-loader@7.0.2_postcss@8.4.25_webpack@5.75.0/node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[2].use[2]!./node_modules/.pnpm/sass-loader@13.2.0_sass@1.58.3_webpack@5.75.0/node_modules/sass-loader/dist/cjs.js??ruleSet[1].rules[2].use[3]!./src/brands/live-forward/design-system-beta.scss ***!
  \******************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************/
@charset "UTF-8";
/** meta helper to inline css through dart css */
/** This import ensures the @unity/tokens design system variables are available through the sass files */
/**
 * Do not edit directly
 * Generated on Mon, 19 Jun 2023 12:04:36 GMT
 */
:root {
  --unity-global-opacity-80: 0.8; /* TBD */
  --unity-global-opacity-60: 0.6; /* TBD */
  --unity-global-opacity-40: 0.4; /* TBD */
  --unity-global-opacity-20: 0.2; /* TBD */
  --unity-global-opacity-10: 0.1; /* TBD */
  --unity-global-base: 16;
  --unity-global-text-decoration-underline: underline;
  --unity-global-text-decoration-line-through: line-through;
  --unity-global-text-decoration-none: none;
  --unity-global-text-case-capitalize: capitalize;
  --unity-global-text-case-uppercase: uppercase;
  --unity-global-text-case-lowercase: lowercase;
  --unity-global-text-case-none: none;
  --unity-global-letter-spacing-expanded: 0.009375rem;
  --unity-global-letter-spacing-semi-expanded: 0.028125rem;
  --unity-global-letter-spacing-extra-expanded: 0.0375rem;
  --unity-global-letter-spacing-ultra-expanded: 0.01875rem;
  --unity-global-letter-spacing-slightly-thight: -0.009375rem;
  --unity-global-letter-spacing-ultra-condensed: -0.01875rem;
  --unity-global-letter-spacing-extra-condensed: -0.028125rem;
  --unity-global-letter-spacing-condensed: -0.0375rem;
  --unity-global-letter-spacing-semi-condensed: -0.05625rem;
  --unity-global-letter-spacing-extra-semi-tight: -0.1125rem;
  --unity-global-letter-spacing-semi-tighter: -0.09375rem;
  --unity-global-letter-spacing-semi-tight: -0.075rem;
  --unity-global-letter-spacing-extreme-thight: -0.253125rem;
  --unity-global-letter-spacing-tightest: -0.1875rem;
  --unity-global-letter-spacing-extra-tight: -0.13125rem;
  --unity-global-letter-spacing-extra-tighter: -0.140625rem;
  --unity-global-letter-spacing-tight: -0.084375rem;
  --unity-global-letter-spacing-normal: 0;
  --unity-global-paragraph-spacing-0: 0;
  --unity-global-font-size-200: 12.5rem;
  --unity-global-font-size-144: 9rem;
  --unity-global-font-size-104: 6.5rem;
  --unity-global-font-size-96: 6rem;
  --unity-global-font-size-72: 4.5rem;
  --unity-global-font-size-56: 3.5rem;
  --unity-global-font-size-48: 3rem;
  --unity-global-font-size-40: 2.5rem;
  --unity-global-font-size-36: 2.25rem;
  --unity-global-font-size-32: 2rem;
  --unity-global-font-size-24: 1.5rem;
  --unity-global-font-size-20: 1.25rem;
  --unity-global-font-size-18: 1.125rem;
  --unity-global-font-size-16: 1rem;
  --unity-global-font-size-14: 0.875rem;
  --unity-global-font-size-13: 0.8125rem;
  --unity-global-font-size-12: 0.75rem;
  --unity-global-font-size-11: 0.6875rem;
  --unity-global-font-weights-black: 900;
  --unity-global-font-weights-bold: 700;
  --unity-global-font-weights-light: 300;
  --unity-global-font-weights-regular-italic: Regular Italic;
  --unity-global-font-weights-medium-italic: Medium Italic;
  --unity-global-font-weights-regular: 400;
  --unity-global-font-weights-medium: 500;
  --unity-global-line-heights-178: 11.125rem;
  --unity-global-line-heights-132: 8.25rem;
  --unity-global-line-heights-106: 6.625rem;
  --unity-global-line-heights-98: 6.125rem;
  --unity-global-line-heights-74: 4.625rem;
  --unity-global-line-heights-64: 4rem;
  --unity-global-line-heights-52: 3.25rem;
  --unity-global-line-heights-44: 2.75rem;
  --unity-global-line-heights-40: 2.5rem;
  --unity-global-line-heights-36: 2.25rem;
  --unity-global-line-heights-32: 2rem;
  --unity-global-line-heights-28: 1.75rem;
  --unity-global-line-heights-26: 1.625rem;
  --unity-global-line-heights-24: 1.5rem;
  --unity-global-line-heights-22: 1.375rem;
  --unity-global-line-heights-19: 1.1875rem;
  --unity-global-line-heights-18: 1.125rem;
  --unity-global-line-heights-17: 1.0625rem;
  --unity-global-font-families-otypical-text: Otypical Text;
  --unity-global-font-families-otypical-headline: Otypical Headline;
  --unity-global-border-width-8: 0.5rem; /* TBD */
  --unity-global-border-width-4: 0.25rem; /* TBD */
  --unity-global-border-width-2: 0.125rem; /* TBD */
  --unity-global-border-width-1: 0.0625rem; /* TBD */
  --unity-global-border-radius-64: 4rem; /* TBD */
  --unity-global-border-radius-48: 3rem; /* TBD */
  --unity-global-border-radius-36: 2.25rem; /* TBD */
  --unity-global-border-radius-32: 2rem; /* TBD */
  --unity-global-border-radius-24: 1.5rem; /* TBD */
  --unity-global-border-radius-16: 1rem; /* TBD */
  --unity-global-border-radius-12: 0.75rem; /* TBD */
  --unity-global-border-radius-8: 0.5rem; /* TBD */
  --unity-global-border-radius-4: 0.25rem; /* TBD */
  --unity-global-border-radius-0: 0; /* TBD */
  --unity-global-rem: 16; /* TBD */
  --unity-global-size-128: 8rem; /* TBD */
  --unity-global-size-80: 5rem; /* TBD */
  --unity-global-size-64: 4rem; /* TBD */
  --unity-global-size-48: 3rem; /* TBD */
  --unity-global-size-40: 2.5rem; /* TBD */
  --unity-global-size-36: 2.25rem; /* TBD */
  --unity-global-size-32: 2rem; /* TBD */
  --unity-global-size-24: 1.5rem; /* TBD */
  --unity-global-size-16: 1rem; /* TBD */
  --unity-global-size-8: 0.5rem; /* TBD */
  --unity-global-size-4: 0.25rem; /* TBD */
  --unity-global-size-0: 0; /* TBD */
  --unity-global-space-128: 8rem; /* TBD */
  --unity-global-space-96: 6rem; /* TBD */
  --unity-global-space-64: 4rem; /* TBD */
  --unity-global-space-48: 3rem; /* TBD */
  --unity-global-space-32: 2rem; /* TBD */
  --unity-global-space-24: 1.5rem; /* TBD */
  --unity-global-space-20: 1.25rem; /* TBD */
  --unity-global-space-16: 1rem; /* TBD */
  --unity-global-space-12: 0.75rem;
  --unity-global-space-8: 0.5rem; /* TBD */
  --unity-global-space-6: 0.375rem; /* TBD */
  --unity-global-space-4: 0.25rem; /* TBD */
  --unity-global-space-2: 0.125rem; /* TBD */
  --unity-global-space-0: 0; /* TBD */
  --unity-global-color-gradient-full-angular-4: conic-gradient(from 90deg at 50% 50%, #2C72FF 0deg, #FF7820 76.88deg, #A9A3FF 181.87deg, #99A2FF 262.5deg, #2C72FF 360deg);
  --unity-global-color-gradient-full-angular-3: conic-gradient(from 90deg at 50% 50%, #4683FF -13.12deg, #82C2BE 28.12deg, #FF914D 136.88deg, #FF914D 172.5deg, #6D9EFF 251.25deg, #2C72FF 307.5deg, #4683FF 346.88deg, #82C2BE 388.13deg);
  --unity-global-color-gradient-full-angular-2: conic-gradient(from 0deg at 50% 50%, #F7BF64 -26.25deg, #FFAC24 44.5deg, #8D85FF 120deg, #568EFF 221.25deg, #F7BF64 333.75deg, #FFAC24 404.5deg);
  --unity-global-color-gradient-full-angular-1: conic-gradient(from 90deg at 50% 50%, #FF99A3 0deg, #2F9A92 56.25deg, #FF914F 223.13deg, #FF99A3 360deg);
  --unity-global-color-gradient-full-linear-4: linear-gradient(225deg, #ff7621 0%, #a9a3ff 59.9%, #6d9eff 100%);
  --unity-global-color-gradient-full-linear-3: linear-gradient(225deg, #82c2be 0%, #568eff 41.15%, #f1bd9d 100%);
  --unity-global-color-gradient-full-linear-2: linear-gradient(225deg, #ffac24 7.81%, #7066ff 59.9%, #80aaff 100%, #2c72ff 100%);
  --unity-global-color-gradient-full-linear-1: linear-gradient(225deg, #2f9a92 0%, #ff7621 46.35%, #ff9773 67.19%, #ffa1aa 89.58%, #ff808c 98.44%);
  --unity-global-color-gradient-reduced-angular-4: conic-gradient(from 111.25deg at 50% 50%, #FFAD7A 0deg, #80AAFF 172.5deg, #A1A4FF 322.5deg, #FEAD7B 360deg);
  --unity-global-color-gradient-reduced-angular-3: conic-gradient(from 130.82deg at 50% 50%, #80AAFF 0deg, #80AAFF 110.62deg, #81B8D9 196.87deg, #82C2BE 268.13deg, #80AAFF 360deg);
  --unity-global-color-gradient-reduced-angular-2: conic-gradient(from 0deg at 50% 50%, #FFD798 -26.25deg, #FFD185 44.5deg, #B1ABFF 120deg, #ADC8FF 221.25deg, #ABC7FF 305.62deg, #FFD798 333.75deg, #FFD185 404.5deg);
  --unity-global-color-gradient-reduced-angular-1: conic-gradient(from 90deg at 50% 50%, #FF9BA5 0deg, #59C2BB 121.87deg, #FFA974 234.38deg, #FF9BA5 360deg);
  --unity-global-color-gradient-reduced-linear-4: linear-gradient(225deg, #ffad7a 0%, #a9a3ff 59.9%, #80aaff 100%);
  --unity-global-color-gradient-reduced-linear-3: linear-gradient(225deg, #82c2be 0%, #80aaff 41.15%, #a9a3ff 100%);
  --unity-global-color-gradient-reduced-linear-2: linear-gradient(225deg, #ffcd7c 7.81%, #a9a3ff 59.9%, #80aaff 100%, #80aaff 100%);
  --unity-global-color-gradient-reduced-linear-1: linear-gradient(222deg, #82c2be 0%, #ffb3ba 42.71%, #ffad7a 100%);
  --unity-global-color-transparency-black-80: #000000cc; /* Modals, overlays, lightbox */
  --unity-global-color-transparency-black-40: #00000066; /* Multiple design components such as popover component */
  --unity-global-color-transparency-black-20: #00000033; /* Subnav darkmode background */
  --unity-global-color-transparency-black-10: #0000001a; /* TBD */
  --unity-global-color-transparency-white-10: #ffffff1a; /* TBD */
  --unity-global-color-solid-white: #fff; /* All fontsizes. */
  --unity-global-color-solid-black: #000000; /* All fontsizes. */
  --unity-global-color-solid-purple: #7066ff; /* Min. size of 18,66 px bold or 24 px for text (on) white. */
  --unity-global-color-solid-pink: #ff808c; /* All fontsizes. */
  --unity-global-color-solid-red: #EB0E00; /* Min. size of 18,66 px bold or 24 px for text (on) black. */
  --unity-global-color-solid-orange: #ff7621; /* All fontsizes. */
  --unity-global-color-solid-yellow: #ffac24; /* All fontsizes. */
  --unity-global-color-solid-green: #2f9a92; /* Min. size of 18,66 px bold or 24 px for text (on) white. */
  --unity-global-color-solid-blue: #2c72ff; /* Min. size of 18,66 px bold or 24 px for text (on) white and black. */
  --unity-global-color-scale-red-900: #EB0E00; /* Min. size of 18,66 px bold or 24 px for text (on) black. */
  --unity-global-color-scale-red-700: #FE5F55; /* Min. size of 18,66 px bold or 24 px for text (on) white. */
  --unity-global-color-scale-red-500: #FE7F77; /* All fontsizes. */
  --unity-global-color-scale-red-300: #FE9F99; /* All fontsizes. */
  --unity-global-color-scale-red-100: #FFBFBB; /* All fontsizes. */
  --unity-global-color-scale-red-50: #FFE7E5; /* All fontsizes. */
  --unity-global-color-scale-purple-900: #5347FF; /* Min. size of 18,66 px bold or 24 px for text (on) black. */
  --unity-global-color-scale-purple-700: #7066ff; /* Min. size of 18,66 px bold or 24 px for text (on) white. */
  --unity-global-color-scale-purple-500: #8D85FF; /* All fontsizes. */
  --unity-global-color-scale-purple-300: #A9A3FF; /* All fontsizes. */
  --unity-global-color-scale-purple-100: #C6C2FF; /* All fontsizes. */
  --unity-global-color-scale-purple-50: #E8E7FF; /* All fontsizes. */
  --unity-global-color-scale-pink-900: #F95262; /* Min. size of 18,66 px bold or 24 px for text (on) white. */
  --unity-global-color-scale-pink-700: #ff808c; /* All fontsizes. */
  --unity-global-color-scale-pink-500: #FF99A3; /* All fontsizes. */
  --unity-global-color-scale-pink-300: #FFB3BA; /* All fontsizes. */
  --unity-global-color-scale-pink-100: #FFCCD1; /* All fontsizes. */
  --unity-global-color-scale-pink-50: #FFF5F6; /* All fontsizes. */
  --unity-global-color-scale-orange-900: #F05C00; /* Min. size of 18,66 px bold or 24 px for text (on) white. */
  --unity-global-color-scale-orange-700: #ff7621; /* All fontsizes. */
  --unity-global-color-scale-orange-500: #FF914D; /* All fontsizes. */
  --unity-global-color-scale-orange-300: #FFAD7A; /* All fontsizes. */
  --unity-global-color-scale-orange-100: #FFC8A6; /* All fontsizes. */
  --unity-global-color-scale-orange-50: #FFF1E9; /* All fontsizes. */
  --unity-global-color-scale-yellow-900: #FFA005; /* All fontsizes. */
  --unity-global-color-scale-yellow-700: #ffac24; /* All fontsizes. */
  --unity-global-color-scale-yellow-500: #FFBD50; /* All fontsizes. */
  --unity-global-color-scale-yellow-300: #FFCD7C; /* All fontsizes. */
  --unity-global-color-scale-yellow-100: #FFDEA7; /* All fontsizes. */
  --unity-global-color-scale-yellow-50: #FFF7E9; /* All fontsizes. */
  --unity-global-color-scale-green-900: #207D76; /* Min. size of 18,66 px bold or 24 px for text (on) black. */
  --unity-global-color-scale-green-700: #2f9a92; /* Min. size of 18,66 px bold or 24 px for text (on) white. */
  --unity-global-color-scale-green-500: #59AEA8; /* All fontsizes. */
  --unity-global-color-scale-green-300: #82C2BE; /* All fontsizes. */
  --unity-global-color-scale-green-100: #ACD7D3; /* All fontsizes. */
  --unity-global-color-scale-green-50: #EEF7F6; /* All fontsizes. */
  --unity-global-color-scale-blue-900: #1B5EE4; /* All fontsizes. */
  --unity-global-color-scale-blue-700: #2c72ff; /* Min. size of 18,66 px bold or 24 px for text (on) white and black. */
  --unity-global-color-scale-blue-500: #568EFF; /* Min. size of 18,66 px bold or 24 px for text (on) white. */
  --unity-global-color-scale-blue-300: #80AAFF; /* All fontsizes. */
  --unity-global-color-scale-blue-100: #ABC7FF; /* All fontsizes. */
  --unity-global-color-scale-blue-50: #ECF2FF; /* All fontsizes. */
  --unity-global-color-scale-gray-900: #222222; /* All fontsizes. */
  --unity-global-color-scale-gray-700: #444444; /* All fontsizes. */
  --unity-global-color-scale-gray-500: #666666; /* Min. size of 18,66 px bold or 24 px for text (on) white. */
  --unity-global-color-scale-gray-300: #999999; /* All fontsizes. */
  --unity-global-color-scale-gray-100: #DDDDDD; /* All fontsizes. */
  --unity-global-color-scale-gray-50: #EBEBEB; /* All fontsizes. */
  --unity-alias-app-badge-typography: 500 0.6875rem / 1.0625rem Otypical Headline;
  --unity-alias-form-active-outline-focus: inset 0 0 0 2px #1B5EE4, 0 0 0 4px #1b5ee41a;
  --unity-alias-components-outline-focus: inset 0 0 0 2px #1B5EE4, 0 0 0 2px #ffffff, 0 0 0 6px #1b5ee41a;
  --unity-alias-opacity-disabled: 0.2; /* Used for disabled UI elements. */
  --unity-alias-text-link-typography-lg: 500 1.25rem / 2rem Otypical Headline;
  --unity-alias-text-link-typography-md: 500 1rem / 1.5rem Otypical Headline;
  --unity-alias-text-link-typography-sm: 500 0.875rem / 1.375rem Otypical Headline;
  --unity-alias-quote-strong: Medium Italic 1.25rem / 2rem Otypical Headline;
  --unity-alias-quote-normal: Regular Italic 1.25rem / 2rem Otypical Headline;
  --unity-alias-charts-value-mobile: 500 1.25rem / 1.75rem Otypical Headline;
  --unity-alias-charts-value-desktop: 500 2.25rem / 2.5rem Otypical Headline;
  --unity-alias-list-strong: 400 1rem / 1.5rem Otypical Headline;
  --unity-alias-label-strong: 400 0.875rem / 1.375rem Otypical Headline;
  --unity-alias-price-euro-md: 500 1rem / 1.0625rem Otypical Headline;
  --unity-alias-price-euro-sm: 500 1rem / 1.0625rem Otypical Headline;
  --unity-alias-price-digits-md: 500 1rem / 1.0625rem Otypical Headline;
  --unity-alias-price-digits-sm: 500 0.875rem / 1.0625rem Otypical Headline;
  --unity-alias-price-main-md: 500 2rem / 1.5rem Otypical Headline;
  --unity-alias-price-main-sm: 500 1.5rem / 1.0625rem Otypical Headline;
  --unity-alias-lead-strong: 500 1.25rem / 2rem Otypical Headline;
  --unity-alias-lead-normal: 400 1.25rem / 2rem Otypical Headline;
  --unity-alias-footnote-medium-line-trough-xs: 500 0.6875rem / 1.0625rem Otypical Headline;
  --unity-alias-footnote-medium-line-trough-sm: 500 0.75rem / 1.125rem Otypical Headline;
  --unity-alias-footnote-medium-line-trough-md: 500 0.8125rem / 1.1875rem Otypical Headline;
  --unity-alias-footnote-medium-xs: 500 0.6875rem / 1.0625rem Otypical Text;
  --unity-alias-footnote-medium-sm: 500 0.75rem / 1.125rem Otypical Text;
  --unity-alias-footnote-medium-md: 500 0.8125rem / 1.1875rem Otypical Text;
  --unity-alias-footnote-regular-line-trough-xs: 400 0.6875rem / 1.0625rem Otypical Text;
  --unity-alias-footnote-regular-line-trough-sm: 400 0.75rem / 1.125rem Otypical Text;
  --unity-alias-footnote-regular-line-trough-md: 400 0.8125rem / 1.1875rem Otypical Text;
  --unity-alias-footnote-regular-xs: 400 0.6875rem / 1.0625rem Otypical Text;
  --unity-alias-footnote-regular-sm: 400 0.75rem / 1.125rem Otypical Text;
  --unity-alias-footnote-regular-md: 400 0.8125rem / 1.1875rem Otypical Text;
  --unity-alias-body-medium-underline-md: 500 1rem / 1.5rem Otypical Text;
  --unity-alias-body-medium-line-through-sm: 500 0.875rem / 1.375rem Otypical Text;
  --unity-alias-body-medium-line-through-md: 500 1rem / 1.5rem Otypical Text;
  --unity-alias-body-medium-line-through-lg: 500 1.125rem / 1.625rem Otypical Text;
  --unity-alias-body-medium-line-through-xl: 500 1.25rem / 1.75rem Otypical Text;
  --unity-alias-body-medium-sm: 500 0.875rem / 1.375rem Otypical Text;
  --unity-alias-body-medium-md: 500 1rem / 1.5rem Otypical Text;
  --unity-alias-body-medium-lg: 500 1.125rem / 1.625rem Otypical Text;
  --unity-alias-body-medium-xl: 500 1.25rem / 1.75rem Otypical Text;
  --unity-alias-body-regular-line-through-sm: 400 0.875rem / 1.375rem Otypical Text;
  --unity-alias-body-regular-line-through-md: 400 1rem / 1.5rem Otypical Text;
  --unity-alias-body-regular-line-through-lg: 400 1.125rem / 1.625rem Otypical Text;
  --unity-alias-body-regular-line-through-xl: 400 1.25rem / 1.75rem Otypical Text;
  --unity-alias-body-regular-sm: 400 0.875rem / 1.375rem Otypical Text;
  --unity-alias-body-regular-md: 400 1rem / 1.5rem Otypical Text;
  --unity-alias-body-regular-lg: 400 1.125rem / 1.625rem Otypical Text;
  --unity-alias-body-regular-xl: 400 1.25rem / 1.75rem Otypical Text;
  --unity-alias-mobile-heading-sx: 500 1.125rem / 1.625rem Otypical Headline;
  --unity-alias-mobile-heading-sm: 500 1.25rem / 1.75rem Otypical Headline;
  --unity-alias-mobile-heading-md: 500 1.5rem / 2rem Otypical Headline;
  --unity-alias-mobile-heading-lg: 500 2rem / 2.25rem Otypical Headline;
  --unity-alias-mobile-heading-xl: 500 2.25rem / 2.5rem Otypical Headline;
  --unity-alias-mobile-display-xs: 500 2.5rem / 2.75rem Otypical Headline;
  --unity-alias-mobile-display-sm: 500 3rem / 3.25rem Otypical Headline;
  --unity-alias-mobile-display-md: 500 3.5rem / 4rem Otypical Headline;
  --unity-alias-mobile-display-lg: 500 4.5rem / 4.625rem Otypical Headline;
  --unity-alias-mobile-display-xl: 500 6.5rem / 6.625rem Otypical Headline;
  --unity-alias-desktop-heading-xs: 500 1.125rem / 1.625rem Otypical Headline;
  --unity-alias-desktop-heading-sm: 500 1.25rem / 1.75rem Otypical Headline;
  --unity-alias-desktop-heading-md: 500 1.5rem / 2rem Otypical Headline;
  --unity-alias-desktop-heading-lg: 500 2.25rem / 2.5rem Otypical Headline;
  --unity-alias-desktop-heading-xl: 500 3rem / 3.25rem Otypical Headline;
  --unity-alias-desktop-display-xs: 500 3.5rem / 4rem Otypical Headline;
  --unity-alias-desktop-display-sm: 500 4.5rem / 4.625rem Otypical Headline;
  --unity-alias-desktop-display-md: 500 6rem / 6.125rem Otypical Headline;
  --unity-alias-desktop-display-lg: 500 9rem / 8.25rem Otypical Headline;
  --unity-alias-desktop-display-xl: 500 12.5rem / 11.125rem Otypical Headline;
  --unity-alias-typography-status: 500 1.125rem / 1.5rem Otypical Headline;
  --unity-alias-typography-numbers-3-xl: 500 3rem / 3.25rem Otypical Headline; /* TBD */
  --unity-alias-typography-numbers-2-xl: 500 2.25rem / 2.5rem Otypical Headline; /* TBD */
  --unity-alias-typography-numbers-xl: 500 2rem / 2.25rem Otypical Headline; /* TBD */
  --unity-alias-typography-numbers-lg: 500 1.25rem / 2rem Otypical Headline; /* TBD */
  --unity-alias-typography-numbers-md: 500 1.125rem / 1.5rem Otypical Headline; /* TBD */
  --unity-alias-typography-numbers-sm: 500 1rem / 1.5rem Otypical Headline; /* TBD */
  --unity-alias-typography-navigation: 500 1.25rem / 1.5rem Otypical Headline;
  --unity-alias-typography-title-message: 500 1.25rem / 1.5rem Otypical Headline;
  --unity-alias-button-typography-lg: 500 1.25rem / 2rem Otypical Text;
  --unity-alias-button-typography-md: 500 1rem / 1.5rem Otypical Text;
  --unity-alias-button-typography-sm: 500 0.875rem / 1.375rem Otypical Text;
  --unity-alias-size-item-xl: 5.5rem; /* TBD */
  --unity-alias-size-item-lg: 3.75rem; /* TBD */
  --unity-alias-size-item-md: 2.5rem; /* TBD */
  --unity-alias-size-item-sm: 2rem; /* TBD */
  --unity-alias-size-item-xs: 1.5rem; /* TBD */
  --unity-alias-size-cta-height-lg: 4rem; /* TBD */
  --unity-alias-size-cta-height-md: 3rem; /* TBD */
  --unity-alias-size-cta-height-sm: 2rem; /* TBD */
  --unity-alias-navigation-meta-mobile-selected: 500 1rem / 1.5rem Otypical Headline;
  --unity-alias-navigation-meta-mobile-default: 400 1rem / 1.5rem Otypical Headline;
  --unity-alias-navigation-meta-desktop-selected: 500 0.875rem / 1.0625rem Otypical Headline;
  --unity-alias-navigation-meta-desktop-default: 400 0.875rem / 1.0625rem Otypical Headline;
  --unity-alias-navigation-main-secondary: 400 1rem / 1.5rem Otypical Headline;
  --unity-alias-navigation-main-primary: 500 1rem / 1.5rem Otypical Headline;
  --unity-alias-border-radius-lg: 1rem; /* TBD */
  --unity-alias-border-radius-md: 0.75rem; /* TBD */
  --unity-alias-border-radius-sm: 0.5rem; /* TBD */
  --unity-alias-border-radius-xs: 0.25rem; /* TBD */
  --unity-alias-border-radius-none: 0; /* TBD */
  --unity-alias-input-text-typography-success: 400 1rem / 1.5rem Otypical Headline;
  --unity-alias-input-text-typography-error: 400 1rem / 1.5rem Otypical Headline;
  --unity-alias-input-text-typography-helper: 400 1rem / 1.5rem Otypical Headline;
  --unity-alias-input-typography-value: 400 1rem / 1.5rem Otypical Headline;
  --unity-alias-input-typography-placeholder: 400 1rem / 1.5rem Otypical Headline;
  --unity-alias-input-label-typography-floating: 400 1rem / 1.5rem Otypical Headline;
  --unity-alias-input-label-typography-default: 500 1rem / 1.5rem Otypical Headline;
  --unity-alias-cta-border-width: 0.125rem; /* TBD */
  --unity-alias-cta-border-radius-lg: 4rem; /* TBD */
  --unity-alias-cta-border-radius-md: 3rem; /* TBD */
  --unity-alias-cta-border-radius-sm: 2rem; /* TBD */
  --unity-alias-space-button-lg-items: 1rem; /* Padding vertical */
  --unity-alias-space-button-lg-vertical: 1.25rem; /* Padding vertical */
  --unity-alias-space-button-lg-horizontal: 2rem; /* TBD */
  --unity-alias-space-button-md-items: 0.75rem; /* Padding vertical */
  --unity-alias-space-button-md-vertical: 0.75rem; /* Padding vertical */
  --unity-alias-space-button-md-horizontal: 1.5rem; /* TBD */
  --unity-alias-space-button-sm-items: 0.5rem; /* Padding vertical */
  --unity-alias-space-button-sm-vertical: 0.5rem; /* Padding vertical */
  --unity-alias-space-button-sm-horizontal: 1rem; /* TBD */
  --unity-alias-space-items-lg: 0.375rem; /* TBD */
  --unity-alias-space-items-md: 0.125rem; /* TBD */
  --unity-alias-space-items-sm: 0.25rem; /* TBD */
  --unity-alias-space-surface-lg-items: 0.375rem; /* TBD */
  --unity-alias-space-surface-lg-vertical: 0.5rem; /* TBD */
  --unity-alias-space-surface-lg-horizontal: 0.5rem; /* TBD */
  --unity-alias-space-surface-md-items: 0.25rem; /* TBD */
  --unity-alias-space-surface-md-vertical: 0.25rem; /* TBD */
  --unity-alias-space-surface-md-horizontal: 0.25rem; /* TBD */
  --unity-alias-space-surface-sm-items: 0.25rem; /* TBD */
  --unity-alias-space-surface-sm-vertical: 0.375rem; /* TBD */
  --unity-alias-space-surface-sm-horizontal: 0.25rem; /* TBD */
  --unity-alias-space-surface-xs-items: 0.125rem; /* TBD */
  --unity-alias-space-surface-xs-vertical: 0.125rem; /* TBD */
  --unity-alias-space-surface-xs-horizontal: 0.125rem; /* TBD */
  --unity-alias-space-surface-2-xs-items: 0.125rem; /* TBD */
  --unity-alias-space-surface-2-xs-vertical: 0.25rem; /* TBD */
  --unity-alias-space-surface-2-xs-horizontal: 0.125rem; /* TBD */
  --unity-alias-space-numbers-md-horizontal: 0.25rem; /* TBD */
  --unity-alias-space-numbers-sm-vertical: 0; /* TBD */
  --unity-alias-space-numbers-sm-horizontal: 0; /* TBD */
  --unity-alias-space-navigation-items: 0.25rem; /* TBD */
  --unity-alias-space-navigation-vertical: 0.25rem; /* TBD */
  --unity-alias-space-cta-lg-items: 0.25rem; /* TBD */
  --unity-alias-space-cta-lg-vertical: 0.125rem; /* TBD */
  --unity-alias-space-cta-lg-horizontal: 0.375rem; /* TBD */
  --unity-alias-space-cta-md-items: 0.25rem; /* TBD */
  --unity-alias-space-cta-md-vertical: 0.125rem; /* TBD */
  --unity-alias-space-cta-md-horizontal: 0.375rem; /* TBD */
  --unity-alias-space-cta-sm-items: 0.125rem; /* TBD */
  --unity-alias-space-cta-sm-vertical: 0.125rem; /* TBD */
  --unity-alias-space-cta-sm-horizontal: 0.25rem; /* TBD */
  --unity-alias-space-cta-xs-horizontal: 0.125rem; /* TBD */
  --unity-alias-divider-color-muted-dark: #444444; /* TBD */
  --unity-alias-divider-color-muted: #DDDDDD; /* TBD */
  --unity-alias-divider-color-base: #222222; /* TBD */
  --unity-alias-icon-size-2-xl: 5rem; /* TBD */
  --unity-alias-icon-size-xl: 4rem; /* TBD */
  --unity-alias-icon-size-lg: 3rem; /* TBD */
  --unity-alias-icon-size-md: 2rem; /* TBD */
  --unity-alias-icon-size-sm: 1.5rem; /* TBD */
  --unity-alias-icon-size-xs: 1rem; /* TBD */
  --unity-alias-icon-color-transparents-inverted: #ffffff66; /* TBD
*/
  --unity-alias-icon-color-transparents-base: #22222266; /* TBD */
  --unity-alias-icon-color-inverted: #fff; /* TBD */
  --unity-alias-icon-color-muted: #999999; /* TBD */
  --unity-alias-icon-color-base: #222222; /* TBD */
  --unity-alias-text-color-inverted: #fff; /* TBD */
  --unity-alias-text-color-muted-dark: #999999; /* TBD */
  --unity-alias-text-color-muted: #444444; /* TBD */
  --unity-alias-text-color-base: #222222; /* TBD */
  --unity-alias-glows-color-purple-700: #7066ff; /* TBD */
  --unity-alias-glows-color-purple-500: #8D85FF; /* TBD */
  --unity-alias-glows-color-purple-300: #A9A3FF; /* TBD */
  --unity-alias-glows-color-purple-100: #C6C2FF; /* TBD */
  --unity-alias-glows-color-pink-700: #ff808c; /* TBD */
  --unity-alias-glows-color-pink-500: #FF99A3; /* TBD */
  --unity-alias-glows-color-pink-300: #FFB3BA; /* TBD */
  --unity-alias-glows-color-pink-100: #FFCCD1; /* TBD */
  --unity-alias-glows-color-orange-700: #ff7621; /* TBD */
  --unity-alias-glows-color-orange-500: #FF914D; /* TBD */
  --unity-alias-glows-color-orange-300: #FFAD7A; /* TBD */
  --unity-alias-glows-color-orange-100: #FFC8A6; /* TBD */
  --unity-alias-glows-color-yellow-700: #ffac24; /* TBD */
  --unity-alias-glows-color-yellow-500: #FFBD50; /* TBD */
  --unity-alias-glows-color-yellow-300: #FFCD7C; /* TBD */
  --unity-alias-glows-color-yellow-100: #FFDEA7; /* TBD */
  --unity-alias-glows-color-green-700: #2f9a92; /* TBD */
  --unity-alias-glows-color-green-500: #59AEA8; /* TBD */
  --unity-alias-glows-color-green-300: #82C2BE; /* TBD */
  --unity-alias-glows-color-green-100: #ACD7D3; /* TBD */
  --unity-alias-glows-color-blue-700: #2c72ff; /* TBD */
  --unity-alias-glows-color-blue-500: #568EFF; /* TBD */
  --unity-alias-glows-color-blue-300: #80AAFF; /* TBD */
  --unity-alias-glows-color-blue-100: #ABC7FF; /* TBD */
  --unity-alias-color-numbers-muted-dark: #444444; /* TBD */
  --unity-alias-color-numbers-base: #000000; /* TBD */
  --unity-alias-color-states-persuasive-loading: #1B5EE4; /* TBD */
  --unity-alias-color-states-persuasive-disabled: #1B5EE4; /* Add opacity to the parent component, not in the token itself. */
  --unity-alias-color-states-persuasive-focus: #2c72ff; /* TBD */
  --unity-alias-color-states-persuasive-active: #1B5EE4; /* TBD */
  --unity-alias-color-states-persuasive-hover: #2c72ff; /* TBD */
  --unity-alias-color-states-persuasive-default: #1B5EE4; /* TBD */
  --unity-alias-color-states-inverted-loading: #fff; /* TBD */
  --unity-alias-color-states-inverted-disabled: #fff; /* Add opacity to the parent component, not in the token itself. */
  --unity-alias-color-states-inverted-focus: #DDDDDD; /* TBD */
  --unity-alias-color-states-inverted-active: #fff; /* TBD */
  --unity-alias-color-states-inverted-hover: #DDDDDD; /* TBD */
  --unity-alias-color-states-inverted-default: #fff; /* TBD */
  --unity-alias-color-states-base-loading: #222222; /* TBD */
  --unity-alias-color-states-base-disabled: #222222; /* Add opacity to the parent component, not in the token itself. */
  --unity-alias-color-states-base-focus: #444444; /* TBD */
  --unity-alias-color-states-base-active: #222222; /* TBD */
  --unity-alias-color-states-base-hover: #444444; /* TBD */
  --unity-alias-color-states-base-default: #000000; /* TBD */
  --unity-alias-color-semantic-warning: #ff7621; /* Warning callout message (icon). */
  --unity-alias-color-semantic-danger: #FE5F55; /* Error callout message (icon). */
  --unity-alias-color-semantic-success: #2f9a92; /* Success callout message (icon).
*/
  --unity-alias-color-semantic-information: #2c72ff; /* Information callout message (icon). */
  --unity-alias-color-sales: #1B5EE4; /* TBD */
  --unity-alias-color-outline-focus-outer: #1b5ee41a; /* TBD */
  --unity-alias-color-outline-focus-inner: #1B5EE4; /* TBD */
  --unity-alias-color-brand-secondary: #fff; /* TBD */
  --unity-alias-color-brand-primary: #000000; /* TBD */
  --unity-alias-color-border-selected: #222222; /* TBD */
  --unity-alias-color-border-focus: #000000; /* TBD */
  --unity-alias-color-border-hover: #999999; /* TBD */
  --unity-alias-color-border-default: #DDDDDD; /* TBD */
  --unity-alias-color-background-purple: #E8E7FF; /* TBD */
  --unity-alias-color-background-pink: #FFF5F6; /* TBD */
  --unity-alias-color-background-green: #EEF7F6; /* TBD */
  --unity-alias-color-background-blue: #ECF2FF; /* TBD */
  --unity-alias-color-background-inverted: #222222; /* TBD */
  --unity-alias-color-background-disabled: #DDDDDD; /* TBD */
  --unity-alias-color-background-base: #fff; /* TBD */
  --unity-component-help-tip-color-icon-base-base: #222222;
  --unity-component-help-tip-color-icon-inverted-inverted: #fff;
  --unity-component-help-tip-icon-size: 1.5rem;
  --unity-component-stock-status-text-color-out-of-stock-default: #FE5F55; /* T.B.D */
  --unity-component-stock-status-text-color-limited-in-stock-default: #ff7621; /* T.B.D */
  --unity-component-stock-status-text-color-in-stock-default: #2f9a92; /* T.B.D */
  --unity-component-stock-status-typography: 500 1.125rem / 1.5rem Otypical Headline;
  --unity-component-tabs-opacity-disabled: 0.2;
  --unity-component-tabs-space-items: 0.25rem;
  --unity-component-tabs-space-vertical: 0.25rem;
  --unity-component-tabs-typography: 500 1.25rem / 1.5rem Otypical Headline;
  --unity-component-tabs-color-border-selected-disabled: #222222;
  --unity-component-tabs-color-border-selected-focus: #444444;
  --unity-component-tabs-color-border-selected-active: #222222;
  --unity-component-tabs-color-border-selected-hover: #444444;
  --unity-component-tabs-color-border-selected-default: #000000;
  --unity-component-tabs-color-border-deselected-disabled: #222222;
  --unity-component-tabs-color-border-deselected-focus: #444444;
  --unity-component-tabs-color-border-deselected-active: #222222;
  --unity-component-tabs-color-border-deselected-hover: #444444;
  --unity-component-tabs-color-border-deselected-default: #000000;
  --unity-component-tabs-text-color-selected-disabled: #222222;
  --unity-component-tabs-text-color-selected-focus: #444444;
  --unity-component-tabs-text-color-selected-active: #222222;
  --unity-component-tabs-text-color-selected-hover: #444444;
  --unity-component-tabs-text-color-selected-default: #000000;
  --unity-component-tabs-text-color-deselected-disabled: #222222;
  --unity-component-tabs-text-color-deselected-focus: #444444;
  --unity-component-tabs-text-color-deselected-active: #222222;
  --unity-component-tabs-text-color-deselected-hover: #444444;
  --unity-component-tabs-text-color-deselected-default: #000000;
  --unity-component-tabs-border-width-deselected-disabled: 0;
  --unity-component-tabs-border-width-deselected-focus: 0.125rem;
  --unity-component-tabs-border-width-deselected-active: 0.125rem;
  --unity-component-tabs-border-width-deselected-hover: 0.125rem;
  --unity-component-tabs-border-width-deselected-default: 0;
  --unity-component-tabs-border-width-selected-disabled: 0.0625rem;
  --unity-component-tabs-border-width-selected-focus: 0.125rem;
  --unity-component-tabs-border-width-selected-active: 0.25rem;
  --unity-component-tabs-border-width-selected-hover: 0.125rem;
  --unity-component-tabs-border-width-selected-default: 0.125rem;
  --unity-component-metrics-color-digit-base-default: #000000;
  --unity-component-metrics-color-suffix-base-default: #000000;
  --unity-component-metrics-typography-digit-lg: 500 2.25rem / 2.5rem Otypical Headline;
  --unity-component-metrics-typography-digit-md: 500 2rem / 2.25rem Otypical Headline;
  --unity-component-metrics-typography-suffix-lg: 500 1rem / 1.5rem Otypical Headline;
  --unity-component-metrics-typography-suffix-md: 500 1rem / 1.5rem Otypical Headline;
  --unity-component-price-space-lg-horizontal: 0.25rem;
  --unity-component-price-space-md-horizontal: 0.25rem;
  --unity-component-price-opacity-disabled: 0.2;
  --unity-component-price-color-digit-base-disabled: #000000;
  --unity-component-price-color-digit-base-default: #000000;
  --unity-component-price-color-currency-base-disabled: #000000;
  --unity-component-price-color-currency-base-default: #000000;
  --unity-component-price-color-comma-base-disabled: #000000;
  --unity-component-price-color-comma-base-default: #000000;
  --unity-component-price-color-suffix-base-disabled: #000000;
  --unity-component-price-color-suffix-base-default: #000000;
  --unity-component-price-color-discount-base-disabled: #444444;
  --unity-component-price-color-discount-base-default: #444444;
  --unity-component-price-typography-digit-lg: 500 2rem / 2.25rem Otypical Headline;
  --unity-component-price-typography-digit-md: 500 1rem / 1.5rem Otypical Headline;
  --unity-component-price-typography-currency-lg: 500 2rem / 2.25rem Otypical Headline;
  --unity-component-price-typography-currency-md: 500 1rem / 1.5rem Otypical Headline;
  --unity-component-price-typography-comma-lg: 500 1.25rem / 2rem Otypical Headline;
  --unity-component-price-typography-suffix-lg: 500 1rem / 1.5rem Otypical Headline;
  --unity-component-price-typography-suffix-md: 500 1rem / 1.5rem Otypical Headline;
  --unity-component-price-typography-discount-md: 500 1rem / 1.5rem Otypical Headline;
  --unity-component-divider-color-background-muted: #DDDDDD;
  --unity-component-divider-color-background-base: #222222;
  --unity-component-tooltip-color-background: #222222;
  --unity-component-tooltip-text-color-default: #fff;
  --unity-component-tooltip-border-radius: 0.25rem;
  --unity-component-tooltip-icon-color-background: #2c72ff;
  --unity-component-tooltip-icon-color-base: #fff;
  --unity-component-tooltip-icon-size-md: 1.5rem;
  --unity-component-tooltip-icon-size-sm: 1.5rem;
  --unity-component-tooltip-typography: 400 0.875rem / 1.375rem Otypical Text;
  --unity-component-input-color-value-base: #000000;
  --unity-component-input-color-value-disabled: #444444;
  --unity-component-input-color-placeholder: #444444;
  --unity-component-input-color-border-error: #FE5F55;
  --unity-component-input-color-border-success: #2f9a92;
  --unity-component-input-color-border-selected: #222222;
  --unity-component-input-color-border-focus: #000000;
  --unity-component-input-color-border-selected-disabled: #999999;
  --unity-component-input-color-border-hover: #999999;
  --unity-component-input-color-border-default: #DDDDDD;
  --unity-component-input-color-background-message-helper: #444444;
  --unity-component-input-color-background-message-error: #FE5F55;
  --unity-component-input-color-background-disabled: #DDDDDD;
  --unity-component-input-color-background-base: #fff;
  --unity-component-input-typography-message: 400 0.875rem / 1.375rem Otypical Text;
  --unity-component-input-typography-value: 400 1rem / 1.5rem Otypical Text;
  --unity-component-input-label-color-error: #FE5F55;
  --unity-component-input-label-color-success: #2f9a92;
  --unity-component-input-label-color-base: #000000;
  --unity-component-input-label-typography-floating: 400 0.875rem / 1.375rem Otypical Text;
  --unity-component-input-label-typography-base: 400 1rem / 1.5rem Otypical Text;
  --unity-component-input-border-radius: 0.25rem;
  --unity-component-input-icon-color-surface-error: #FE5F55;
  --unity-component-input-icon-color-surface-dropdown: #22222266;
  --unity-component-input-icon-color-surface-base: #fff;
  --unity-component-input-icon-color-surface-success: #2f9a92;
  --unity-component-input-icon-size-md: 1.5rem;
  --unity-component-input-icon-size-sm: 2rem;
  --unity-component-badge-color-text-dark-base: #000000;
  --unity-component-badge-color-text-light-base: #fff;
  --unity-component-badge-color-background-dark-base: #fff;
  --unity-component-badge-color-background-light-base: #000000;
  --unity-component-badge-typography-default: 500 0.6875rem / 1.0625rem Otypical Headline;
  --unity-component-bottom-nav-typography-active: 500 0.875rem / 1.375rem Otypical Text;
  --unity-component-bottom-nav-typography-default: 400 0.875rem / 1.375rem Otypical Text;
  --unity-component-bottom-nav-icon-size: 1.5rem;
  --unity-component-bottom-nav-color-icon-dark-active: #fff;
  --unity-component-bottom-nav-color-icon-dark-default: #999999;
  --unity-component-bottom-nav-color-icon-light-active: #222222;
  --unity-component-bottom-nav-color-icon-light-default: #999999;
  --unity-component-bottom-nav-color-background-dark-default: #222222;
  --unity-component-bottom-nav-color-background-light-default: #fff;
  --unity-component-bottom-nav-color-text-dark-active: #fff;
  --unity-component-bottom-nav-color-text-dark-default: #222222;
  --unity-component-bottom-nav-color-text-light-active: #000000;
  --unity-component-bottom-nav-color-text-light-default: #444444;
  --unity-component-bottom-nav-color-border-dark-default: #444444;
  --unity-component-bottom-nav-color-border-light-default: #DDDDDD;
  --unity-component-textlink-color-persuasive-default: #1B5EE4;
  --unity-component-textlink-typography-md: 500 0.875rem / 1.375rem Otypical Headline;
  --unity-component-textlink-space-horizontal: 0.125rem;
  --unity-component-callout-description-icon-border-radius: 4rem;
  --unity-component-callout-message-icon-border-radius: 2rem;
  --unity-component-callout-border-radius-bottom-right: 3rem;
  --unity-component-callout-border-radius-top-right: 3rem;
  --unity-component-callout-border-radius-top-left: 3rem;
  --unity-component-callout-space-items-horizontal: 0.125rem;
  --unity-component-callout-space-items-vertical: 0.125rem;
  --unity-component-callout-space-surface-surface: 0.5rem;
  --unity-component-callout-color-border-warning: #ff7621; /* Warning callout message (icon). */
  --unity-component-callout-color-border-danger: #FE5F55; /* Error callout message (icon). */
  --unity-component-callout-color-border-success: #2f9a92; /* Success callout message (icon).
*/
  --unity-component-callout-color-border-information: #2c72ff; /* Information callout message (icon). */
  --unity-component-callout-background-inverted: #000000;
  --unity-component-callout-background-base: #fff;
  --unity-component-callout-background-warning: #ff7621; /* Warning callout message (icon). */
  --unity-component-callout-background-danger: #FE5F55; /* Error callout message (icon). */
  --unity-component-callout-background-success: #2f9a92; /* Success callout message (icon).
*/
  --unity-component-callout-background-information: #2c72ff; /* Information callout message (icon). */
  --unity-component-callout-description-typography: 400 1rem / 1.5rem Otypical Text;
  --unity-component-callout-title-typography: 400 1rem / 1.5rem Otypical Text;
  --unity-component-callout-text-color-base-inverted: #fff;
  --unity-component-callout-text-color-base-base: #000000;
  --unity-component-callout-icon-color-base-base: #222222;
  --unity-component-callout-icon-color-base-inverted: #fff;
  --unity-component-callout-size-description-icon: 1.5rem;
  --unity-component-callout-size-message-icon: 2rem;
  --unity-component-button-opacity-disabled: 0.2; /* TBD */
  --unity-component-button-size-height-lg: 4rem;
  --unity-component-button-size-height-md: 3rem;
  --unity-component-button-size-height-sm: 2rem;
  --unity-component-button-icon-size-lg: 1.5rem;
  --unity-component-button-icon-size-md: 1.5rem;
  --unity-component-button-icon-size-sm: 1rem;
  --unity-component-button-icon-color-persuasive-loading-transparency-solid: #ffffff66;
  --unity-component-button-icon-color-persuasive-loading-transparency-outline: #22222266;
  --unity-component-button-icon-color-persuasive-loading-solid: #fff;
  --unity-component-button-icon-color-persuasive-loading-outline: #222222;
  --unity-component-button-icon-color-persuasive-disabled-solid: #fff;
  --unity-component-button-icon-color-persuasive-disabled-outline: #222222;
  --unity-component-button-icon-color-persuasive-focus-solid: #fff;
  --unity-component-button-icon-color-persuasive-focus-outline: #fff;
  --unity-component-button-icon-color-persuasive-active-solid: #fff;
  --unity-component-button-icon-color-persuasive-active-outline: #fff;
  --unity-component-button-icon-color-persuasive-hover-solid: #fff;
  --unity-component-button-icon-color-persuasive-hover-outline: #fff;
  --unity-component-button-icon-color-persuasive-default-solid: #fff;
  --unity-component-button-icon-color-persuasive-default-outline: #222222;
  --unity-component-button-icon-color-inverted-loading-transparency-solid: #22222266;
  --unity-component-button-icon-color-inverted-loading-transparency-outline: #ffffff66;
  --unity-component-button-icon-color-inverted-loading-solid: #222222;
  --unity-component-button-icon-color-inverted-loading-outline: #fff;
  --unity-component-button-icon-color-inverted-disabled-solid: #222222;
  --unity-component-button-icon-color-inverted-disabled-outline: #fff;
  --unity-component-button-icon-color-inverted-focus-solid: #222222;
  --unity-component-button-icon-color-inverted-focus-outline: #222222;
  --unity-component-button-icon-color-inverted-active-solid: #222222;
  --unity-component-button-icon-color-inverted-active-outline: #222222;
  --unity-component-button-icon-color-inverted-hover-solid: #222222;
  --unity-component-button-icon-color-inverted-hover-outline: #222222;
  --unity-component-button-icon-color-inverted-default-solid: #222222;
  --unity-component-button-icon-color-inverted-default-outline: #fff;
  --unity-component-button-icon-color-base-loading-transparency-solid: #ffffff66;
  --unity-component-button-icon-color-base-loading-transparency-outline: #22222266;
  --unity-component-button-icon-color-base-loading-solid: #fff;
  --unity-component-button-icon-color-base-loading-outline: #222222;
  --unity-component-button-icon-color-base-disabled-solid: #fff;
  --unity-component-button-icon-color-base-disabled-outline: #222222;
  --unity-component-button-icon-color-base-focus-solid: #fff;
  --unity-component-button-icon-color-base-focus-outline: #fff;
  --unity-component-button-icon-color-base-active-solid: #fff;
  --unity-component-button-icon-color-base-active-outline: #fff;
  --unity-component-button-icon-color-base-hover-solid: #fff;
  --unity-component-button-icon-color-base-hover-outline: #fff;
  --unity-component-button-icon-color-base-default-solid: #fff;
  --unity-component-button-icon-color-base-default-outline: #222222;
  --unity-component-button-color-border-persuasive-disabled-outline: #1B5EE4;
  --unity-component-button-color-border-persuasive-focus-outline: transparent;
  --unity-component-button-color-border-persuasive-active-outline: transparent;
  --unity-component-button-color-border-persuasive-hover-outline: transparent;
  --unity-component-button-color-border-persuasive-loading-outline: #1B5EE4;
  --unity-component-button-color-border-persuasive-default-outline: #1B5EE4;
  --unity-component-button-color-border-inverted-disabled-outline: #fff;
  --unity-component-button-color-border-inverted-focus-outline: transparent;
  --unity-component-button-color-border-inverted-active-outline: transparent;
  --unity-component-button-color-border-inverted-hover-outline: transparent;
  --unity-component-button-color-border-inverted-loading-outline: #fff;
  --unity-component-button-color-border-inverted-default-outline: #fff;
  --unity-component-button-color-border-base-disabled-outline: #222222;
  --unity-component-button-color-border-base-focus-outline: transparent;
  --unity-component-button-color-border-base-active-outline: transparent;
  --unity-component-button-color-border-base-hover-outline: transparent;
  --unity-component-button-color-border-base-loading-outline: #222222;
  --unity-component-button-color-border-base-default-outline: #000000;
  --unity-component-button-color-background-persuasive-disabled-solid: #1B5EE4;
  --unity-component-button-color-background-persuasive-disabled-outline: transparent;
  --unity-component-button-color-background-persuasive-focus-solid: #2c72ff;
  --unity-component-button-color-background-persuasive-focus-outline: #2c72ff;
  --unity-component-button-color-background-persuasive-active-solid: #1B5EE4;
  --unity-component-button-color-background-persuasive-active-outline: #1B5EE4;
  --unity-component-button-color-background-persuasive-hover-solid: #2c72ff;
  --unity-component-button-color-background-persuasive-hover-outline: #2c72ff;
  --unity-component-button-color-background-persuasive-default-solid: #1B5EE4;
  --unity-component-button-color-background-persuasive-default-outline: transparent;
  --unity-component-button-color-background-persuasive-loading-solid: #1B5EE4;
  --unity-component-button-color-background-persuasive-loading-outline: transparent;
  --unity-component-button-color-background-inverted-disabled-solid: #fff;
  --unity-component-button-color-background-inverted-disabled-outline: transparent;
  --unity-component-button-color-background-inverted-focus-solid: #DDDDDD;
  --unity-component-button-color-background-inverted-focus-outline: #DDDDDD;
  --unity-component-button-color-background-inverted-active-solid: #fff;
  --unity-component-button-color-background-inverted-active-outline: #fff;
  --unity-component-button-color-background-inverted-hover-solid: #DDDDDD;
  --unity-component-button-color-background-inverted-hover-outline: #DDDDDD;
  --unity-component-button-color-background-inverted-default-solid: #fff;
  --unity-component-button-color-background-inverted-default-outline: transparent;
  --unity-component-button-color-background-inverted-loading-solid: #fff;
  --unity-component-button-color-background-inverted-loading-outline: transparent;
  --unity-component-button-color-background-base-disabled-solid: #222222;
  --unity-component-button-color-background-base-disabled-outline: transparent;
  --unity-component-button-color-background-base-focus-solid: #444444;
  --unity-component-button-color-background-base-focus-outline: #444444;
  --unity-component-button-color-background-base-active-solid: #222222;
  --unity-component-button-color-background-base-active-outline: #222222;
  --unity-component-button-color-background-base-hover-solid: #444444;
  --unity-component-button-color-background-base-hover-outline: #444444;
  --unity-component-button-color-background-base-loading-solid: #000000;
  --unity-component-button-color-background-base-loading-outline: transparent;
  --unity-component-button-color-background-base-default-solid: #000000;
  --unity-component-button-color-background-base-default-outline: transparent;
  --unity-component-button-border-width: 0.125rem;
  --unity-component-button-border-radius-lg: 4rem;
  --unity-component-button-border-radius-md: 3rem;
  --unity-component-button-border-radius-sm: 2rem;
  --unity-component-button-text-color-persuasive-disabled-solid: #fff;
  --unity-component-button-text-color-persuasive-disabled-outline: #000000;
  --unity-component-button-text-color-persuasive-focus-solid: #fff;
  --unity-component-button-text-color-persuasive-focus-outline: #fff;
  --unity-component-button-text-color-persuasive-active-solid: #fff;
  --unity-component-button-text-color-persuasive-active-outline: #fff;
  --unity-component-button-text-color-persuasive-hover-solid: #fff;
  --unity-component-button-text-color-persuasive-hover-outline: #fff;
  --unity-component-button-text-color-persuasive-default-solid: #fff;
  --unity-component-button-text-color-persuasive-default-outline: #000000;
  --unity-component-button-text-color-inverted-disabled-solid: #000000;
  --unity-component-button-text-color-inverted-disabled-outline: #fff;
  --unity-component-button-text-color-inverted-focus-solid: #000000;
  --unity-component-button-text-color-inverted-focus-outline: #000000;
  --unity-component-button-text-color-inverted-active-solid: #000000;
  --unity-component-button-text-color-inverted-active-outline: #000000;
  --unity-component-button-text-color-inverted-hover-solid: #000000;
  --unity-component-button-text-color-inverted-hover-outline: #000000;
  --unity-component-button-text-color-inverted-default-solid: #000000;
  --unity-component-button-text-color-inverted-default-outline: #fff;
  --unity-component-button-text-color-base-disabled-solid: #fff;
  --unity-component-button-text-color-base-disabled-outline: #000000;
  --unity-component-button-text-color-base-focus-solid: #fff;
  --unity-component-button-text-color-base-focus-outline: #fff;
  --unity-component-button-text-color-base-active-solid: #fff;
  --unity-component-button-text-color-base-active-outline: #fff;
  --unity-component-button-text-color-base-hover-solid: #fff;
  --unity-component-button-text-color-base-hover-outline: #fff;
  --unity-component-button-text-color-base-default-solid: #fff;
  --unity-component-button-text-color-base-default-outline: #000000;
  --unity-component-button-typography-lg: 500 1.25rem / 2rem Otypical Text;
  --unity-component-button-typography-md: 500 1rem / 1.5rem Otypical Text;
  --unity-component-button-typography-sm: 500 0.875rem / 1.375rem Otypical Text;
  --unity-component-button-space-lg-items: 1rem;
  --unity-component-button-space-lg-vertical: 1.25rem;
  --unity-component-button-space-lg-horizontal: 2rem;
  --unity-component-button-space-md-items: 0.75rem;
  --unity-component-button-space-md-vertical: 0.75rem;
  --unity-component-button-space-md-horizontal: 1.5rem;
  --unity-component-button-space-sm-items: 0.5rem;
  --unity-component-button-space-sm-vertical: 0.5rem;
  --unity-component-button-space-sm-horizontal: 1rem;
}

:root {
  --unity-global-motion-duration-50: 0.083s;
  --unity-global-motion-duration-100: 0.1s;
  --unity-global-motion-duration-150: 0.13s;
  --unity-global-motion-duration-200: 0.167s;
  --unity-global-motion-duration-300: 0.25s;
  --unity-global-motion-duration-400: 0.333s;
  --unity-global-motion-duration-450: 0.417s;
  --unity-global-motion-duration-500: 0.5s;
  --unity-global-motion-duration-600: 0.667s;
  --unity-global-motion-duration-700: 0.833s;
  --unity-global-motion-duration-800: 0.1s;
  --unity-global-motion-duration-900: 0.125s;
  --unity-global-motion-duration-1000: 0.15s;
  --unity-global-motion-delay-0: 0s;
  --unity-global-motion-delay-100: 0.016s;
  --unity-global-motion-delay-200: 0.033s;
  --unity-global-motion-delay-300: 0.05s;
  --unity-global-motion-delay-400: 0.083s;
  --unity-global-motion-delay-500: 0.133s;
  --unity-global-motion-delay-600: 0.167s;
  --unity-global-motion-delay-650: 0.2s;
  --unity-global-motion-delay-700: 0.25s;
  --unity-global-motion-delay-800: 0.333s;
  --unity-global-motion-delay-900: 0.417s;
  --unity-global-motion-delay-1000: 0.5s;
  --unity-global-motion-ease-0: cubic-bezier(0, 0, 1, 1);
  --unity-global-motion-ease-out-100: cubic-bezier(0.3, 0, 0.3, 1);
  --unity-global-motion-ease-out-200: cubic-bezier(0.25, 0, 0, 1);
  --unity-global-motion-ease-out-300: cubic-bezier(0, 0, 0, 1);
  --unity-global-motion-ease-out-400: cubic-bezier(0.1, 0, 0.05, 1);
  --unity-global-motion-ease-in-200: cubic-bezier(0.6, 0, 1, 1);
  --unity-global-motion-ease-in-300: cubic-bezier(1, 0, 1, 1);
  --unity-global-motion-ease-in-400: cubic-bezier(0.95, 0, 0.9, 1);
  --unity-global-motion-ease-in-out-100: cubic-bezier(0.33, 0, 0.67, 1);
  --unity-global-motion-ease-in-out-200: cubic-bezier(0.65, 0, 0.35, 1);
  --unity-global-motion-ease-in-out-300: cubic-bezier(0.85, 0, 0.15, 1);
  --unity-global-motion-ease-in-out-400: cubic-bezier(0.85, 0, 0.15, 1);
}

/** css variables import for dark mode, uncomment the following lines to include the dark themes when you want start the implementation for those */
@-webkit-keyframes donut-loader-animation {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes donut-loader-animation {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
:root {
  --icon-24h-solid: "";
  --icon-24h: "";
  --icon-3d-solid: "";
  --icon-3d: "";
  --icon-4g-for-home-solid: "";
  --icon-4g-for-home: "";
  --icon-5g-solid: "";
  --icon-5g: "";
  --icon-accessoires-solid: "";
  --icon-accessoires: "";
  --icon-add-device-solid: "";
  --icon-add-device: "";
  --icon-add-solid: "";
  --icon-add: "";
  --icon-airplane-solid: "";
  --icon-airplane: "";
  --icon-alert-solid: "";
  --icon-alert-square-solid: "";
  --icon-alert-square: "";
  --icon-alert: "";
  --icon-angry-solid: "";
  --icon-angry: "";
  --icon-app-solid: "";
  --icon-app: "";
  --icon-appointment-solid: "";
  --icon-appointment: "";
  --icon-arrow-collapse-solid: "";
  --icon-arrow-collapse: "";
  --icon-arrow-down-solid: "";
  --icon-arrow-down: "";
  --icon-arrow-download-solid: "";
  --icon-arrow-download: "";
  --icon-arrow-expand-solid: "";
  --icon-arrow-expand: "";
  --icon-arrow-left-solid: "";
  --icon-arrow-left: "";
  --icon-arrow-right-solid: "";
  --icon-arrow-right: "";
  --icon-arrow-sort-solid: "";
  --icon-arrow-sort: "";
  --icon-arrow-up-solid: "";
  --icon-arrow-up: "";
  --icon-arrow-upload-solid: "";
  --icon-arrow-upload: "";
  --icon-aspect-ratio-16-9-solid: "";
  --icon-aspect-ratio-16-9: "";
  --icon-aspect-ratio-4-3-solid: "";
  --icon-aspect-ratio-4-3: "";
  --icon-attachment-solid: "";
  --icon-attachment: "";
  --icon-auto-solid: "";
  --icon-auto: "";
  --icon-award-solid: "";
  --icon-award: "";
  --icon-bar-diagram-solid: "";
  --icon-bar-diagram: "";
  --icon-battery-solid: "";
  --icon-battery: "";
  --icon-bookmark-solid: "";
  --icon-bookmark: "";
  --icon-briefcase-solid: "";
  --icon-briefcase: "";
  --icon-business-alt-solid: "";
  --icon-calendar-solid: "";
  --icon-calendar: "";
  --icon-call-log-solid: "";
  --icon-call-log: "";
  --icon-camera-solid: "";
  --icon-camera: "";
  --icon-change-player-pop-in-solid: "";
  --icon-change-player-pop-in: "";
  --icon-change-player-pop-out-solid: "";
  --icon-change-player-pop-out: "";
  --icon-change-player-small-solid: "";
  --icon-change-player-small: "";
  --icon-change-player-to-big-solid: "";
  --icon-change-player-to-big: "";
  --icon-chat-solid: "";
  --icon-chat: "";
  --icon-checkmark-solid: "";
  --icon-checkmark: "";
  --icon-chevron-down-solid: "";
  --icon-chevron-down: "";
  --icon-chevron-left-solid: "";
  --icon-chevron-left: "";
  --icon-chevron-oval-left-solid: "";
  --icon-chevron-oval-left: "";
  --icon-chevron-oval-right-solid: "";
  --icon-chevron-oval-right: "";
  --icon-chevron-right-solid: "";
  --icon-chevron-right: "";
  --icon-chevron-up-solid: "";
  --icon-chevron-up: "";
  --icon-choose-solid: "";
  --icon-choose: "";
  --icon-circle-solid: "";
  --icon-circle: "";
  --icon-clear-solid: "";
  --icon-clear: "";
  --icon-clock-solid: "";
  --icon-clock: "";
  --icon-close-solid: "";
  --icon-close: "";
  --icon-cloud-files-solid: "";
  --icon-cloud-files: "";
  --icon-cloud-solid: "";
  --icon-cloud-upload-solid: "";
  --icon-cloud-upload: "";
  --icon-cloud: "";
  --icon-cm-solid: "";
  --icon-cm: "";
  --icon-code-solid: "";
  --icon-code: "";
  --icon-comment-solid: "";
  --icon-comment: "";
  --icon-contact-list-solid: "";
  --icon-contact-list: "";
  --icon-contracts-solid: "";
  --icon-contracts: "";
  --icon-cost-solid: "";
  --icon-cost: "";
  --icon-coupon-solid: "";
  --icon-coupon: "";
  --icon-damage-solid: "";
  --icon-damage: "";
  --icon-data-privacy-solid: "";
  --icon-data-privacy: "";
  --icon-data-upsell-solid: "";
  --icon-data-upsell: "";
  --icon-delivery-solid: "";
  --icon-delivery: "";
  --icon-desktop-solid: "";
  --icon-desktop: "";
  --icon-dial-solid: "";
  --icon-dial: "";
  --icon-discount-solid: "";
  --icon-discount: "";
  --icon-edit-solid: "";
  --icon-edit: "";
  --icon-energy-solid: "";
  --icon-energy: "";
  --icon-euro-solid: "";
  --icon-euro: "";
  --icon-excited-solid: "";
  --icon-excited: "";
  --icon-extra-media-solid: "";
  --icon-extra-media: "";
  --icon-facebook-solid: "";
  --icon-facebook: "";
  --icon-family-solid: "";
  --icon-family: "";
  --icon-favorite-solid: "";
  --icon-favorite: "";
  --icon-file-edit-solid: "";
  --icon-file-edit: "";
  --icon-file-eror-solid: "";
  --icon-file-eror: "";
  --icon-file-solid: "";
  --icon-file: "";
  --icon-filter-solid: "";
  --icon-filter: "";
  --icon-fixed-phone-solid: "";
  --icon-fixed-phone: "";
  --icon-flip-solid: "";
  --icon-flip: "";
  --icon-folder-invoice-solid: "";
  --icon-folder-invoice: "";
  --icon-folder-solid: "";
  --icon-folder: "";
  --icon-forum-solid: "";
  --icon-forum: "";
  --icon-forward-10-sec-solid: "";
  --icon-forward-10-sec: "";
  --icon-forward-30-sec-solid: "";
  --icon-forward-30-sec: "";
  --icon-forward-solid: "";
  --icon-forward: "";
  --icon-fullscreen-solid: "";
  --icon-fullscreen: "";
  --icon-gallery-solid: "";
  --icon-gallery: "";
  --icon-global-solid: "";
  --icon-global: "";
  --icon-grid-solid: "";
  --icon-grid: "";
  --icon-happy-solid: "";
  --icon-happy: "";
  --icon-hd-solid: "";
  --icon-hd: "";
  --icon-helpdesk-solid: "";
  --icon-helpdesk: "";
  --icon-home-solid: "";
  --icon-home: "";
  --icon-image-solid: "";
  --icon-image: "";
  --icon-incoming-call-solid: "";
  --icon-incoming-call: "";
  --icon-infinite: "";
  --icon-info-solid: "";
  --icon-info-square-solid: "";
  --icon-info-square: "";
  --icon-info: "";
  --icon-instagram-solid: "";
  --icon-instagram: "";
  --icon-insurance-solid: "";
  --icon-insurance: "";
  --icon-internet-of-things-solid: "";
  --icon-internet-of-things: "";
  --icon-internet-solid: "";
  --icon-internet: "";
  --icon-invisible-solid: "";
  --icon-invisible: "";
  --icon-invoice-solid: "";
  --icon-invoice: "";
  --icon-key-solid: "";
  --icon-key: "";
  --icon-laptop-solid: "";
  --icon-laptop: "";
  --icon-layers-solid: "";
  --icon-layers: "";
  --icon-line-diagram-solid: "";
  --icon-line-diagram: "";
  --icon-link-solid: "";
  --icon-link: "";
  --icon-linkedin-solid: "";
  --icon-linkedin: "";
  --icon-loader: "";
  --icon-loading-solid: "";
  --icon-loading: "";
  --icon-location-pin-solid: "";
  --icon-location-pin: "";
  --icon-lock-solid: "";
  --icon-lock: "";
  --icon-logout-solid: "";
  --icon-logout: "";
  --icon-lowest-solid: "";
  --icon-lowest: "";
  --icon-mail-solid: "";
  --icon-mail: "";
  --icon-media-solid: "";
  --icon-media: "";
  --icon-menu-solid: "";
  --icon-menu: "";
  --icon-mic-solid: "";
  --icon-mic: "";
  --icon-min-solid: "";
  --icon-min: "";
  --icon-mobile-home-solid: "";
  --icon-mobile-home: "";
  --icon-mobile-phone-solid: "";
  --icon-mobile-phone: "";
  --icon-mobile-protect-solid: "";
  --icon-mobile-protect: "";
  --icon-more-solid: "";
  --icon-more: "";
  --icon-multi-sim-solid: "";
  --icon-multi-sim: "";
  --icon-mute-alert-solid: "";
  --icon-mute-alert: "";
  --icon-mute-sound-solid: "";
  --icon-mute-sound: "";
  --icon-my-location-solid: "";
  --icon-my-location: "";
  --icon-neutral-solid: "";
  --icon-neutral: "";
  --icon-no-video-solid: "";
  --icon-no-video: "";
  --icon-order-solid: "";
  --icon-order: "";
  --icon-oval-checkmark-solid: "";
  --icon-oval-checkmark: "";
  --icon-oval-close-solid: "";
  --icon-oval-close: "";
  --icon-overview-solid: "";
  --icon-overview: "";
  --icon-payout-solid: "";
  --icon-payout: "";
  --icon-pdf-solid: "";
  --icon-pdf: "";
  --icon-pin-solid: "";
  --icon-pin: "";
  --icon-placeholder-solid: "";
  --icon-placeholder: "";
  --icon-play-solid: "";
  --icon-play: "";
  --icon-plus-solid: "";
  --icon-plus: "";
  --icon-prepaid-solid: "";
  --icon-prepaid: "";
  --icon-printer-solid: "";
  --icon-printer: "";
  --icon-processor-solid: "";
  --icon-processor: "";
  --icon-profile-business-alt: "";
  --icon-profile-business-solid: "";
  --icon-profile-business: "";
  --icon-profile-solid: "";
  --icon-profile: "";
  --icon-protection-solid: "";
  --icon-protection: "";
  --icon-question-mark-solid: "";
  --icon-question-mark: "";
  --icon-question-square-solid: "";
  --icon-question-square: "";
  --icon-rating-solid: "";
  --icon-rating: "";
  --icon-recycle-solid: "";
  --icon-recycle: "";
  --icon-refresh-solid: "";
  --icon-refresh: "";
  --icon-regional-pin-solid: "";
  --icon-regional-pin: "";
  --icon-reload-solid: "";
  --icon-reload: "";
  --icon-renew-solid: "";
  --icon-renew: "";
  --icon-rewind-10-sec-solid: "";
  --icon-rewind-10-sec: "";
  --icon-rewind-30-sec-solid: "";
  --icon-rewind-30-sec: "";
  --icon-rotate-solid: "";
  --icon-rotate: "";
  --icon-sad-solid: "";
  --icon-sad: "";
  --icon-schedule-solid: "";
  --icon-schedule: "";
  --icon-screensize-solid: "";
  --icon-screensize: "";
  --icon-sd-solid: "";
  --icon-sd: "";
  --icon-search-solid: "";
  --icon-search: "";
  --icon-security-solid: "";
  --icon-security: "";
  --icon-self-service-solid: "";
  --icon-self-service: "";
  --icon-send-solid: "";
  --icon-settings-solid: "";
  --icon-settings: "";
  --icon-share-solid: "";
  --icon-share: "";
  --icon-shop-solid: "";
  --icon-shop: "";
  --icon-shopping-list-solid: "";
  --icon-shopping-list: "";
  --icon-simcard-activate-solid: "";
  --icon-simcard-activate: "";
  --icon-simcard-cancel-solid: "";
  --icon-simcard-cancel: "";
  --icon-simcard-solid: "";
  --icon-simcard: "";
  --icon-slider: "";
  --icon-smartphone-data-transfer-solid: "";
  --icon-smartphone-data-transfer: "";
  --icon-smartwatch-solid: "";
  --icon-smartwatch: "";
  --icon-sound-solid: "";
  --icon-sound: "";
  --icon-spam-email-solid: "";
  --icon-spam-email: "";
  --icon-status-alert-solid: "";
  --icon-status-alert: "";
  --icon-status-error-solid: "";
  --icon-status-error: "";
  --icon-status-info-solid: "";
  --icon-status-info: "";
  --icon-status-success-solid: "";
  --icon-status-success: "";
  --icon-status-warning-solid: "";
  --icon-status-warning: "";
  --icon-store-solid: "";
  --icon-store: "";
  --icon-tablet-solid: "";
  --icon-tablet: "";
  --icon-telekom-solid: "";
  --icon-telekom: "";
  --icon-text-solid: "";
  --icon-text: "";
  --icon-theft-solid: "";
  --icon-thumbs-down-solid: "";
  --icon-thumbs-up-solid: "";
  --icon-trashcan-solid: "";
  --icon-trophy-solid: "";
  --icon-tv-solid: "";
  --icon-tv: "";
  --icon-twitter-solid: "";
  --icon-unlimited-solid: "";
  --icon-unlimited: "";
  --icon-unlink-solid: "";
  --icon-unlink: "";
  --icon-unlock-solid: "";
  --icon-unlock: "";
  --icon-visible-solid: "";
  --icon-voicemail-solid: "";
  --icon-wallet-solid: "";
  --icon-warning-solid: "";
  --icon-wifi-solid: "";
  --icon-youtube-solid: "";
}

.icon-24h-solid:before {
  content: var(--icon-24h-solid);
}

.icon-24h:before {
  content: var(--icon-24h);
}

.icon-3d-solid:before {
  content: var(--icon-3d-solid);
}

.icon-3d:before {
  content: var(--icon-3d);
}

.icon-4g-for-home-solid:before {
  content: var(--icon-4g-for-home-solid);
}

.icon-4g-for-home:before {
  content: var(--icon-4g-for-home);
}

.icon-5g-solid:before {
  content: var(--icon-5g-solid);
}

.icon-5g:before {
  content: var(--icon-5g);
}

.icon-accessoires-solid:before {
  content: var(--icon-accessoires-solid);
}

.icon-accessoires:before {
  content: var(--icon-accessoires);
}

.icon-add-device-solid:before {
  content: var(--icon-add-device-solid);
}

.icon-add-device:before {
  content: var(--icon-add-device);
}

.icon-add-solid:before {
  content: var(--icon-add-solid);
}

.icon-add:before {
  content: var(--icon-add);
}

.icon-airplane-solid:before {
  content: var(--icon-airplane-solid);
}

.icon-airplane:before {
  content: var(--icon-airplane);
}

.icon-alert-solid:before {
  content: var(--icon-alert-solid);
}

.icon-alert-square-solid:before {
  content: var(--icon-alert-square-solid);
}

.icon-alert-square:before {
  content: var(--icon-alert-square);
}

.icon-alert:before {
  content: var(--icon-alert);
}

.icon-angry-solid:before {
  content: var(--icon-angry-solid);
}

.icon-angry:before {
  content: var(--icon-angry);
}

.icon-app-solid:before {
  content: var(--icon-app-solid);
}

.icon-app:before {
  content: var(--icon-app);
}

.icon-appointment-solid:before {
  content: var(--icon-appointment-solid);
}

.icon-appointment:before {
  content: var(--icon-appointment);
}

.icon-arrow-collapse-solid:before {
  content: var(--icon-arrow-collapse-solid);
}

.icon-arrow-collapse:before {
  content: var(--icon-arrow-collapse);
}

.icon-arrow-down-solid:before {
  content: var(--icon-arrow-down-solid);
}

.icon-arrow-down:before {
  content: var(--icon-arrow-down);
}

.icon-arrow-download-solid:before {
  content: var(--icon-arrow-download-solid);
}

.icon-arrow-download:before {
  content: var(--icon-arrow-download);
}

.icon-arrow-expand-solid:before {
  content: var(--icon-arrow-expand-solid);
}

.icon-arrow-expand:before {
  content: var(--icon-arrow-expand);
}

.icon-arrow-left-solid:before {
  content: var(--icon-arrow-left-solid);
}

.icon-arrow-left:before {
  content: var(--icon-arrow-left);
}

.icon-arrow-right-solid:before {
  content: var(--icon-arrow-right-solid);
}

.icon-arrow-right:before {
  content: var(--icon-arrow-right);
}

.icon-arrow-sort-solid:before {
  content: var(--icon-arrow-sort-solid);
}

.icon-arrow-sort:before {
  content: var(--icon-arrow-sort);
}

.icon-arrow-up-solid:before {
  content: var(--icon-arrow-up-solid);
}

.icon-arrow-up:before {
  content: var(--icon-arrow-up);
}

.icon-arrow-upload-solid:before {
  content: var(--icon-arrow-upload-solid);
}

.icon-arrow-upload:before {
  content: var(--icon-arrow-upload);
}

.icon-aspect-ratio-16-9-solid:before {
  content: var(--icon-aspect-ratio-16-9-solid);
}

.icon-aspect-ratio-16-9:before {
  content: var(--icon-aspect-ratio-16-9);
}

.icon-aspect-ratio-4-3-solid:before {
  content: var(--icon-aspect-ratio-4-3-solid);
}

.icon-aspect-ratio-4-3:before {
  content: var(--icon-aspect-ratio-4-3);
}

.icon-attachment-solid:before {
  content: var(--icon-attachment-solid);
}

.icon-attachment:before {
  content: var(--icon-attachment);
}

.icon-auto-solid:before {
  content: var(--icon-auto-solid);
}

.icon-auto:before {
  content: var(--icon-auto);
}

.icon-award-solid:before {
  content: var(--icon-award-solid);
}

.icon-award:before {
  content: var(--icon-award);
}

.icon-bar-diagram-solid:before {
  content: var(--icon-bar-diagram-solid);
}

.icon-bar-diagram:before {
  content: var(--icon-bar-diagram);
}

.icon-battery-solid:before {
  content: var(--icon-battery-solid);
}

.icon-battery:before {
  content: var(--icon-battery);
}

.icon-bookmark-solid:before {
  content: var(--icon-bookmark-solid);
}

.icon-bookmark:before {
  content: var(--icon-bookmark);
}

.icon-briefcase-solid:before {
  content: var(--icon-briefcase-solid);
}

.icon-briefcase:before {
  content: var(--icon-briefcase);
}

.icon-business-alt-solid:before {
  content: var(--icon-business-alt-solid);
}

.icon-calendar-solid:before {
  content: var(--icon-calendar-solid);
}

.icon-calendar:before {
  content: var(--icon-calendar);
}

.icon-call-log-solid:before {
  content: var(--icon-call-log-solid);
}

.icon-call-log:before {
  content: var(--icon-call-log);
}

.icon-camera-solid:before {
  content: var(--icon-camera-solid);
}

.icon-camera:before {
  content: var(--icon-camera);
}

.icon-change-player-pop-in-solid:before {
  content: var(--icon-change-player-pop-in-solid);
}

.icon-change-player-pop-in:before {
  content: var(--icon-change-player-pop-in);
}

.icon-change-player-pop-out-solid:before {
  content: var(--icon-change-player-pop-out-solid);
}

.icon-change-player-pop-out:before {
  content: var(--icon-change-player-pop-out);
}

.icon-change-player-small-solid:before {
  content: var(--icon-change-player-small-solid);
}

.icon-change-player-small:before {
  content: var(--icon-change-player-small);
}

.icon-change-player-to-big-solid:before {
  content: var(--icon-change-player-to-big-solid);
}

.icon-change-player-to-big:before {
  content: var(--icon-change-player-to-big);
}

.icon-chat-solid:before {
  content: var(--icon-chat-solid);
}

.icon-chat:before {
  content: var(--icon-chat);
}

.icon-checkmark-solid:before {
  content: var(--icon-checkmark-solid);
}

.icon-checkmark:before {
  content: var(--icon-checkmark);
}

.icon-chevron-down-solid:before {
  content: var(--icon-chevron-down-solid);
}

.icon-chevron-down:before {
  content: var(--icon-chevron-down);
}

.icon-chevron-left-solid:before {
  content: var(--icon-chevron-left-solid);
}

.icon-chevron-left:before {
  content: var(--icon-chevron-left);
}

.icon-chevron-oval-left-solid:before {
  content: var(--icon-chevron-oval-left-solid);
}

.icon-chevron-oval-left:before {
  content: var(--icon-chevron-oval-left);
}

.icon-chevron-oval-right-solid:before {
  content: var(--icon-chevron-oval-right-solid);
}

.icon-chevron-oval-right:before {
  content: var(--icon-chevron-oval-right);
}

.icon-chevron-right-solid:before {
  content: var(--icon-chevron-right-solid);
}

.icon-chevron-right:before {
  content: var(--icon-chevron-right);
}

.icon-chevron-up-solid:before {
  content: var(--icon-chevron-up-solid);
}

.icon-chevron-up:before {
  content: var(--icon-chevron-up);
}

.icon-choose-solid:before {
  content: var(--icon-choose-solid);
}

.icon-choose:before {
  content: var(--icon-choose);
}

.icon-circle-solid:before {
  content: var(--icon-circle-solid);
}

.icon-circle:before {
  content: var(--icon-circle);
}

.icon-clear-solid:before {
  content: var(--icon-clear-solid);
}

.icon-clear:before {
  content: var(--icon-clear);
}

.icon-clock-solid:before {
  content: var(--icon-clock-solid);
}

.icon-clock:before {
  content: var(--icon-clock);
}

.icon-close-solid:before {
  content: var(--icon-close-solid);
}

.icon-close:before {
  content: var(--icon-close);
}

.icon-cloud-files-solid:before {
  content: var(--icon-cloud-files-solid);
}

.icon-cloud-files:before {
  content: var(--icon-cloud-files);
}

.icon-cloud-solid:before {
  content: var(--icon-cloud-solid);
}

.icon-cloud-upload-solid:before {
  content: var(--icon-cloud-upload-solid);
}

.icon-cloud-upload:before {
  content: var(--icon-cloud-upload);
}

.icon-cloud:before {
  content: var(--icon-cloud);
}

.icon-cm-solid:before {
  content: var(--icon-cm-solid);
}

.icon-cm:before {
  content: var(--icon-cm);
}

.icon-code-solid:before {
  content: var(--icon-code-solid);
}

.icon-code:before {
  content: var(--icon-code);
}

.icon-comment-solid:before {
  content: var(--icon-comment-solid);
}

.icon-comment:before {
  content: var(--icon-comment);
}

.icon-contact-list-solid:before {
  content: var(--icon-contact-list-solid);
}

.icon-contact-list:before {
  content: var(--icon-contact-list);
}

.icon-contracts-solid:before {
  content: var(--icon-contracts-solid);
}

.icon-contracts:before {
  content: var(--icon-contracts);
}

.icon-cost-solid:before {
  content: var(--icon-cost-solid);
}

.icon-cost:before {
  content: var(--icon-cost);
}

.icon-coupon-solid:before {
  content: var(--icon-coupon-solid);
}

.icon-coupon:before {
  content: var(--icon-coupon);
}

.icon-damage-solid:before {
  content: var(--icon-damage-solid);
}

.icon-damage:before {
  content: var(--icon-damage);
}

.icon-data-privacy-solid:before {
  content: var(--icon-data-privacy-solid);
}

.icon-data-privacy:before {
  content: var(--icon-data-privacy);
}

.icon-data-upsell-solid:before {
  content: var(--icon-data-upsell-solid);
}

.icon-data-upsell:before {
  content: var(--icon-data-upsell);
}

.icon-delivery-solid:before {
  content: var(--icon-delivery-solid);
}

.icon-delivery:before {
  content: var(--icon-delivery);
}

.icon-desktop-solid:before {
  content: var(--icon-desktop-solid);
}

.icon-desktop:before {
  content: var(--icon-desktop);
}

.icon-dial-solid:before {
  content: var(--icon-dial-solid);
}

.icon-dial:before {
  content: var(--icon-dial);
}

.icon-discount-solid:before {
  content: var(--icon-discount-solid);
}

.icon-discount:before {
  content: var(--icon-discount);
}

.icon-edit-solid:before {
  content: var(--icon-edit-solid);
}

.icon-edit:before {
  content: var(--icon-edit);
}

.icon-energy-solid:before {
  content: var(--icon-energy-solid);
}

.icon-energy:before {
  content: var(--icon-energy);
}

.icon-euro-solid:before {
  content: var(--icon-euro-solid);
}

.icon-euro:before {
  content: var(--icon-euro);
}

.icon-excited-solid:before {
  content: var(--icon-excited-solid);
}

.icon-excited:before {
  content: var(--icon-excited);
}

.icon-extra-media-solid:before {
  content: var(--icon-extra-media-solid);
}

.icon-extra-media:before {
  content: var(--icon-extra-media);
}

.icon-facebook-solid:before {
  content: var(--icon-facebook-solid);
}

.icon-facebook:before {
  content: var(--icon-facebook);
}

.icon-family-solid:before {
  content: var(--icon-family-solid);
}

.icon-family:before {
  content: var(--icon-family);
}

.icon-favorite-solid:before {
  content: var(--icon-favorite-solid);
}

.icon-favorite:before {
  content: var(--icon-favorite);
}

.icon-file-edit-solid:before {
  content: var(--icon-file-edit-solid);
}

.icon-file-edit:before {
  content: var(--icon-file-edit);
}

.icon-file-eror-solid:before {
  content: var(--icon-file-eror-solid);
}

.icon-file-eror:before {
  content: var(--icon-file-eror);
}

.icon-file-solid:before {
  content: var(--icon-file-solid);
}

.icon-file:before {
  content: var(--icon-file);
}

.icon-filter-solid:before {
  content: var(--icon-filter-solid);
}

.icon-filter:before {
  content: var(--icon-filter);
}

.icon-fixed-phone-solid:before {
  content: var(--icon-fixed-phone-solid);
}

.icon-fixed-phone:before {
  content: var(--icon-fixed-phone);
}

.icon-flip-solid:before {
  content: var(--icon-flip-solid);
}

.icon-flip:before {
  content: var(--icon-flip);
}

.icon-folder-invoice-solid:before {
  content: var(--icon-folder-invoice-solid);
}

.icon-folder-invoice:before {
  content: var(--icon-folder-invoice);
}

.icon-folder-solid:before {
  content: var(--icon-folder-solid);
}

.icon-folder:before {
  content: var(--icon-folder);
}

.icon-forum-solid:before {
  content: var(--icon-forum-solid);
}

.icon-forum:before {
  content: var(--icon-forum);
}

.icon-forward-10-sec-solid:before {
  content: var(--icon-forward-10-sec-solid);
}

.icon-forward-10-sec:before {
  content: var(--icon-forward-10-sec);
}

.icon-forward-30-sec-solid:before {
  content: var(--icon-forward-30-sec-solid);
}

.icon-forward-30-sec:before {
  content: var(--icon-forward-30-sec);
}

.icon-forward-solid:before {
  content: var(--icon-forward-solid);
}

.icon-forward:before {
  content: var(--icon-forward);
}

.icon-fullscreen-solid:before {
  content: var(--icon-fullscreen-solid);
}

.icon-fullscreen:before {
  content: var(--icon-fullscreen);
}

.icon-gallery-solid:before {
  content: var(--icon-gallery-solid);
}

.icon-gallery:before {
  content: var(--icon-gallery);
}

.icon-global-solid:before {
  content: var(--icon-global-solid);
}

.icon-global:before {
  content: var(--icon-global);
}

.icon-grid-solid:before {
  content: var(--icon-grid-solid);
}

.icon-grid:before {
  content: var(--icon-grid);
}

.icon-happy-solid:before {
  content: var(--icon-happy-solid);
}

.icon-happy:before {
  content: var(--icon-happy);
}

.icon-hd-solid:before {
  content: var(--icon-hd-solid);
}

.icon-hd:before {
  content: var(--icon-hd);
}

.icon-helpdesk-solid:before {
  content: var(--icon-helpdesk-solid);
}

.icon-helpdesk:before {
  content: var(--icon-helpdesk);
}

.icon-home-solid:before {
  content: var(--icon-home-solid);
}

.icon-home:before {
  content: var(--icon-home);
}

.icon-image-solid:before {
  content: var(--icon-image-solid);
}

.icon-image:before {
  content: var(--icon-image);
}

.icon-incoming-call-solid:before {
  content: var(--icon-incoming-call-solid);
}

.icon-incoming-call:before {
  content: var(--icon-incoming-call);
}

.icon-infinite:before {
  content: var(--icon-infinite);
}

.icon-info-solid:before {
  content: var(--icon-info-solid);
}

.icon-info-square-solid:before {
  content: var(--icon-info-square-solid);
}

.icon-info-square:before {
  content: var(--icon-info-square);
}

.icon-info:before {
  content: var(--icon-info);
}

.icon-instagram-solid:before {
  content: var(--icon-instagram-solid);
}

.icon-instagram:before {
  content: var(--icon-instagram);
}

.icon-insurance-solid:before {
  content: var(--icon-insurance-solid);
}

.icon-insurance:before {
  content: var(--icon-insurance);
}

.icon-internet-of-things-solid:before {
  content: var(--icon-internet-of-things-solid);
}

.icon-internet-of-things:before {
  content: var(--icon-internet-of-things);
}

.icon-internet-solid:before {
  content: var(--icon-internet-solid);
}

.icon-internet:before {
  content: var(--icon-internet);
}

.icon-invisible-solid:before {
  content: var(--icon-invisible-solid);
}

.icon-invisible:before {
  content: var(--icon-invisible);
}

.icon-invoice-solid:before {
  content: var(--icon-invoice-solid);
}

.icon-invoice:before {
  content: var(--icon-invoice);
}

.icon-key-solid:before {
  content: var(--icon-key-solid);
}

.icon-key:before {
  content: var(--icon-key);
}

.icon-laptop-solid:before {
  content: var(--icon-laptop-solid);
}

.icon-laptop:before {
  content: var(--icon-laptop);
}

.icon-layers-solid:before {
  content: var(--icon-layers-solid);
}

.icon-layers:before {
  content: var(--icon-layers);
}

.icon-line-diagram-solid:before {
  content: var(--icon-line-diagram-solid);
}

.icon-line-diagram:before {
  content: var(--icon-line-diagram);
}

.icon-link-solid:before {
  content: var(--icon-link-solid);
}

.icon-link:before {
  content: var(--icon-link);
}

.icon-linkedin-solid:before {
  content: var(--icon-linkedin-solid);
}

.icon-linkedin:before {
  content: var(--icon-linkedin);
}

.icon-loader:before {
  content: var(--icon-loader);
}

.icon-loading-solid:before {
  content: var(--icon-loading-solid);
}

.icon-loading:before {
  content: var(--icon-loading);
}

.icon-location-pin-solid:before {
  content: var(--icon-location-pin-solid);
}

.icon-location-pin:before {
  content: var(--icon-location-pin);
}

.icon-lock-solid:before {
  content: var(--icon-lock-solid);
}

.icon-lock:before {
  content: var(--icon-lock);
}

.icon-logout-solid:before {
  content: var(--icon-logout-solid);
}

.icon-logout:before {
  content: var(--icon-logout);
}

.icon-lowest-solid:before {
  content: var(--icon-lowest-solid);
}

.icon-lowest:before {
  content: var(--icon-lowest);
}

.icon-mail-solid:before {
  content: var(--icon-mail-solid);
}

.icon-mail:before {
  content: var(--icon-mail);
}

.icon-media-solid:before {
  content: var(--icon-media-solid);
}

.icon-media:before {
  content: var(--icon-media);
}

.icon-menu-solid:before {
  content: var(--icon-menu-solid);
}

.icon-menu:before {
  content: var(--icon-menu);
}

.icon-mic-solid:before {
  content: var(--icon-mic-solid);
}

.icon-mic:before {
  content: var(--icon-mic);
}

.icon-min-solid:before {
  content: var(--icon-min-solid);
}

.icon-min:before {
  content: var(--icon-min);
}

.icon-mobile-home-solid:before {
  content: var(--icon-mobile-home-solid);
}

.icon-mobile-home:before {
  content: var(--icon-mobile-home);
}

.icon-mobile-phone-solid:before {
  content: var(--icon-mobile-phone-solid);
}

.icon-mobile-phone:before {
  content: var(--icon-mobile-phone);
}

.icon-mobile-protect-solid:before {
  content: var(--icon-mobile-protect-solid);
}

.icon-mobile-protect:before {
  content: var(--icon-mobile-protect);
}

.icon-more-solid:before {
  content: var(--icon-more-solid);
}

.icon-more:before {
  content: var(--icon-more);
}

.icon-multi-sim-solid:before {
  content: var(--icon-multi-sim-solid);
}

.icon-multi-sim:before {
  content: var(--icon-multi-sim);
}

.icon-mute-alert-solid:before {
  content: var(--icon-mute-alert-solid);
}

.icon-mute-alert:before {
  content: var(--icon-mute-alert);
}

.icon-mute-sound-solid:before {
  content: var(--icon-mute-sound-solid);
}

.icon-mute-sound:before {
  content: var(--icon-mute-sound);
}

.icon-my-location-solid:before {
  content: var(--icon-my-location-solid);
}

.icon-my-location:before {
  content: var(--icon-my-location);
}

.icon-neutral-solid:before {
  content: var(--icon-neutral-solid);
}

.icon-neutral:before {
  content: var(--icon-neutral);
}

.icon-no-video-solid:before {
  content: var(--icon-no-video-solid);
}

.icon-no-video:before {
  content: var(--icon-no-video);
}

.icon-order-solid:before {
  content: var(--icon-order-solid);
}

.icon-order:before {
  content: var(--icon-order);
}

.icon-oval-checkmark-solid:before {
  content: var(--icon-oval-checkmark-solid);
}

.icon-oval-checkmark:before {
  content: var(--icon-oval-checkmark);
}

.icon-oval-close-solid:before {
  content: var(--icon-oval-close-solid);
}

.icon-oval-close:before {
  content: var(--icon-oval-close);
}

.icon-overview-solid:before {
  content: var(--icon-overview-solid);
}

.icon-overview:before {
  content: var(--icon-overview);
}

.icon-payout-solid:before {
  content: var(--icon-payout-solid);
}

.icon-payout:before {
  content: var(--icon-payout);
}

.icon-pdf-solid:before {
  content: var(--icon-pdf-solid);
}

.icon-pdf:before {
  content: var(--icon-pdf);
}

.icon-pin-solid:before {
  content: var(--icon-pin-solid);
}

.icon-pin:before {
  content: var(--icon-pin);
}

.icon-placeholder-solid:before {
  content: var(--icon-placeholder-solid);
}

.icon-placeholder:before {
  content: var(--icon-placeholder);
}

.icon-play-solid:before {
  content: var(--icon-play-solid);
}

.icon-play:before {
  content: var(--icon-play);
}

.icon-plus-solid:before {
  content: var(--icon-plus-solid);
}

.icon-plus:before {
  content: var(--icon-plus);
}

.icon-prepaid-solid:before {
  content: var(--icon-prepaid-solid);
}

.icon-prepaid:before {
  content: var(--icon-prepaid);
}

.icon-printer-solid:before {
  content: var(--icon-printer-solid);
}

.icon-printer:before {
  content: var(--icon-printer);
}

.icon-processor-solid:before {
  content: var(--icon-processor-solid);
}

.icon-processor:before {
  content: var(--icon-processor);
}

.icon-profile-business-alt:before {
  content: var(--icon-profile-business-alt);
}

.icon-profile-business-solid:before {
  content: var(--icon-profile-business-solid);
}

.icon-profile-business:before {
  content: var(--icon-profile-business);
}

.icon-profile-solid:before {
  content: var(--icon-profile-solid);
}

.icon-profile:before {
  content: var(--icon-profile);
}

.icon-protection-solid:before {
  content: var(--icon-protection-solid);
}

.icon-protection:before {
  content: var(--icon-protection);
}

.icon-question-mark-solid:before {
  content: var(--icon-question-mark-solid);
}

.icon-question-mark:before {
  content: var(--icon-question-mark);
}

.icon-question-square-solid:before {
  content: var(--icon-question-square-solid);
}

.icon-question-square:before {
  content: var(--icon-question-square);
}

.icon-rating-solid:before {
  content: var(--icon-rating-solid);
}

.icon-rating:before {
  content: var(--icon-rating);
}

.icon-recycle-solid:before {
  content: var(--icon-recycle-solid);
}

.icon-recycle:before {
  content: var(--icon-recycle);
}

.icon-refresh-solid:before {
  content: var(--icon-refresh-solid);
}

.icon-refresh:before {
  content: var(--icon-refresh);
}

.icon-regional-pin-solid:before {
  content: var(--icon-regional-pin-solid);
}

.icon-regional-pin:before {
  content: var(--icon-regional-pin);
}

.icon-reload-solid:before {
  content: var(--icon-reload-solid);
}

.icon-reload:before {
  content: var(--icon-reload);
}

.icon-renew-solid:before {
  content: var(--icon-renew-solid);
}

.icon-renew:before {
  content: var(--icon-renew);
}

.icon-rewind-10-sec-solid:before {
  content: var(--icon-rewind-10-sec-solid);
}

.icon-rewind-10-sec:before {
  content: var(--icon-rewind-10-sec);
}

.icon-rewind-30-sec-solid:before {
  content: var(--icon-rewind-30-sec-solid);
}

.icon-rewind-30-sec:before {
  content: var(--icon-rewind-30-sec);
}

.icon-rotate-solid:before {
  content: var(--icon-rotate-solid);
}

.icon-rotate:before {
  content: var(--icon-rotate);
}

.icon-sad-solid:before {
  content: var(--icon-sad-solid);
}

.icon-sad:before {
  content: var(--icon-sad);
}

.icon-schedule-solid:before {
  content: var(--icon-schedule-solid);
}

.icon-schedule:before {
  content: var(--icon-schedule);
}

.icon-screensize-solid:before {
  content: var(--icon-screensize-solid);
}

.icon-screensize:before {
  content: var(--icon-screensize);
}

.icon-sd-solid:before {
  content: var(--icon-sd-solid);
}

.icon-sd:before {
  content: var(--icon-sd);
}

.icon-search-solid:before {
  content: var(--icon-search-solid);
}

.icon-search:before {
  content: var(--icon-search);
}

.icon-security-solid:before {
  content: var(--icon-security-solid);
}

.icon-security:before {
  content: var(--icon-security);
}

.icon-self-service-solid:before {
  content: var(--icon-self-service-solid);
}

.icon-self-service:before {
  content: var(--icon-self-service);
}

.icon-send-solid:before {
  content: var(--icon-send-solid);
}

.icon-settings-solid:before {
  content: var(--icon-settings-solid);
}

.icon-settings:before {
  content: var(--icon-settings);
}

.icon-share-solid:before {
  content: var(--icon-share-solid);
}

.icon-share:before {
  content: var(--icon-share);
}

.icon-shop-solid:before {
  content: var(--icon-shop-solid);
}

.icon-shop:before {
  content: var(--icon-shop);
}

.icon-shopping-list-solid:before {
  content: var(--icon-shopping-list-solid);
}

.icon-shopping-list:before {
  content: var(--icon-shopping-list);
}

.icon-simcard-activate-solid:before {
  content: var(--icon-simcard-activate-solid);
}

.icon-simcard-activate:before {
  content: var(--icon-simcard-activate);
}

.icon-simcard-cancel-solid:before {
  content: var(--icon-simcard-cancel-solid);
}

.icon-simcard-cancel:before {
  content: var(--icon-simcard-cancel);
}

.icon-simcard-solid:before {
  content: var(--icon-simcard-solid);
}

.icon-simcard:before {
  content: var(--icon-simcard);
}

.icon-slider:before {
  content: var(--icon-slider);
}

.icon-smartphone-data-transfer-solid:before {
  content: var(--icon-smartphone-data-transfer-solid);
}

.icon-smartphone-data-transfer:before {
  content: var(--icon-smartphone-data-transfer);
}

.icon-smartwatch-solid:before {
  content: var(--icon-smartwatch-solid);
}

.icon-smartwatch:before {
  content: var(--icon-smartwatch);
}

.icon-sound-solid:before {
  content: var(--icon-sound-solid);
}

.icon-sound:before {
  content: var(--icon-sound);
}

.icon-spam-email-solid:before {
  content: var(--icon-spam-email-solid);
}

.icon-spam-email:before {
  content: var(--icon-spam-email);
}

.icon-status-alert-solid:before {
  content: var(--icon-status-alert-solid);
}

.icon-status-alert:before {
  content: var(--icon-status-alert);
}

.icon-status-error-solid:before {
  content: var(--icon-status-error-solid);
}

.icon-status-error:before {
  content: var(--icon-status-error);
}

.icon-status-info-solid:before {
  content: var(--icon-status-info-solid);
}

.icon-status-info:before {
  content: var(--icon-status-info);
}

.icon-status-success-solid:before {
  content: var(--icon-status-success-solid);
}

.icon-status-success:before {
  content: var(--icon-status-success);
}

.icon-status-warning-solid:before {
  content: var(--icon-status-warning-solid);
}

.icon-status-warning:before {
  content: var(--icon-status-warning);
}

.icon-store-solid:before {
  content: var(--icon-store-solid);
}

.icon-store:before {
  content: var(--icon-store);
}

.icon-tablet-solid:before {
  content: var(--icon-tablet-solid);
}

.icon-tablet:before {
  content: var(--icon-tablet);
}

.icon-telekom-solid:before {
  content: var(--icon-telekom-solid);
}

.icon-telekom:before {
  content: var(--icon-telekom);
}

.icon-text-solid:before {
  content: var(--icon-text-solid);
}

.icon-text:before {
  content: var(--icon-text);
}

.icon-theft-solid:before {
  content: var(--icon-theft-solid);
}

.icon-thumbs-down-solid:before {
  content: var(--icon-thumbs-down-solid);
}

.icon-thumbs-up-solid:before {
  content: var(--icon-thumbs-up-solid);
}

.icon-trashcan-solid:before {
  content: var(--icon-trashcan-solid);
}

.icon-trophy-solid:before {
  content: var(--icon-trophy-solid);
}

.icon-tv-solid:before {
  content: var(--icon-tv-solid);
}

.icon-tv:before {
  content: var(--icon-tv);
}

.icon-twitter-solid:before {
  content: var(--icon-twitter-solid);
}

.icon-unlimited-solid:before {
  content: var(--icon-unlimited-solid);
}

.icon-unlimited:before {
  content: var(--icon-unlimited);
}

.icon-unlink-solid:before {
  content: var(--icon-unlink-solid);
}

.icon-unlink:before {
  content: var(--icon-unlink);
}

.icon-unlock-solid:before {
  content: var(--icon-unlock-solid);
}

.icon-unlock:before {
  content: var(--icon-unlock);
}

.icon-visible-solid:before {
  content: var(--icon-visible-solid);
}

.icon-voicemail-solid:before {
  content: var(--icon-voicemail-solid);
}

.icon-wallet-solid:before {
  content: var(--icon-wallet-solid);
}

.icon-warning-solid:before {
  content: var(--icon-warning-solid);
}

.icon-wifi-solid:before {
  content: var(--icon-wifi-solid);
}

.icon-youtube-solid:before {
  content: var(--icon-youtube-solid);
}

/* ========================================================================
   Component: Drilldown
 ========================================================================== */
unity-drilldown {
  display: block;
  height: 100%;
  overflow: hidden;
}
unity-drilldown > ul {
  left: 0;
  position: relative;
}
unity-drilldown ul,
unity-drilldown li {
  list-style: none;
  margin: 0;
}
unity-drilldown ul {
  width: 100%;
  padding: 0;
}
unity-drilldown li > ul {
  display: none;
  left: 100%;
  position: absolute;
  top: 0;
}
unity-drilldown li[id] {
  display: none;
}
unity-drilldown [data-drilldown-panel] {
  -webkit-box-shadow: none;
          box-shadow: none;
}
@media (max-width: 47.9375rem) {
  unity-drilldown[persistant-height] {
    overflow: visible;
  }
  unity-drilldown[persistant-height] [data-drilldown-panel] + ul,
  unity-drilldown[persistant-height] [data-drilldown-panel] + ul > li,
  unity-drilldown[persistant-height] > ul,
  unity-drilldown[persistant-height] > ul > li {
    height: 100%;
  }
}

/** Shadow modifier */
/** Nav icon */
/** Nav button */
/** Nav item */
/** By setting the variable below to 'true', the jigsaw element of the original puzzle navigation will appear */
/** Nav item - muted modifier */
/** Nav item - completed modifier */
/** Nav item - selected modifier */
/** Nav item main label */
/** Nav item sub label */
/** Nav item add button */
/** Nav item add button - minimized */
/** Remove dropdown variables */
/* ========================================================================
   Puzzle nav
 ========================================================================== */
.puzzle-nav {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.puzzle-nav ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  list-style: none;
  overflow-x: scroll;
  overflow-y: visible;
  height: 7.875rem;
  margin: 0;
  padding: var(--unity-global-space-4) 0 var(--unity-global-space-4) 2px;
  position: relative;
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
}
.puzzle-nav ul::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}

.puzzle-nav--shadow:before {
  -webkit-box-shadow: 6px 0 8px 0 rgba(0, 0, 0, 0.08);
          box-shadow: 6px 0 8px 0 rgba(0, 0, 0, 0.08);
  content: "";
  height: 62px;
  left: -8px;
  position: absolute;
  top: 6px;
  width: 8px;
  z-index: 2;
}

/* Nav item
========================================================================== */
.puzzle-nav-item {
  position: relative;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  border-bottom: solid transparent;
  border-width: var(--unity-global-border-width-2);
  max-width: 30rem;
  -webkit-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}
.puzzle-nav-item:before {
  content: "";
  background: var(--unity-global-color-solid-white);
  position: absolute;
  content: "";
  height: calc(var(--unity-global-size-64) - var(--unity-global-border-width-2));
  width: calc(var(--unity-global-border-width-2) * 2);
  left: calc(-1 * var(--unity-global-border-width-2));
  top: 0;
  bottom: 0;
}
.puzzle-nav-item.is-hidden {
  overflow: hidden;
  max-width: 0;
}
.puzzle-nav-item:first-child {
  /** first item shouldn't have a circle */
  /** Container border radius is handled by the first rendered button */
}
.puzzle-nav-item:first-child:before {
  content: none;
}
.puzzle-nav-item:first-child .puzzle-nav-button {
  border-radius: 0;
}

.puzzle-nav-item-complete .puzzle-nav-icon {
  color: var(--unity-global-color-solid-green);
}

.puzzle-nav-item-selected .puzzle-nav-button {
  border-bottom: 2px solid var(--unity-global-color-solid-black);
}

.puzzle-nav-item-muted .puzzle-nav-icon {
  color: #757575;
}
.puzzle-nav-item-muted .puzzle-nav-main-label {
  color: #757575;
}
.puzzle-nav-item-muted .puzzle-nav-sub-label {
  color: #757575;
}

/* Nav item elements
========================================================================== */
.puzzle-nav-button {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background-color: var(--unity-global-color-solid-white);
  border: none;
  -webkit-box-shadow: 0 0 2px rgba(0, 0, 0, 0.12), 0 4px 8px rgba(0, 0, 0, 0.04);
          box-shadow: 0 0 2px rgba(0, 0, 0, 0.12), 0 4px 8px rgba(0, 0, 0, 0.04);
  color: var(--unity-global-color-solid-black);
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-family: var(--unity-global-font-families-otypical-text);
  font-size: var(--unity-global-font-size-16);
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  height: var(--unity-global-size-64);
  gap: var(--unity-global-space-12);
  padding: var(--unity-global-space-8) var(--unity-global-space-32) var(--unity-global-space-8) var(--unity-global-space-16);
}

.puzzle-nav-icon {
  font-size: var(--unity-alias-icon-size-sm);
}

.puzzle-nav-text {
  margin: 0;
  text-align: left;
}

.puzzle-nav-main-label {
  line-height: var(--unity-global-line-heights-22);
  font-size: var(--unity-global-font-size-14);
  font-weight: var(--unity-global-font-weights-medium);
  color: var(--unity-global-color-solid-black);
}

.puzzle-nav-sub-label {
  line-height: var(--unity-global-line-heights-22);
  font-size: var(--unity-global-font-size-14);
  font-weight: var(--unity-global-font-weights-regular);
  color: #757575;
  display: block;
}

/* Add button & circle
========================================================================== */
.puzzle-nav-item-add {
  position: sticky;
  right: 0;
  height: var(--unity-global-size-64);
}
.puzzle-nav-item-add::before {
  content: "";
  border-radius: 100%;
  -webkit-box-shadow: 0 0 2px rgba(0, 0, 0, 0.12), 0 4px 8px rgba(0, 0, 0, 0.04);
          box-shadow: 0 0 2px rgba(0, 0, 0, 0.12), 0 4px 8px rgba(0, 0, 0, 0.04);
  height: 32px;
  left: -16px;
  position: absolute;
  top: 16px;
  width: 32px;
  -webkit-transition: background-color 0.3s ease-in-out;
  -o-transition: background-color 0.3s ease-in-out;
  transition: background-color 0.3s ease-in-out;
  background-color: var(--unity-global-color-solid-blue);
  -webkit-clip-path: none;
          clip-path: none;
  z-index: 2;
}
.puzzle-nav-item-add .puzzle-nav-button {
  -webkit-transition: background-color 0.3s ease-in-out;
  -o-transition: background-color 0.3s ease-in-out;
  transition: background-color 0.3s ease-in-out;
  background-color: var(--unity-global-color-solid-black);
  border-radius: 0;
  color: var(--unity-global-color-solid-white);
  position: relative;
  padding: var(--unity-global-space-8) var(--unity-global-space-32);
  -webkit-transition: width 0.2s ease-in-out;
  -o-transition: width 0.2s ease-in-out;
  transition: width 0.2s ease-in-out;
}
.puzzle-nav-item-add .puzzle-nav-icon {
  font-size: var(--unity-alias-icon-size-xs);
  left: calc(var(--unity-alias-icon-size-xs) / -2);
  top: var(--unity-global-space-24);
  position: absolute;
  z-index: 3;
}
.puzzle-nav-item-add .puzzle-nav-text {
  width: 100%;
}
.puzzle-nav-item-add .puzzle-nav-main-label {
  line-height: var(--unity-global-line-heights-24);
  font-size: var(--unity-global-font-size-16);
  font-weight: var(--unity-global-font-weights-medium);
  color: var(--unity-global-color-solid-white);
}
.puzzle-nav-item-add:hover:before {
  background-color: var(--unity-global-color-scale-blue-700);
}
.puzzle-nav-item-add:hover .puzzle-nav-button {
  background-color: var(--unity-global-color-scale-gray-700);
}

.puzzle-nav-item-add--minimized .puzzle-nav-button {
  padding: var(--unity-global-space-8) var(--unity-global-space-12);
  -webkit-box-shadow: -1px 0 2px rgba(0, 0, 0, 0.12), -1px 4px 8px rgba(0, 0, 0, 0.04);
          box-shadow: -1px 0 2px rgba(0, 0, 0, 0.12), -1px 4px 8px rgba(0, 0, 0, 0.04);
}
.puzzle-nav-item-add--minimized .puzzle-nav-button .puzzle-nav-text {
  width: 0;
  overflow: hidden;
}

/* Remove dropdown (opens when nav item is clicked)
========================================================================== */
.puzzle-nav-remove {
  background-color: var(--unity-global-color-solid-white);
  -webkit-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.04), 0 0 2px 0 rgba(0, 0, 0, 0.12);
          box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.04), 0 0 2px 0 rgba(0, 0, 0, 0.12);
  margin-top: var(--unity-global-space-8);
  padding: var(--unity-global-space-8) var(--unity-global-space-16);
  position: absolute;
  width: 100%;
}
.puzzle-nav-remove button {
  background-color: transparent;
  border: none;
  color: var(--unity-global-color-solid-blue);
  cursor: pointer;
  font-family: inherit; /* 1 */
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
  overflow: visible;
  text-transform: none;
  -webkit-appearance: button;
     -moz-appearance: button;
          appearance: button;
}
.puzzle-nav-remove .triangle-element {
  -webkit-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.04), 0 0 2px 0 rgba(0, 0, 0, 0.12);
          box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.04), 0 0 2px 0 rgba(0, 0, 0, 0.12);
  width: var(--unity-global-space-12);
  height: var(--unity-global-space-12);
  top: calc(var(--unity-global-space-12) / -2);
  left: var(--unity-global-space-20);
  overflow: hidden;
  position: absolute;
  /** clip-path renders a triangle triangle */
  -webkit-clip-path: polygon(0% -2000%, -60% 100%, 100% 100%);
          clip-path: polygon(0% -2000%, -60% 100%, 100% 100%);
  -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
          transform: rotate(45deg);
}
.puzzle-nav-remove .triangle-element:after {
  content: "";
  background: var(--unity-global-color-solid-white);
  width: 142%;
  height: 142%;
  position: absolute;
  -webkit-transform: rotate(45deg) translate(-71%, 0);
      -ms-transform: rotate(45deg) translate(-71%, 0);
          transform: rotate(45deg) translate(-71%, 0);
}
