.rounded-5 {
  border-radius: 5px;
}

$tile-height: 420px;

.tile.tile-dashboard {
  height: $tile-height;
  min-height: $tile-height;

  background: var(--global-color-scale-blue-50, #ECF2FF);
  justify-content: start;

  > div.tile-section:first-of-type {
    min-height: 50px;
  }

  &:not(.tile-skeleton-hidden) {
    .tile-section {
      transform: rotateY(-90deg);
    }
  }

  &.tile-skeleton-hidden {
    height: 100%;

    .tile-section {
      transition-timing-function: ease-in-out;

      transform: none;
      animation-duration: 1s;
      animation-name: flipY;
    }

    transition-timing-function: ease-in;
    animation-delay: 0.25s;
    animation-duration: 0.4s;
    animation-name: growTile;

    &::after {
      opacity: 0;
      transition-timing-function: ease-in-out;
      animation-duration: 0.2s;
      animation-name: fadeOut;
    }
  }

  &::after {
    content: '';
    background-image: url(/assets/images/skeleton/tile-dashboard-text-dark.svg);
    background-size: auto 86.5%;
    background-repeat: no-repeat;
    background-position: 50% 45%;

    opacity: 0.7;

    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
}

.tile-section {
  width: 100%;

  z-index: 1;
  text-align: center !important;

  &.tile-image {
    padding-top: 1rem;
    padding-bottom: 1.5rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
}

.btn-close-tile {
  position: absolute !important;
  top: 12px;
  right: 12px;
  height: 24px;
  width: 24px;
  font-size: 24px;
  cursor: pointer;
  z-index: 2;
  color: #757575; /* design-systems text-muted */

  &:hover {
    color: #262626; /* design-systems text-default */
  }
}

@keyframes fadeOut {
  100% {
    opacity: 0;
  }
}

@keyframes flipY {
  0% {
    transform: rotateY(-90deg);
  }
  100% {
    transform: none;
  }
}

@keyframes zoomIn {
  0% {
    transform: scale(0, 0);
  }
  100% {
    transform: scale(1, 1);
  }
}

@keyframes growTile {
  0% {
    height: $tile-height;
  }
  100% {
    height: 100%;
  }
}

.tile-dashboard-headline {
  font-size: var(--unity-global-font-size-18) !important;
}
