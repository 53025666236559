.d-flex {
  display: flex;
}

.d-flex-important {
  display: flex !important;
}

.d-block {
  display: block;
}

.d-block-important {
  display: block !important;
}

@media (max-width: 767.97px) {
  .d-block-mobile-important {
    display: block !important;
  }
}

.d-inline-block {
  display: inline-block;
}

.d-invisible {
  visibility: hidden;
}

.flex-inline {
  display: inline-flex;
}

.list-ordered-steps h3 {
  line-height: 28px;
}

@media (max-width: 979.98px) {
  .invisible-tablet-down {
    display: none !important;
  }
}

@media (min-width: 980px) {
  .invisible-desktop-up {
    display: none !important;
  }
}
