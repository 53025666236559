.label--row {
  display: block;
}

.row {
  margin-bottom: 0px;
}

.card:not(.card-h-100) {
  height: auto !important;
}

.form-group {
  margin-bottom: 10px;
}

ul.footer-links > li {
  margin-left: 4.6px;
}

ul.footer-links > li:first-child {
  margin-left: 0px;
}

ul.footer-social > li {
  margin-left: 4.6px;
}

ul.footer-social > li:first-child {
  margin-left: 0px;
}

ul.navbar-meta-links > li {
  margin-left: 3px;
}

ul.navbar-meta-links > li:first-child {
  margin-left: 0px;
}

label.navbar-menu-toggle {
  margin-bottom: 0;
}

.datepicker--cell.-weekend-:not(.-selected-) {
  color: #4a4a4a !important;

  &.-disabled- {
    color: #aeaeae !important;
  }

  &.-other-month- {
    color: #dedede !important;
  }
}

.icon-large:before {
  vertical-align: -19%;
  font-size: 1.65em;
}

/* Next four are for styling validations on a textarea similar to a textbox */
.form-textfield.is-invalid textarea {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23d90000' viewBox='0 0 64 64'%3E%3Cpath d='M32 4C16.5 4 4 16.5 4 32s12.5 28 28 28 28-12.5 28-28S47.5 4 32 4zm0 53C18.2 57 7 45.8 7 32S18.2 7 32 7s25 11.2 25 25-11.2 25-25 25zm-1.5-10h3v-4h-3v4zm0-9h3V17h-3v21z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: calc(100% - 8px) center;
  background-size: 20px;
  padding-right: 36px;
}

.form-textfield.is-valid textarea {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%2321b277' viewBox='0 0 64 64'%3E%3Cpath d='M23 49.2c-.4 0-.7-.2-1-.4L8.5 36c-.6-.6-.7-1.5-.1-2.1s1.5-.7 2.1-.1L23 45.6l30.4-30.4c.6-.6 1.6-.5 2.1.1s.5 1.5 0 2L24.1 48.8c-.3.3-.7.4-1.1.4z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: calc(100% - 8px) center;
  background-size: 20px;
  padding-right: 36px;
}

.form-textfield.is-invalid textarea {
  border-color: #d90000;
}

.form-textfield.is-valid textarea {
  border-color: #21b277;
}

/* Button link disabled additions on design systems */
.button-link {
  &:disabled {
    pointer-events: none;
  }

  /* line-height is 48px, making the link as big as buttons, instead we make it normal */
  [class^='icon-'] {
    line-height: normal;
  }
}

@media (max-width: 600px) {
  .mx-mobile-0 {
    margin-left: 0px;
    margin-right: 0px;
  }
}

.list-group-inset > li.link-list-item {
  border-top: 0;
}

.card-header {
  border-bottom: none !important;
}

.inherit-font-size {
  font-size: inherit !important;
}

.modal-dialog-drawer.modal-dialog-drawer-bottom {
  border-top-left-radius: var(--unity-global-border-radius-24);
  border-top-right-radius: var(--unity-global-border-radius-24);
}

.modal-close-button:after {
  content: var(--icon-close);
  vertical-align: middle;
  font-size: var(--unity-global-font-size-16);
}

.text-muted-500 {
  color: #666;
}

.font-smaller {
  font-size: var(--unity-global-font-size-14);
}

.modal-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
